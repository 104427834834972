<template lang="pug">
v-app-bar.pa-0.ma-0(:class='{"not-fixed-header": isNotFixedHeader}' app color='white' dark elevation='1')
  v-row.ma-0.pa-0(v-if='!isMobile' align='center' justify='space-between')
    v-col.ma-0.pa-0(cols='3')
      logo

    v-col.pa-0.ma-0(cols='6')
      v-row.pa-0(justify='center')
        v-btn(v-if='back' color='primary' icon rounded @click='goBack')
          v-icon(large) mdi-keyboard-backspace
        v-toolbar-title.header-text.primary--text {{$t($route.query.gr_name) ? $t($route.query.gr_name) + $t('common.no_ja') + $t($route.name) : $t($route.name)}}
    v-col.ma-0.pa-0.pr-2(cols='3')
      v-row.ma-0(justify='end')
        locale-dropdown
        j-notifications
        profile-menu
        //portal-target(name="header-action")

  v-row.ma-0.pa-0(v-else align='center' justify='space-between')
    v-row.ma-0.pa-0.mobile-flex(align='center')
      v-col.pa-0(cols='1')
        v-btn.pr-3.btn-icon(:style='{width: "38px"}' icon @click='$emit("open-menu")')
          v-icon(:style='{"font-size": "30px"}' color='primary' x-large) mdi-menu
      v-col.ma-0.pa-0.pl-4(cols='2')
        logo(:is-mobile='isMobile' @is-demo='handleIsDemo')
      v-col(cols='1')
        v-btn(v-if='back' color='primary' icon rounded @click='goBack')
          v-icon(large) mdi-keyboard-backspace
      v-col.justify-center.text-center.pa-0.mobile-text
        v-toolbar-title.header-mobile-text.primary--text(v-if='!isAssignmentHeader' style='text-align: center') {{$t($route.query.gr_name) ? $t($route.query.gr_name) + $t('common.no_ja') + $t($route.name) : $t($route.name)}}
        span.test-text(v-if='isHomepage && isDemoValue', :style='"background-color:" + colorDescription') {{webDescription}}
        .header-mobile-text.primary--text(v-if='isAssignmentHeader')
          portal-target(name='header-title')
        .sub-header-mobile-text.primary--text(v-if='isAssignmentHeader')
          portal-target(name='header-sub-title')
      portal-target.mobile-portal-target(name='header-action')

    v-col.ma-0.pa-0(:cols='3')
      v-row.ma-0.mobile-icon(:style='{"font-size": isResize ? "14px" : "16px"}' justify='end')
        j-notifications(v-if='$route.path === "/"', :is-mobile='!isMobile')
</template>

<script>
  import {defineComponent, getCurrentInstance, ref, watch, watchEffect} from 'vue'
  import {useRoute} from 'vue-router'
  import Logo from './Logo'
  import ProfileMenu from './ProfileMenu'
  import LocaleDropdown from './LocaleDropdown.vue'
  import JNotifications from '../JNotifications/index.vue'
  import {backPage, urlPath} from '@/utils'
  import {history} from '@/store/history'
  const JHeaderMenu = defineComponent({
    components: {Logo, ProfileMenu, JNotifications, LocaleDropdown},
    props: {
      back: {
        type: Boolean,
        required: false,
        default: false
      },
      isMobile: {
        type: Boolean,
        required: false,
        default: false
      },
      isResize: {
        type: Boolean,
        required: false,
        default: false
      },
      isNotFixedHeader: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    setup() {
      const instance = getCurrentInstance()
      const {$root} = instance.proxy
      const colorDescription = process.env.VUE_APP_WEB_DESCRIPTION
        ? process.env.VUE_APP_DESCRIPTION_COLOR
          ? process.env.VUE_APP_DESCRIPTION_COLOR
          : 'green'
        : ''
      const webDescription = process.env.VUE_APP_WEB_DESCRIPTION
      const goBack = () => {
        backPage()
      }

      const isAssignmentHeader = ref(false)
      const isHomepage = ref(false)

      const isDemoValue = ref(false)

      const handleIsDemo = value => {
        isDemoValue.value = value
      }

      watch(
        () => $root.$route,
        () => {
          const {path} = $root.$route
          if (path === '/assignment/mobile/form/create') {
            isAssignmentHeader.value = true
          } else {
            isAssignmentHeader.value = false
          }
          if (path === '/') {
            isHomepage.value = true
          } else {
            isHomepage.value = false
          }
        }
      )
      return {
        goBack,
        colorDescription,
        webDescription,
        isAssignmentHeader,
        isHomepage,
        handleIsDemo,
        isDemoValue
      }
    }
  })
  export default JHeaderMenu
</script>

<style lang="sass" scoped>
  @import '@/style/css/common.sass'
  .header-text
    font-size: 30px
    font-weight: bold
  .header-mobile-text
    font-size: 20px
    font-weight: bold
  .sub-header-mobile-text
    font-size: 16px
    font-weight: bold
  ::v-deep .v-toolbar__content
      padding-right: 0 !important
  .test-text
      letter-spacing: 10px
      padding-top: 3px
      padding-left: 7px
      padding-right: 7px
      font-size: 28px
      font-weight: bold
      color: white
      border-radius: 4px
      @media (max-width: 820px)
        font-size: 18px
        letter-spacing: 4px
        padding-top: 3px
      @media (max-width: 600px)
        font-size: 10px
        padding-top: 3px
  @media (max-width: 400px)
    .mobile-text
      padding-right: 0 !important
      margin-left: -5px !important
    .mobile-icon
      padding-right: 5px !important
    .mobile-btn
      height: 40px !important
    .mobile-flex
      flex: 1 1 0
  @media (max-width: 600px)
    .header-mobile-text
      height: 24px
  .mobile-portal-target
    position: absolute
    right: 0px
  .not-fixed-header
    position: absolute !important
</style>
