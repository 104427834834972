import {api, i18n} from '@/plugins'
import {endpoints} from '@/utils'

export const toYomi = async text => {
  try {
    const response = await api.get(`${endpoints.TO_YOMI}?text=${text}`)
    return response.data
  } catch (err) {
    console.log(err)
  }
  return null
}

export const actionDotsVertical = [
  {
    id: 1,
    text: 'common.edit',
    icon: 'mdi-pencil',
    action: 'on-update',
    disabled: false,
    color: 'gray'
  },
  {
    id: 2,
    text: 'common.delete',
    icon: 'mdi-delete-empty',
    action: 'on-delete',
    disabled: false,
    color: 'red'
  }
]

export const moveCursor = (event, $refs, list_states, focusInput) => {
  /*
   * event: keydown event
   * list_states: array reference name exp ['code', 'untg', 'name', 'yomi', 'name_short', 'name_eng']
   * focusInput: reference name is focusing exp name
   * */
  let index = list_states.findIndex(sates => sates === focusInput)
  const oldFocusInput = focusInput
  if ((event.keyCode === 13 || event.keyCode === 9) && index !== list_states.length - 1) {
    event.preventDefault()
    focusInput = list_states[index + 1]
    if ($refs[oldFocusInput]) {
      $refs[oldFocusInput].blur()
    } else if ($refs?.master_dialog.$refs[oldFocusInput]) {
      $refs?.master_dialog.$refs[oldFocusInput].blur()
    }
  }

  if ($refs[focusInput]) {
    if (Object.keys($refs[focusInput]).includes('focus')) $refs[focusInput].focus()
    if (Object.keys($refs[focusInput]).includes('activateMenu')) $refs[focusInput].activateMenu()
  } else if ($refs?.master_dialog.$refs[focusInput]) {
    $refs?.master_dialog.$refs[focusInput]?.focus()
  }

  return focusInput
}

export const getErrorMessage = (error, itemName, itemGroupName) => {
  let msg = []
  if (typeof error.response?.data?.detail === 'string') {
    msg.push(error.response?.data?.detail)
  } else if (error.response?.data?.detail?.length > 0) {
    error.response?.data?.detail.forEach(e => {
      const {error_code, duplicate} = e
      msg.push(i18n.t(`error_code.group_${error_code}`, {itemName: itemName}))
      msg.push(
        i18n.t(`error_code.group_detail_${error_code}`, {
          itemGroupName: itemGroupName,
          group: duplicate.group_name,
          name: duplicate?.name,
          code: duplicate.code
        })
      )
    })
    msg.push(i18n.t('common.msg.do_you_want_to_save'))
  }
  return msg
}
