<template lang="pug">
div
  v-btn.pa-0.button-header(v-if='showReset' color='white' height='35' icon width='40' @click.stop='reset')
    v-icon mdi-reload
  v-btn.pa-0.button-header(:disabled='disabled' color='white' height='35' icon width='40' @click='save')
    v-icon mdi-content-save
</template>

<script>
  import {defineComponent, ref, inject} from 'vue'

  const JSortActionButton = defineComponent({
    props: {
      showReset: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    setup(props, {emit}) {
      const disabled = inject('isSortDisabled', ref(false))

      const save = () => {
        emit('on-save')
      }
      const reset = () => {
        emit('on-reset')
      }
      // const cancel = () => {
      //   emit('on-cancel')
      // }

      return {save, reset, disabled}
    }
  })

  export default JSortActionButton
</script>

<style lang="sass" scoped>
  @import '@/style/css/common.sass'
</style>
