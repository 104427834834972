import {i18n} from '@/plugins'

export const ARRIVAL_TYPE = {
  ORDER: i18n.t('common.order'),
  PURCHASE: i18n.t('common.purchase'),
  CONSIGNMENT: i18n.t('common.consignment')
}

export const AUCTION_LANE = {
  LANE_A: 'A',
  LANE_B: 'B',
  LANE_C: 'C',
  LANE_D: 'D'
}

export const AUCTION_LANE_LIST = ['A', 'B', 'C', 'D']

const MONDAY = 'monday'
const TUESDAY = 'tuesday'
const WEDNESDAY = 'wednesday'
const THURSDAY = 'thursday'
const FRIDAY = 'friday'
const SATURDAY = 'saturday'
const SUNDAY = 'sunday'

// Please keep Sunday first for compatibility with JS (Sunday = 0)
export const WEEKS = [SUNDAY, MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY]
export const MONDAY_FIRST_WEEKS = [MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY]
export const ALPHABET = ['', ...Array.from({length: 26}, (_, i) => `-${String.fromCharCode(97 + i)}`)]
export const COLOR_STORE_ARRIVAL_DATE = '#279d93'
export const COLOR_RECIPE = 'rgba(0,113,103,0.15)'
