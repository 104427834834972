<template lang="pug">
j-dialog-container(
  :label='item.category + " " + item.subcategory',
  :show-bottom='false',
  :value='show',
  :width='1600'
  mode='create'
  @on-close='$emit("on-close")')
  v-row
    v-col(cols='6')
      .d-flex.align-center
        span.product-span {{$t('processed_product.category')}}
        .value-box.product-col
          v-text-field(:disabled='disabled', :value='item.category' dense hide-details outlined readonly)
  v-row
    v-col(cols='6')
      .d-flex.align-center
        span.product-span {{$t('processed_product.subcategory')}}
        .value-box.product-col
          v-text-field(:disabled='disabled', :value='item.subcategory' dense hide-details outlined readonly)
  v-row
    v-col(cols='6')
      .d-flex.align-center
        span.product-span {{$t('processed_product.customer')}}
        .value-box.product-col
          v-text-field(:disabled='disabled', :value='item.customer_name' dense hide-details outlined readonly)
  v-row
    v-col(cols='6')
      .d-flex.align-center
        span.product-span {{$t('processed_product.modular_code')}}
        .value-box.product-col
          v-text-field(:disabled='disabled', :value='item.modular_code' dense hide-details outlined readonly)
    v-col(cols='6')
      .d-flex.align-center.justify-end
        span.product-span-2 {{$t('processed_product.jan')}}
        .value-box.product-col
          v-text-field(:disabled='disabled', :value='item.jan' dense hide-details outlined readonly)
  v-row
    v-col(cols='6')
      .d-flex.align-center
        span.product-span {{$t('processed_product.activating_date')}}
        .value-box.product-col
          common-date-picker(
            :date='item.activating_date',
            :disable='disabled',
            :is-show-label='false',
            :label='$t("d2_wl_closing.filter.sales_date")',
            :outlined='true',
            :picker-key='1',
            :required='true'
            dense='')
    v-col(cols='6')
      .d-flex.align-center.justify-end
        span.product-span-2 {{$t('processed_product.processing_company')}}
        .value-box.product-col
          v-text-field(:disabled='disabled', :value='item.processing_company' dense hide-details outlined readonly)
  .overflow-auto-x
    j-common-form-table.pt-3(
      :box-type-groups='boxTypeGroups',
      :colors='colors',
      :columns='headers',
      :disabled='disabled',
      :domId='"PRODUCT"',
      :is-auto-add-empty-row='false',
      :is-clip-over-flow='true',
      :quality-groups='qualityGroups',
      :rows='item.bouquets',
      :show-show-hide-dialog='false',
      :size-groups='sizeGroups',
      :unit-groups='unitGroups')
  #wrapper-scrollbar-bottom-PRODUCT.wrapper-scrollbar-bottom
    #scrollbar-bottom-container-PRODUCT.scrollbar-bottom-container
</template>

<script>
  import {defineComponent, getCurrentInstance, watch, ref} from 'vue'
  import JDialogContainer from '../JDialogContainer/index'
  import {CommonDatePicker, JCommonFormTable} from '@/components'
  import {columns} from '../JProcessProduct/index'
  import {MONDAY_FIRST_WEEKS} from '@/utils/constants'

  const JProcessProduct = defineComponent({
    props: {
      show: {default: false},
      disabled: {default: false},
      sizes: {default: () => []},
      qualities: {default: () => []},
      units: {default: () => []},
      item: {default: () => []},
      sizeGroups: {default: () => []},
      boxTypeGroups: {default: () => []},
      unitGroups: {default: () => []},
      qualityGroups: {default: () => []},
      colors: {default: () => []},
      listProcessing: {default: () => []}
    },
    components: {JCommonFormTable, JDialogContainer, CommonDatePicker},
    setup(props) {
      const instance = getCurrentInstance()
      const {$root} = instance.proxy
      const headers = ref([...columns])
      const filters = ref({
        store_arrival_date: null,
        customer_id: null
      })
      const generateHeaderProductDetail = () => {
        // create header for product detail through delivery_lead_days
        const deliveryLeadDays = props.item.delivery_lead_days
        const daysOfWeek = MONDAY_FIRST_WEEKS
        const headerWeeks = daysOfWeek.map(day => {
          const daysBack = deliveryLeadDays[day]
          const currentIndex = daysOfWeek.indexOf(day)
          const targetIndex = (currentIndex - daysBack + 7) % 7
          const targetDay = daysOfWeek[targetIndex]
          return {
            customHeaderName: `${$root.$t(`table_common.${day}`)}>${$root.$t(`table_common.${targetDay}`)}`,
            name: day,
            sortable: false,
            align: 'right',
            isActive: true,
            onEnableEditMode: () => false
          }
        })
        headers.value = [...headers.value, ...headerWeeks]
      }
      watch(
        () => props.show,
        () => {
          headers.value = columns
          if (props.show) generateHeaderProductDetail()
        }
      )
      return {
        columns,
        filters,
        headers
      }
    }
  })

  export default JProcessProduct
</script>

<style lang="sass" scoped>
  .msg-mix
    font-style: italic
  .info-assign
    span
      font-size: 20px
      color: #111111

  .wrapper-scrollbar-bottom
    width: 100%
    position: relative
    top: -16px
    left: 4px
    overflow-x: auto
    overflow-y: hidden
  .scrollbar-bottom-container
    width: 100%
    height: 1px
  .label
    font-weight: bold
    margin-bottom: 4px
    display: block

  ::v-deep .v-input--is-disabled .v-input__control
    background-color: #f3f3f3cc
  ::v-deep .v-input--is-disabled input
    color: black
  .product-col
    flex: 0 0 70%
  .product-span
    flex: 0 0 20%
    margin-right: 8px
    color: #000000
    align-content: center
  .product-span-2
    flex: 0 0 10%
    margin-right: 8px
    color: #000000
    align-content: center
  .wrapper-scrollbar-bottom
    width: calc(100% - 8px)
    position: relative
    top: 0
    left: 4px
    overflow-x: auto
    overflow-y: hidden
  .scrollbar-bottom-container
    width: 100%
    height: 1px
</style>
