import router from '../../router'
import Store from '../../store'
import {api, i18n} from '@/plugins'
import Vue, {getCurrentInstance} from 'vue'
import {endpoints, showError, query, convertToWidthCharacter} from '@/utils'
import {TaxType, TaxTypeId} from '@/utils/constants'
import moment from 'moment'
import _ from 'lodash'
import {DAILY_UPDATE} from '../apiEndpoints'
import {TaxFractionId} from '../constants'
import {getCodeNameStr} from '../string'

export const replaceUrl = ({query, params}) => {
  // type query and params is object
  // exp: {id: 1}
  router
    .replace({
      query: query,
      params: params
    })
    .catch(e => {
      return
    })
}

export const numberToString = (number, isRounding = false, maximumFractionDigits = 2, showEmpty = false) => {
  if (number && isRounding) number = Math.round(number)
  if ((number === null || number === undefined) && showEmpty) {
    return ''
  }
  return number ? number.toLocaleString('en-US', {maximumFractionDigits}) : '0'
}

export const convertMinusJpToLatin = input => {
  // convert string '-' fullwidth, halfwidth to '-' latin
  let result = input
    ? input
        .toString()
        .replaceAll(/−|ｰ|ー/gi, '-')
        .normalize('NFKD')
    : ''
  if (isNaN(result)) {
    result = ''
  }
  return result
}

export const backPage = () => {
  history.back()
}

export const getData = async (listData = [], queryData = {}, getOne = {}) => {
  const result = {
    items: null,
    varieties: null,
    colors: null,
    units: null,
    grades: null,
    arrival_types: null,
    articles: null,
    daily_update: null
  }

  const queryItem = queryData.item ? query.buildQuery(queryData.item) : ''
  const queryVariety = queryData.variety ? query.buildQuery(queryData.variety) : ''
  const queryMaterialVariety = queryData.material_variety ? query.buildQuery(queryData.material_variety) : ''
  const queryPast = queryData.past ? query.buildQuery(queryData.past) : ''
  const queryShipper = queryData.shipper ? query.buildQuery(queryData.shipper) : 'page=1&per_page=100&active=true'
  const varietyId = getOne.variety || ''
  try {
    await Promise.all([
      listData.includes('item')
        ? api.get(`${endpoints.MASTER_ITEM}?${queryItem}`).then(value => (result.items = value.data))
        : null,
      listData.includes('past_item')
        ? api.get(`${endpoints.PAST_ITEM}?${queryItem}&${queryPast}`).then(value => (result.items = value.data))
        : null,
      listData.includes('variety')
        ? api
            .get(`${endpoints.MASTER_VARIETY}${varietyId}?${queryVariety}`)
            .then(value => (result.varieties = value.data))
        : null,
      listData.includes('past_variety')
        ? api
            .get(`${endpoints.PAST_VARIETY}${varietyId}?${queryVariety}&${queryPast}`)
            .then(value => (result.varieties = value.data))
        : null,
      listData.includes('color') ? api.get(endpoints.MASTER_COLOR).then(value => (result.colors = value.data)) : null,
      listData.includes('arrival_type')
        ? api.get(endpoints.MASTER_ARRIVAL_TYPE).then(value => (result.arrival_types = value.data))
        : null,
      listData.includes('grade') || listData.includes('rank')
        ? api.get(endpoints.MASTER_GRADE).then(value => {
            if (listData.includes('grade')) result.grades = value.data
            if (listData.includes('rank')) result.ranks = value.data
          })
        : null,
      listData.includes('past_grade') || listData.includes('past_rank')
        ? api.get(`${endpoints.PAST_GRADE}?${queryPast}`).then(value => {
            if (listData.includes('past_grade')) result.grades = value.data
            if (listData.includes('past_rank')) result.ranks = value.data
          })
        : null,
      listData.includes('bloom_num')
        ? api.get(endpoints.MASTER_BLOOM).then(value => (result.bloom_nums = value.data))
        : null,
      listData.includes('past_bloom_num')
        ? api.get(`${endpoints.PAST_BLOOM_NUM}?${queryPast}`).then(value => (result.bloom_nums = value.data))
        : null,
      listData.includes('packing')
        ? api.get(endpoints.MASTER_PACKING).then(value => (result.packings = value.data))
        : null,
      listData.includes('article')
        ? api.get(endpoints.MASTER_ARTICLE).then(value => (result.articles = value.data))
        : null,
      listData.includes('past_article')
        ? api.get(`${endpoints.PAST_ARTICLE}?${queryPast}`).then(value => (result.articles = value.data))
        : null,
      listData.includes('country')
        ? api.get(endpoints.MASTER_COUNTRY).then(value => (result.countries = value.data))
        : null,
      listData.includes('mps') ? api.get(endpoints.MASTER_MPS).then(value => (result.mps = value.data)) : null,
      listData.includes('daily_update')
        ? api.get(endpoints.DAILY_UPDATE).then(value => (result.daily_update = value.data))
        : null,
      listData.includes('unit')
        ? api.get(`${endpoints.MASTER_UNIT}?active=true`).then(value => (result.units = value.data))
        : null,
      listData.includes('prefecture')
        ? api.get(endpoints.MASTER_PREFECTURE).then(value => (result.prefectures = value.data))
        : null,
      listData.includes('organization_type')
        ? api.get(endpoints.MASTER_ORGANIZATION_TYPE).then(value => (result.organizationTypes = value.data))
        : null,
      listData.includes('shipper')
        ? api.get(`${endpoints.MASTER_SHIPPER}?${queryShipper}`).then(value => (result.shippers = value.data))
        : null,
      listData.includes('carrier')
        ? api.get(endpoints.MASTER_CARRIER).then(value => (result.carriers = value.data))
        : null,
      listData.includes('tax_type')
        ? api.get(endpoints.MASTER_TAX_TYPE).then(value => (result.tax_types = value.data))
        : null,
      listData.includes('processing_companies')
        ? api.get('/api/processing-companies').then(value => (result.processing_companies = value.data))
        : null,
      listData.includes('customers') ? api.get('/api/customers').then(value => (result.customers = value.data)) : null,
      listData.includes('material_families')
        ? api.get('/api/material-families').then(value => (result.material_families = value.data))
        : null,
      listData.includes('material_varieties')
        ? api
            .get(`/api/material-varieties?${queryMaterialVariety}`)
            .then(value => (result.material_varieties = value.data))
        : null,
      listData.includes('suppliers') ? api.get('/api/suppliers').then(value => (result.suppliers = value.data)) : null,
      listData.includes('grades') ? api.get('/api/grades').then(value => (result.grades = value.data)) : null
    ])
  } catch (e) {
    showError(e, Vue.$toast, i18n.t('common.msg.system_failure'))
  }
  return result
}
const returnDate = (year, month, date, format) => {
  let returnYear = year
  if (parseInt(year) < 100) {
    returnYear = `20${year}`
  } else if (year === null) {
    returnYear = new Date().getFullYear()
  }
  let lastDayOfMonth = moment(`${returnYear}-${month}`).endOf('month').format('DD')
  let dateOfMonth = date
  let monthOfYear = parseInt(month) - 1
  if (month > 12) {
    monthOfYear = 11
  }
  if (parseInt(date) > parseInt(lastDayOfMonth)) {
    dateOfMonth = lastDayOfMonth
  }
  if (lastDayOfMonth === 'Invalid date') {
    // if (year === null) {
    //     return `${month}${date}`
    // }
    // return `${year}${month}${date}`
    return null
  }
  return moment().year(returnYear).month(monthOfYear).date(dateOfMonth).format(format)
}
export const pickDate = (input, format = 'YYYY-MM-DD') => {
  input = convertToWidthCharacter(input, 'half')

  if (input === null) {
    return input
  }
  if (input === undefined) {
    return input
  }
  const v = input.trim()
  // 当月月末日を取得
  const last = moment().endOf('month').date()

  // 数値1-2桁のみ受け付ける
  switch (input.length) {
    case 1: {
      // 「a」なら月初
      if (v === 'a') {
        return moment().date(1).format(format)
      }
      // 「z」なら月末
      if (v === 'z') {
        return moment().date(last).format(format)
      }
      // 「0」なら当日
      if (v === '0') {
        return moment().format(format)
      }

      if (parseInt(v) > 0 && parseInt(v) < 10) {
        return moment().date(parseInt(v)).format(format)
      }
      if (isNaN(parseInt(v))) {
        return null
      }
      return v
    }
    case 2: {
      const p = /^([0-9]{1,2})$/
      if (!p.test(v)) {
        return null
      }
      if (v === '00') {
        return null
      }
      // 当月月初から月末日までの日付分の数値を列挙
      const dates = [...Array(last).keys()].map(i => ++i)
      // 当月の有効な日付のみ受け付ける
      if (!dates.includes(parseInt(v, 10))) {
        return v
      }
      // 日付を設定して、有効な日付か最終チェック。
      const x = moment().date(v)
      if (x.isValid()) {
        return x.format(format)
      }
      return v
    }
    case 3: {
      let month3 = input.slice(0, 1)
      let day3 = input.slice(1, 3)
      return returnDate(null, '0' + month3, day3, format)
    }
    case 4: {
      let month4 = input.slice(0, 2)
      let day4 = input.slice(2, 4)
      return returnDate(null, month4, day4, format)
    }

    case 6: {
      let year6 = input.slice(0, 2)
      let month6 = input.slice(2, 4)
      let day6 = input.slice(4, 6)
      return returnDate(year6, month6, day6, format)
    }
    case 8: {
      let cleanedString = input.replace(/[/-]/g, '')
      let year
      let month
      let day
      if (cleanedString.length == 6) {
        year = cleanedString.slice(0, 2)
        month = cleanedString.slice(2, 4)
        day = cleanedString.slice(4, 6)
      } else {
        year = input.slice(0, 4)
        month = input.slice(4, 6)
        day = input.slice(6, 8)
      }
      return returnDate(year, month, day, format)
    }
    case 10: {
      let year10 = input.slice(0, 4)
      let month10 = input.slice(5, 7)
      let day10 = input.slice(8, 10)
      return returnDate(year10, month10, day10, format)
    }
    default:
      return null
  }
}

export const checkIsOnPC = () => {
  // BREAK USER AGENT DOWN
  const isMobile = navigator.userAgent.toLowerCase().match(/mobile/i)
  const isTablet = navigator.userAgent.toLowerCase().match(/tablet/i)
  const isAndroid = navigator.userAgent.toLowerCase().match(/android/i)
  const isiPhone = navigator.userAgent.toLowerCase().match(/iphone/i)
  const isiPad = navigator.userAgent.toLowerCase().match(/ipad/i)
  return !(isMobile || isTablet || isAndroid || isiPhone || isiPad)
}

export const checkScreenSize = () => {
  if (540 < screen.width && screen.width < 900) return 'tablet'
  else if (screen.width <= 540) return 'mobile'
  else return 'pc'
}

export const compactText = (text, maxLength) => {
  if (!text) return
  if (text.length < maxLength) {
    return text
  } else {
    return `${text.slice(0, maxLength - 1)}...`
  }
}

/**
 * Deep diff between two object, using lodash
 *
 * @param object
 * @param base
 * @param deep
 * @returns
 */
export function objectDiff(object, base, deep = false) {
  function changes(object, base) {
    return _.transform(object, function (result, value, key) {
      if (!_.isEqual(value, base[key])) {
        result[key] =
          _.isObject(value) && _.isObject(base[key]) && deep ? changes(value, base[key]) : value === '' ? null : value
      }
    })
  }
  return changes(object, base)
}

export const calculateTax = (totalAmount, percentage, type) => {
  let product, tax
  if (type === TaxType.NO_TAX) {
    product = totalAmount
    tax = 0
  } else if (type === TaxType.INCLUDED) {
    product = (totalAmount / (percentage + 100)) * 100
    tax = totalAmount - product
  } else {
    product = totalAmount
    tax = (product * percentage) / 100
  }
  return [product, tax]
}

export const postData = async (endpoint, payload, showMsg = true) => {
  try {
    const {data} = await api.post(endpoint, payload)
    if (showMsg) Vue.$toast.success(i18n.t('common.msg.create_success'))
    return data
  } catch (e) {
    showError(e, Vue.$toast, i18n.t('common.msg.create_failed'))
  }
  return null
}

export const putData = async (endpoint, data) => {
  try {
    const response = await api.put(`${endpoint}`, data)
    Vue.$toast.success(i18n.t('common.msg.update_success'))
    return response.data
  } catch (e) {
    showError(e, Vue.$toast, i18n.t('common.msg.update_failed'))
  }
  return null
}

export const roundTax = (value, type) => {
  if (!value) return value
  if (type === 'ceil') return Math.ceil(value)
  else if (type === 'floor') return Math.floor(value)
  const round = Math.round(Math.abs(value))
  if (value < 0) return round * -1
  return round
}
export const validateEmail = email => {
  if (email) {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return emailRegex.test(email)
  } else return true
}
export const setupScroll = (wrapperScrollbarBottomRef, vTableRef, scrollBarBottomContainerRef) => {
  const wrapperScrollbarBottom = wrapperScrollbarBottomRef.value
  const wrapperTableContainer = vTableRef.value?.$el.querySelector('.v-data-table__wrapper')
  const tableContainer = vTableRef.value?.$el.querySelector('table')

  const handeScroll = event => {
    const scrollLeftValue = event?.target.scrollLeft
    tableContainer.style.transform = `translateX(${-scrollLeftValue}px)`
    wrapperScrollbarBottom.scrollLeft = scrollLeftValue
  }

  wrapperScrollbarBottom?.addEventListener('scroll', handeScroll)

  let resizeObsBottom = new ResizeObserver(entries => {
    for (let entry of entries) {
      const cr = entry.contentRect
      const scrollbarBottomContainer = scrollBarBottomContainerRef.value
      scrollbarBottomContainer.style.width = cr.width.toString() + 'px'
    }
  })

  if (tableContainer && wrapperTableContainer) {
    resizeObsBottom.observe(tableContainer)
    return () => {
      resizeObsBottom.unobserve(tableContainer)
    }
  }

  // Return null if no observation is set up
  return null
}
export const validateDataFeeTable = (itemFee, arItem) => {
  return itemFee
    .filter(item => arItem.some(valItem => valItem.id === item.ar_item_id))
    .map(item => {
      // update name
      const validateItem = arItem.find(valItem => valItem.id === item.ar_item_id)
      if (validateItem) {
        return {...item, fee: validateItem.name}
      }
      return item
    })
}
//Customize the filter function of v-autocomplete
export const filterBySearchString = (item, queryText) => {
  const normalizeQueryText = convertToWidthCharacter(queryText?.normalize('NFKC'), 'full').toLowerCase()
  const searchstr = item.searchstr ?? item.name
  if (searchstr) {
    const normalizeSearchStr = convertToWidthCharacter(searchstr?.normalize('NFKC'), 'full').toLowerCase()
    if (normalizeSearchStr.includes(normalizeQueryText)) return item
  }
  return undefined
}

export const filterOnComboBox = (items, queryText, splitters = [':']) => {
  if (!queryText || queryText === '') {
    return items
  }
  let searches = [queryText]
  const normalizeQueryText = convertToWidthCharacter(queryText?.normalize('NFKC'), 'full').toLowerCase().trim()

  if (splitters.length) {
    // split string 'code: name(description~)' to array ["code", "name", "description~"]
    const splitterRegex = RegExp('[^' + _.join(splitters, '/') + ']+', 'g')
    searches = _.words(normalizeQueryText, splitterRegex)
    if (!searches.length) searches = [normalizeQueryText]
  }

  const searchItemsIncludes = items.filter(item => {
    const searchStr = item.searchstr
      ? convertToWidthCharacter(item.searchstr?.normalize('NFKC'), 'full').toLowerCase()
      : convertToWidthCharacter(`${item.code}: ${item.name}`.normalize('NFKC'), 'full').toLowerCase()
    return searches.every(word => searchStr.normalize('NFKC').includes(word.trim().normalize('NFKC')))
  })

  // Sort by priority: code > name
  searchItemsIncludes.sort((a, b) => {
    const startsWithComparison = (valueA, valueB) =>
      valueB.toLowerCase().startsWith(normalizeQueryText) - valueA.toLowerCase().startsWith(normalizeQueryText)
    return (
      startsWithComparison(
        convertToWidthCharacter(String(a.code || '')?.normalize('NFKC'), 'full'),
        convertToWidthCharacter(String(b.code || '')?.normalize('NFKC'), 'full')
      ) ||
      startsWithComparison(a.code || '', b.code || '') ||
      startsWithComparison(a.name || '', b.name || '')
    )
  })
  return [...searchItemsIncludes]
}

export const normalizeString = str => {
  str = str
    ? str
        .toString()
        .replaceAll(/−|ｰ|ー/gi, '-')
        .normalize('NFKD')
    : ''
  return convertToWidthCharacter(str.normalize('NFKC'), 'half').toLowerCase()
}
/**
 * Retrieves the first item from the provided list that matches the input text.
 * If no match is found, returns null.
 *
 * @param {string} text - The input text to search for.
 * @param {Array} propsList - The list of items to search within.
 * @returns {Object|null} - The first item that matches the input text, or null if no match is found.
 */
export const getDataByInputText = (text, propsList, forceCompareCodeName = false) => {
  if (!text) text = ''
  const matchedLst = filterOnComboBox(propsList, text)
  return matchedLst.length > 0 ? matchedLst[0] : null
}
export function safeParseInt(str) {
  if (/^\d+$/.test(str)) return parseInt(str, 10)
  return NaN
}

class ChangeTracker {
  constructor() {
    this._store = new WeakMap()
  }
  track(object) {
    // Clone the object
    const clone = _.cloneDeep(object)
    // Store it
    this._store.set(object, clone)
  }
  getOriginal(object) {
    // Access everything so if this function is used inside a
    // computed property, Vue will refresh the computed value
    // when object changes
    for (const prop in object) {
      // eslint-disable-next-line no-unused-vars
      const __ = object[prop]
    }
    // When return original, deep clone it so whoever
    // uses the result won't accidentally change the original value
    return _.cloneDeep(this._store.get(object))
  }
  getRawOriginal(object) {
    return this._store.get(object)
  }
  changedProps(object) {
    const clone = this.getOriginal(object)
    if (clone === undefined) {
      console.warn('This object was not tracked. Call objectTracker.track(object) first.')
      return undefined
    }
    const props = []
    for (const prop in object) {
      if (!_.isEqual(object[prop], clone[prop])) {
        props.push(prop)
      }
    }
    return props
  }
  changed(object) {
    const objChanged = this.changedProps(object)
    if (objChanged === undefined) return undefined
    return _.pick(object, objChanged)
  }
  /**
   * Mark a property as unchanged by copying value back to original object
   */
  setUnchanged(object, prop) {
    const original = this._store.get(object)
    original[prop] = _.cloneDeep(object[prop])
  }
}

export const changeTracker = new ChangeTracker()

export const calculateFreightAmount = (data, rowIdx) => {
  if (data[rowIdx].freight_unit_cost && data[rowIdx].freight_bundle_num) {
    data[rowIdx].freight_amount =
      parseFloat(data[rowIdx].freight_unit_cost) * parseFloat(data[rowIdx].freight_bundle_num)
  } else {
    data[rowIdx].freight_amount = 0
  }
  // remove #ERROR in freight_amount if have
  if (data[rowIdx].$isValidate) delete data[rowIdx].$isValidate['freight_amount']
}

/**
 * Calculate freight tax for rows[rowIdx]
 * freight_tax = freight_amount * tax_rate if tax_type = 外税, then round based on fraction_type
 * freight_tax = freight_amount - (freight_amount/ (1 + tax_rate)) tax_rate if tax_type = 内税, then round based on fraction_type
 * otherwise freight_tax = 0
 */
export const calculateFreightTax = (data, rowIdx, taxFraction, taxType, taxRate) => {
  let taxAmount = 0
  const currentTaxType = taxType
  let freightAmount = parseFloat(data[rowIdx].freight_amount)
  let currentTaxRate = parseFloat(taxRate) / 100
  // Prevent NaN values
  if (isNaN(freightAmount)) freightAmount = 0
  if (isNaN(currentTaxRate)) currentTaxRate = 0
  // Calculate tax amount based on tax_type
  if (currentTaxType) {
    if (currentTaxType.code === TaxTypeId.TAX_EXCLUDED) taxAmount = freightAmount * currentTaxRate
    if (currentTaxType.code === TaxTypeId.TAX_INCLUDED) taxAmount = freightAmount - freightAmount / (1 + currentTaxRate)
    if (currentTaxType.code === TaxTypeId.TAX_FREE) taxAmount = 0
  }

  // Round tax amount based on tax fraction
  if (taxFraction && taxFraction.code === TaxFractionId.ROUND_CEIL) taxAmount = Math.ceil(taxAmount)
  else if (taxFraction && taxFraction.code === TaxFractionId.ROUND_FLOOR) taxAmount = Math.floor(taxAmount)
  else taxAmount = Math.round(taxAmount)
  data[rowIdx].freight_tax = taxAmount
}

/**
 * calculate total freight_amount after tax
 * total_freight_amount = freight_amount + freight_tax if tax_type is 外税
 * Otherwise, total_freight_amount = freight_amount
 */
export const calculateTotal = (data, rowIdx, taxType) => {
  const currentTaxType = taxType
  const freightAmt = parseFloat(data[rowIdx].freight_amount ?? 0)
  const taxAmount = parseFloat(data[rowIdx].freight_tax ?? 0)
  if (currentTaxType && currentTaxType.code === TaxTypeId.TAX_EXCLUDED) {
    data[rowIdx].total = freightAmt + taxAmount
  } else data[rowIdx].total = freightAmt
}

export const calFreightTax = (freightAmount, taxRate, taxType, taxFraction) => {
  let freightTax = 0
  let total = 0
  let currentTaxRate = parseFloat(taxRate) / 100
  let currentFreightAmount = parseFloat(freightAmount)
  // Prevent NaN values
  if (isNaN(currentFreightAmount)) currentFreightAmount = 0
  if (isNaN(currentTaxRate)) currentTaxRate = 0
  // Calculate tax amount based on tax_type
  if (taxType) {
    if (taxType.code === TaxTypeId.TAX_EXCLUDED) freightTax = currentFreightAmount * currentTaxRate
    if (taxType.code === TaxTypeId.TAX_INCLUDED)
      freightTax = currentFreightAmount - currentFreightAmount / (1 + currentTaxRate)
    if (taxType.code === TaxTypeId.TAX_FREE) freightTax = 0
  }

  // Round tax amount based on tax fraction
  if (taxFraction && taxFraction.code === TaxFractionId.ROUND_CEIL) freightTax = Math.ceil(freightTax)
  else if (taxFraction && taxFraction.code === TaxFractionId.ROUND_FLOOR) freightTax = Math.floor(freightTax)
  else freightTax = Math.round(freightTax)

  //calculate total
  if (taxType && taxType.code === TaxTypeId.TAX_EXCLUDED) {
    total = currentFreightAmount + freightTax
  } else total = currentFreightAmount

  return [freightTax, total]
}

export const sortOnCombobox = (items, queryText) => {
  const normalizeQueryText = convertToWidthCharacter(queryText?.normalize('NFKC'), 'full').toLowerCase().trim()
  const searchItemsIncludes = items.sort((a, b) => {
    const startsWithComparison = (valueA, valueB) =>
      valueB.toLowerCase().startsWith(normalizeQueryText) - valueA.toLowerCase().startsWith(normalizeQueryText)
    return (
      startsWithComparison(
        convertToWidthCharacter(String(a.code || '')?.normalize('NFKC'), 'full'),
        convertToWidthCharacter(String(b.code || '')?.normalize('NFKC'), 'full')
      ) ||
      startsWithComparison(a.code || '', b.code || '') ||
      startsWithComparison(a.name || '', b.name || '')
    )
  })
  return searchItemsIncludes
}

export const setupCustomScroll = (wrapperScrollbarId, scrollbarContainerId, pulledTargetId) => {
  const wrapperScrollbarBot = document.getElementById('wrapper-scrollbar-' + wrapperScrollbarId)
  const wrapperTableContainer = document.getElementById(pulledTargetId)
  const tableContainer = wrapperTableContainer.querySelector('table')
  function syncScroll(event) {
    const scrollLeftValue = event?.target.scrollLeft
    tableContainer.style.transform = `translateX(${-scrollLeftValue}px)`
    if (event.target === wrapperScrollbarBot) {
      if (wrapperScrollbarBot) {
        wrapperScrollbarBot.scrollLeft = scrollLeftValue
      }
    }
  }
  // //Take all element change attributes and style in mutationsList
  function updateScrollWhenTableContainerMove(mutationsList, observer) {
    mutationsList.forEach(mutation => {
      if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
        const element = mutation.target
        const style = window.getComputedStyle(element)
        const translateXValue =
          style.transform && style.transform !== 'none'
            ? style.transform.split('(')[1].split(')')[0].split(',')[4]
            : '0'
        if (wrapperScrollbarBot) wrapperScrollbarBot.scrollLeft = -translateXValue
      }
    })
  }
  const observer = new MutationObserver(updateScrollWhenTableContainerMove)
  const config = {attributes: true, attributeFilter: ['style']}
  // Start observe tableContainer change
  observer.observe(tableContainer, config)
  wrapperScrollbarBot?.addEventListener('scroll', syncScroll)

  let resizeObs = new ResizeObserver(entries => {
    for (let entry of entries) {
      const cr = entry.contentRect
      const scrollbarBotContainer = document.getElementById('scrollbar-container-' + scrollbarContainerId)
      // padding left 4px, padding right 4px, need to plus 8px
      if (scrollbarBotContainer) scrollbarBotContainer.style.width = cr.width.toString() + 'px'
      // Fix bug on Windows: Set height != 0 for scrollbar bot
      // to make sure browser can detect it and handle scroll for it.
      scrollbarBotContainer.style.height = '1px'
    }
  })
  if (tableContainer && wrapperTableContainer) {
    resizeObs.observe(tableContainer)
  }
  if (wrapperTableContainer && wrapperScrollbarBot) {
    wrapperScrollbarBot.onscroll = function () {
      wrapperTableContainer.scrollLeft = wrapperScrollbarBot.scrollLeft
    }
    wrapperTableContainer.onscroll = function () {
      wrapperScrollbarBot.scrollLeft = wrapperTableContainer.scrollLeft
    }
  }
  function removeListener() {
    resizeObs.unobserve(tableContainer)
    wrapperScrollbarBot?.removeEventListener('scroll', syncScroll)
  }
  return removeListener
}
export const isNotEmpty = value => {
  return value != null && value !== '' && (!Array.isArray(value) || value.length > 0)
}

export const concatCodeName = item => {
  if (!item) return null
  const _name = item?.name ? item.name : ''
  // return empty string if no name found
  if (!_name) return ''
  if (!item.code) return _name
  if (item?.branch_code) return item.code + '-' + item.branch_code + ': ' + _name
  return item.code + ': ' + _name
}
export const concatShipperName = shipper => {
  if (!shipper) return null
  return concatShipperCode(shipper) + (': ' + shipper.name)
}

export const concatShipperCode = shipper => {
  if (!shipper) return null
  const codes = [shipper.prefecture_code, shipper.organization_type_code]
  // ex: 01-5
  if (!shipper.agri_group_code && !shipper.personal_code) return codes.join('-')
  // ex: 01-5-1001
  if (!shipper.personal_code) return [...codes, shipper.agri_group_code].join('-')
  // ex: 01-5--10
  if (!shipper.agri_group_code) return [...codes, '', shipper.personal_code].join('-')
  // ex: 01-5-1001-10
  return [...codes, shipper.agri_group_code, shipper.personal_code].join('-')
}

export const closeAllPopup = refs => {
  Object.values(refs).forEach(ref => {
    if (ref.$el.classList.contains('v-autocomplete')) ref.blur()
    if (ref.$el.classList.contains('chip-combobox') || ref.$el.classList.contains('shipper-input')) ref.closePopup()
  })
}

export const onEnterAutocomplete = ($nextTick, $refs, e) => {
  $nextTick(() => {
    $refs[`${e}`].isMenuActive = false
  })
  setTimeout(() => {
    $refs[`${e}`].blur()
  }, 200)
}

export const filterItem = (item, searchString) => {
  const normalizeString = convertToWidthCharacter(searchString ? searchString.normalize('NFKC') : '', 'half')
  const normalizeItemName = convertToWidthCharacter(item.displayName ? item.displayName.normalize('NFKC') : '', 'half')
  return normalizeItemName.includes(normalizeString)
}

//  Functions for string conversion copy from backend from python to javascript
//  文字列変換用の関数群です
export const convert = (str, option) => {
  if (!option) {
    return str
  }

  if (option.includes('r')) {
    // Convert full-width letters to half-width letters
    str = str.replace(/[Ａ-Ｚａ-ｚ]/g, match => String.fromCharCode(match.charCodeAt(0) - 65248))
  }

  if (option.includes('R')) {
    // Convert half-width letters to full-width letters
    str = str.replace(/[A-Za-z]/g, match => String.fromCharCode(match.charCodeAt(0) + 65248))
  }

  if (option.includes('n')) {
    // Convert full-width numbers to half-width numbers
    str = str.replace(/[０-９]/g, match => String.fromCharCode(match.charCodeAt(0) - 65248))
  }

  if (option.includes('N')) {
    // Convert half-width numbers to full-width numbers
    str = str.replace(/[0-9]/g, match => String.fromCharCode(match.charCodeAt(0) + 65248))
  }

  if (option.includes('a')) {
    // Convert full-width alphanumeric characters and symbols to half-width
    str = str.replace(/[Ａ-Ｚａ-ｚ０-９！＃＄％＆（）＊＋，－．／：；＜＝＞？＠［］＾＿｀｛｜｝]/g, match =>
      String.fromCharCode(match.charCodeAt(0) - 65248)
    )
  }

  if (option.includes('A')) {
    // Convert half-width alphanumeric characters and symbols to full-width
    str = str.replace(/[A-Za-z0-9!#$%&()*+,\-./:;<=>?@[\]^_`{|}]/g, match =>
      String.fromCharCode(match.charCodeAt(0) + 65248)
    )
  }

  if (option.includes('s')) {
    // Convert full-width space to half-width space (U+3000 -> U+0020)
    str = str.replace(/\u3000/g, '\u0020')
  }

  if (option.includes('S')) {
    // Convert half-width space to full-width space (U+0020 -> U+3000)
    str = str.replace(/\u0020/g, '\u3000')
  }

  if (option.includes('c')) {
    // Convert full-width Katakana to full-width Hiragana
    str = str
      .replace(/[ァ-ン]/g, match => String.fromCharCode(match.charCodeAt(0) - 0x60))
      .replace('ヴ', 'う゛')
      .replace('ヵ', 'か')
      .replace('ヽ', 'ゝ')
      .replace('ヾ', 'ゞ')
  }

  if (option.includes('C')) {
    // Convert full-width Hiragana to full-width Katakana
    str = str
      .replace(/[ぁ-ん]/g, match => String.fromCharCode(match.charCodeAt(0) + 0x60))
      .replace('ゝ', 'ヽ')
      .replace('ゞ', 'ヾ')
  }

  if (option.includes('v')) {
    // Convert "う濁" to "は濁"
    str = str
      .replace(/(う゛ぁ|ゔぁ)/g, 'ば')
      .replace(/(う゛ぃ|ゔぃ)/g, 'び')
      .replace(/(う゛ぇ|ゔぇ)/g, 'べ')
      .replace(/(う゛ぉ|ゔぉ)/g, 'ぼ')
      .replace(/(う゛|ゔ)/g, 'ぶ')
  }

  if (option.includes('V')) {
    // Convert "ウ濁" to "ハ濁"
    str = str
      .replace(/(ウ゛ァ|ヴァ)/g, 'バ')
      .replace(/(ウ゛ィ|ヴィ)/g, 'ビ')
      .replace(/(ウ゛ェ|ヴェ)/g, 'ベ')
      .replace(/(ウ゛ォ|ヴォ)/g, 'ボ')
      .replace(/(ウ゛|ヴ)/g, 'ブ')
  }

  if (option.includes('Q')) {
    // Convert half-width quotes and apostrophes to full-width
    str = str.replace(/"/g, '＂').replace(/'/g, '＇')
  }

  if (option.includes('q')) {
    // Convert full-width quotes and apostrophes to half-width
    str = str.replace(/＂/g, '"').replace(/＇/g, "'")
  }

  if (option.includes('B')) {
    // Convert half-width backslash to full-width
    str = str.replace(/\\/g, '＼')
  }

  if (option.includes('b')) {
    // Convert full-width backslash to half-width
    str = str.replace(/＼/g, '\\')
  }

  if (option.includes('T')) {
    // Convert half-width tilde to full-width tilde
    str = str.replace(/~/g, '〜')
  }

  if (option.includes('t')) {
    // Convert full-width tilde to half-width tilde
    str = str.replace(/～/g, '~')
  }

  if (option.includes('W')) {
    // Convert full-width wave dash to full-width tilde
    str = str.replace(/〜/g, '～')
  }

  if (option.includes('w')) {
    // Convert full-width tilde to full-width wave dash
    str = str.replace(/～/g, '〜')
  }

  if (option.includes('P')) {
    // Convert half-width dashes to full-width dashes
    str = str.replace(/ー/g, '－').replace(/ｰ/g, '－')
  }

  if (option.includes('p')) {
    // Convert full-width dashes to half-width dashes
    str = str.replace(/－/g, '-')
  }

  if (option.includes('U')) {
    // Convert half-width symbols to full-width symbols
    const hanMap = {
      '⦅': '｟',
      '⦆': '｠',
      '¢': '￠',
      '£': '￡',
      '¬': '￢',
      '¯': '￣',
      '¦': '￤',
      '¥': '￥',
      '₩': '￦',
      '￨': '│',
      '￩': '←',
      '￪': '↑',
      '￫': '→',
      '￬': '↓',
      '￭': '■',
      '￮': '○'
    }
    Object.keys(hanMap).forEach(char => {
      str = str.replace(new RegExp(char, 'g'), hanMap[char])
    })
  }

  if (option.includes('u')) {
    // Convert full-width symbols to half-width symbols
    const zenMap = {
      '｟': '⦅',
      '｠': '⦆',
      '￠': '¢',
      '￡': '£',
      '￢': '¬',
      '￣': '¯',
      '￤': '¦',
      '￥': '¥',
      '￦': '₩',
      '│': '￨',
      '←': '￩',
      '↑': '￪',
      '→': '￫',
      '↓': '￬',
      '■': '￭',
      '○': '￮'
    }
    Object.keys(zenMap).forEach(char => {
      str = str.replace(new RegExp(char, 'g'), zenMap[char])
    })
  }

  if (option.includes('X')) {
    // Convert specific characters
    const kakomiMap = {'⑴': '(1)'}
    Object.keys(kakomiMap).forEach(char => {
      str = str.replace(new RegExp(char, 'g'), kakomiMap[char])
    })
  }

  if (option.includes('Y')) {
    // Convert specific characters
    const syuugouMap = {'㌀': 'アパート'}
    Object.keys(syuugouMap).forEach(char => {
      str = str.replace(new RegExp(char, 'g'), syuugouMap[char])
    })
  }

  if (option.includes('Z')) {
    // Convert small kana to '@'
    const komojiMap = {'\ufe6b': '\u0040'}
    Object.keys(komojiMap).forEach(char => {
      str = str.replace(new RegExp(char, 'g'), komojiMap[char])
    })
  }

  // Trim and remove extra spaces except newlines
  str = str.trim().replace(/[^\S\n]+/g, ' ')

  return str
}

export const downloadFile = async (data, type, name) => {
  const url = URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a')
  link.href = url
  link.download = `${name}.${type}`
  link.click()
  link.remove()
  URL.revokeObjectURL(url)
}

export const returnValueOrNull = value => {
  if (value === null || value === undefined || value === '') return null
  return value
}

export const updateUrlQuery = (oldRouter, params) => {
  // update url query params
  const query = {...oldRouter}
  for (const key in params) {
    if (!params[key] || (Array.isArray(params[key]) && params[key].length === 0)) delete query[key]
    else query[key] = params[key].toString()
  }
  replaceUrl({query})
}

export const genListIdByString = ids => {
  if (!ids) return []
  const splitIds = ids.split(',')
  if (splitIds.length > 0) {
    return splitIds.map(item => {
      const num = Number(item)
      return !isNaN(num) ? num : item
    })
  } else return []
}

export const addDataDropDown = (columns, dataDetail) => {
  columns.map(column => {
    if (column.dropdown && dataDetail[column.name]) {
      column.items = dataDetail[column.name]
    }
    return column
  })
}
