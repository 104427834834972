import type {Ref} from 'vue'

const appVersion = process.env.VUE_APP_VERSION || '0.0.1'

export type ContextUserType = {
  id: number
  auth: string
  code: string
  name: string
  flower_type_id: number | null
  role: string
  user_type: string | null
}

export type LoginType = {
  code: string | null
  pass: string | null
}

export const loginInit = (): LoginType => ({
  code: null,
  pass: null
})

export type SelfType = {
  version: string
  user: ContextUserType
}

export type AuthType = {
  localVersion: string
  serverVersion: string
  user: ContextUserType
  isLoaded: boolean
}

export type AuthResponseType = {
  status: boolean
  messages: string[]
}

export interface IAuth {
  me: Ref<AuthType>
  reload(force?: boolean): Promise<boolean>
  login(value?: number | ContextUserType | LoginType): Promise<AuthResponseType>
  getTokenSilently(): Promise<string>
  isAuthenticated(): Promise<boolean>
  logout(): Promise<void>
}

export const initAuth = (): AuthType => {
  return {
    localVersion: appVersion,
    serverVersion: '0',
    user: {
      id: 2,
      auth: '',
      code: '',
      name: '',
      flower_type_id: null,
      role: 'user',
      user_type: 'member'
    },
    isLoaded: false
  }
}

export const buildAuth = (self: SelfType): AuthType => {
  return {
    localVersion: appVersion,
    serverVersion: self.version,
    user: self.user,
    isLoaded: true
  }
}
