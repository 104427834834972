import Vue from 'vue'
import VueI18n from 'vue-i18n'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ja from '../locale/ja.json'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import en from '../locale/en.json'

Vue.use(VueI18n)

const environment = process.env.VUE_APP_ENVIRONMENT || ''
const locale = environment.toLocaleLowerCase() === 'local' ? window.localStorage.getItem('lang') || 'ja' : 'ja'

const i18n = new VueI18n({
  locale: locale,
  messages: {
    ja,
    en
  },
  dateTimeFormats: {
    'ja-JP': {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      },
      long: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      }
    },
    'en-US': {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      },
      long: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      }
    }
  }
})

export default i18n
