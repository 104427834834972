<template lang="pug">
div(v-if='!isMobile')
  v-menu.profile-menu.black--text(ref='profileVMenu' v-model='isOpened' left offset-y)
    template(v-slot:activator='{on}')
      v-toolbar-items
        v-btn.profile-btn(v-on='on' text)
          v-list-item
            v-list-item-avatar
              img.icon(src='@/assets/user2.png')
            v-list-item-content.d-none.d-lg-flex.text-left
              v-list-item-title.title-text
                | {{currentMember.name_short ?? currentMember.name}}
              v-list-item-subtitle.grey--text
                | {{currentMember.dept}}
    template
      v-list(dense nav)
        v-list-item
          v-list-item-content.text-center
            h3 {{currentMember.name_short ?? currentMember.name}}
            v-list-item-subtitle.text--disabled
              | ID: {{currentMember.id}}
        v-list-item(:to='{name: "Logout"}' @click='logout')
          v-list-item-icon
            v-icon.primary--text mdi-logout-variant
          v-list-item-content
            v-list-item-title.rough-black Log out
div(v-else)
  v-row.pa-0.ma-0.mt-3
    v-col.ma-0.icon.pl-4(cols='1')
      img.icon(src='@/assets/user2.png')
    v-col.pl-6(cols='6')
      v-list-item-title.header-text.primary--text.title-text.mt-2
        | {{currentMember.name_short ?? currentMember.name}}
    v-col.pa-0.ma-0.mt-1(cols='4')
      v-list-item.pa-0.ma-0.logout.mt-2(:to='{name: "Logout"}' @click='logout')
        v-list-item-icon.logout.ma-0.pl-1
          v-icon.primary--text mdi-logout-variant
        v-list-item-content.ma-0.pa-0.logout
          v-list-item-title.rough-black Log out
</template>
<script>
  import {defineComponent, getCurrentInstance, ref, watch, computed} from 'vue'
  import {auth} from '@/services/auth'
  import {showError} from '@/utils'

  const ProfileMenu = defineComponent({
    props: {
      isMobile: {
        type: Boolean,
        require: false,
        default: false
      }
    },
    setup() {
      const isOpened = ref(false)
      const instance = getCurrentInstance()
      const {$store, $toast, $auth0} = instance.proxy
      const currentMember = ref({})
      const logout = async () => {
        try {
          await $auth0.logout()
        } catch (e) {
          showError(e, $toast, 'ERROR')
        }
      }

      const changeMe = user => {
        if (user) currentMember.value = user
        else currentMember.value = null
        $store.commit('setMemberInfo', currentMember.value)
      }

      watch(
        () => auth.me.value.user,
        user => changeMe(user),
        {deep: true, immediate: true}
      )

      return {
        logout,
        currentMember,
        isOpened
      }
    }
  })
  export default ProfileMenu
</script>

<style scoped lang="sass">
  @import '@/style/css/common.sass'
  .content
    max-width: 500px
  // Adjust padding to make it look balanced
  .v-btn.profile-btn
    margin-left: 8px

    .v-list-item
      max-width: 196px
      padding: 0

      .v-list-item__avatar
        margin-right: 5px

  .title-text
    color: $rough-black
  .logout
    max-height: 40px !important
    min-height: 34px
  .icon
    max-width: 41px
    height: 34px
  .header-text
    font-size: 18px
    font-weight: bold
</style>
