
  import {defineComponent, getCurrentInstance, ref} from 'vue'
  import {api} from '@/plugins'
  import {endpoints, showError} from '@/utils'
  export default defineComponent({
    props: {
      currentMember: {
        type: Object,
        default: null
      },
      haveConfirmBtn: {
        type: Boolean,
        default: false
      }
    },
    setup(props, {emit}) {
      const {$store, $toast} = getCurrentInstance().proxy
      const menu = ref(false)
      const loading = ref(false)
      const isConfirm = ref(false)
      const hideGuideline = async e => {
        if (isConfirm.value) {
          loading.value = true
          e.stopPropagation()
          try {
            const {data} = await api.patch(`${endpoints.MASTER_MEMBER}update_guideline/${props.currentMember.id}`)
            $store.commit('setMemberInfo', data)
            emit('success', data)
          } catch (e) {
            showError(e, $toast, 'ERROR')
            loading.value = false
          } finally {
            loading.value = false
            menu.value = false
          }
        } else {
          menu.value = false
        }
      }
      const handleClick = () => {
        menu.value = false
      }
      return {
        menu,
        hideGuideline,
        loading,
        handleClick,
        isConfirm
      }
    }
  })
