const filter = (query = '') => {
  // console.log(query)
}

export const propsDefault = {
  value: {
    require: true
  },
  items: {
    default: () => []
  },
  itemText: {
    default: null
  },
  subItemText: {
    default: null
  },
  hideDetails: {
    default: false
  },
  returnObject: {
    default: false
  },
  autoSelectFirst: {
    default: false
  },
  isDropdown: {
    default: true
  },
  name: {
    default: null
  },
  type: {
    default: ''
  },
  filter: {
    type: Function,
    default: filter
  },
  width: {
    default: 250
  },
  returnValue: {
    type: [String, null],
    default: null
  },
  returnCodeName: {
    type: Boolean,
    default: true
  }
}
