class BasicStorage {
  setCallbackReferrer(ref: string) {
    sessionStorage.setItem('callback_referrer', ref)
  }
  clearCallbackReferrer() {
    sessionStorage.removeItem('callback_referrer')
  }
  popCallbackReferrer(defaultValue: string) {
    const callbackReferrer = sessionStorage.getItem('callback_referrer') || defaultValue
    this.clearCallbackReferrer()
    return callbackReferrer
  }
}
export const basicStorage = new BasicStorage()
