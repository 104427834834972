
  import {defineComponent, getCurrentInstance, ref} from 'vue'
  import {PropType} from 'vue'
  import {WEEKS} from '@/utils/constants'

  import {CellMouseEvent, Column, getTable, Row} from './types'
  import moment from 'moment'
  import {getCursor} from './types'
  export default defineComponent({
    props: {
      column: {
        type: Object as PropType<Column>,
        required: true
      },
      row: {
        type: Object as PropType<Row>,
        required: true
      },
      rowIndex: {
        type: Number,
        required: true
      },
      columnIndex: {
        type: Number,
        required: true
      },
      disabledCell: {
        type: Boolean,
        default: false
      },
      possibleStoreArrivalDate: {
        type: Object,
        required: false
      }
    },
    setup(props, {emit}) {
      const on = setupEventHandlers(props)
      const instance = getCurrentInstance()
      const {$root} = instance.proxy
      const cursor = getCursor()
      const isOpenDatePicker = ref(false)

      // Allow cell slot to update input by calling onInput(value)
      const {onInput} = setupInputEvent(props)
      const getDataDisPlay = (column, row) => {
        let returnValue = ''
        if (column.displayField && row[column.name]) {
          returnValue = row[column.name][column.displayField]
        } else if (column.type === 'number') {
          returnValue =
            isNaN(Number(row[column.name])) || Number(row[column.name]) === 0
              ? row[column.name]
              : Number(row[column.name]).toLocaleString('en-US')
        } else if (column.type === 'date' && row[column.name]) {
          returnValue = moment(row[column.name]).format($root.$t('common.time_full_format') as string)
        } else {
          returnValue = row[column.name]
        }
        return returnValue
      }
      const isErrorCell = (column, row) => {
        if (!row.redBucket) return false
        return !!row.redBucket.includes(column.name)
      }
      const handleChange = (row, column, $event) => {
        isOpenDatePicker.value = false
        row[column.name] = $event
        emit('input', {row: row, column: column, value: $event, cursor: cursor})
      }
      const onCheckboxChange = () => emit('checkbox-change', props)
      // TODO: Refactor this
      // Get correct background color depends on column and row
      const genBackgroundColor = (column, row) => {
        if (column.name === 'total_bouquet' && row.disableTotalBouquet) return '#CCCCCC'
        if (row.parentId != row.id && WEEKS.includes(column.name)) return '#CCCCCC'
        if (column.isStoreArrivalDate) {
          const deliveryDate = column?.group
          if (row.product_id) {
            const keyCheck = `${row.product_id}_${row.recipe_id}_${deliveryDate}`
            const listStoreArrivalDate = props.possibleStoreArrivalDate[keyCheck] ?? []
            if (!listStoreArrivalDate.includes(column?.date)) {
              return '#CCCCCC'
            }
          }
          return ''
        }
        return ''
      }
      const genBorderBottom = row => {
        if (row.isBorderBottom) return '1px solid #333333'
        return ''
      }

      return {
        on,
        onInput,
        getDataDisPlay,
        isErrorCell,
        isOpenDatePicker,
        cursor,
        handleChange,
        onCheckboxChange,
        genBackgroundColor,
        genBorderBottom
      }
    }
  })

  function setupInputEvent(props: {row: Row; column: Column}) {
    const $table = getTable()
    return {
      onInput($event) {
        $table.$emit('input', {row: props.row, column: props.column, value: $event})
      }
    }
  }

  function setupEventHandlers(props: {rowIndex: number; columnIndex: number}) {
    const $table = getTable()

    function onClick($event: MouseEvent) {
      $table.$emit('cellclick', {
        rowIndex: props.rowIndex,
        columnIndex: props.columnIndex,
        $event
      } as CellMouseEvent)
    }

    function onDoubleClick($event: MouseEvent) {
      $table.$emit('celldblclick', {
        rowIndex: props.rowIndex,
        columnIndex: props.columnIndex,
        $event
      } as CellMouseEvent)
    }

    function onContextMenu(event: MouseEvent) {
      event.preventDefault()

      const $event: CellMouseEvent = {
        rowIndex: props.rowIndex,
        columnIndex: props.columnIndex,
        $event: event
      }

      $table.$emit('table.contextmenu', $event)
    }
    function onKeyDown($event: KeyboardEvent) {
      // console.log($event)
    }
    return {
      mousedown: onClick,
      dblclick: onDoubleClick,
      contextmenu: onContextMenu,
      keydown: onKeyDown
    }
  }
