<template lang="pug">
.d-flex.align-end
  v-menu.menu-parent(v-model='notificationsShown', :close-on-content-click='true' offset-y)
    template(v-slot:activator='{on, attrs}')
      .d-flex.justify-center(:class='isMobile ? "notification-mobile" : ""')
        span.text-center(:style='{color: "#343f4b !important", "margin-bottom": "13px"}') ver {{currentVersion}}

  v-snackbar(
    v-if='backendVer',
    :timeout='-1',
    :value='showVerNotify'
    absolute
    color='white'
    multi-line
    right
    text
    top
    vertical)
    span
      v-icon.mr-2 mdi-cog-outline
      span {{$t('system_noti.reload_msg', {ver: backendVer})}}
    template(v-slot:action='{attrs}')
      v-btn.mr-3(v-bind='attrs' color='grey darken-1' small @click='closeVerNotify(false)') {{$t('system_noti.reload_later')}}
      v-btn(v-bind='attrs' color='success' small @click='closeVerNotify(true)') {{$t('system_noti.reload_now')}}
</template>

<script lang="js">
  import moment from 'moment'
  import {defineComponent, getCurrentInstance, ref, watch} from 'vue'
  import {useStore} from '@/utils/notifications'
  import {version} from '@/../package.json'

  export default defineComponent({
    props: {
      isMobile: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    setup(props) {
      const currentVersion = ref(version)
      // const PortalSymbol: InjectionKey<'vendor' | 'merchant' | 'admin'> = Symbol('portal')
      // default vendor for jfga
      const {$root} = getCurrentInstance().proxy
      moment.locale('ja')
      const portal = 'vendor'
      const store = useStore()
      const showVerNotify = ref(false)
      const notificationsShown = ref(false)
      const listIcons = {
        al_published: 'mdi-playlist-edit',
        product_published: 'mdi-flower',
        order_modified: 'mdi-receipt-text',
        payment_ticket_modified: 'mdi-ticket-outline',
        withdrawal_modified: 'mdi-cash-refund',
        system: 'mdi-cog-outline'
      }
      const convertTime = time => {
        // Convert time to date time format
        const dateCreated = new Date(time)
        const now = new Date()
        const diff = now - dateCreated
        // Get different time from now
        // Convert time to absolute number
        const days = parseInt(diff / (1000 * 3600 * 24))
        const hours = parseInt((diff - days * (1000 * 3600 * 24)) / (1000 * 3600))
        const minutes = parseInt((diff - days * (1000 * 3600 * 24) - hours * (1000 * 3600)) / (1000 * 60))

        // Case  in today
        if (days < 1) {
          // Show time in hour when time created is more than 59 minutes
          if (hours >= 1)
            return hours === 1 ? `${hours}` + $root.$t('common.hour_ago') : `${hours}` + $root.$t('common.hour_ago')
          // Show time to Now when time created is less than 2 minutes
          else if (hours < 1 && minutes <= 1) return $root.$t('common.now')
          // Show time in minutes when time created is less than 1 hour
          else if (minutes > 1 && hours < 1) return `${minutes}` + $root.$t('common.minute_ago')
        } else if (days >= 1 && days <= 7)
          return days === 1 ? `${days}` + $root.$t('common.day_ago') : `${days}` + $root.$t('common.day_ago')
        // Case in this week, time will show in days
        // Return to normal time format
        else return `${moment(time).format($root.$t('common.time_month_date'))}`
      }

      const getRoute = item => {
        return {
          al_published: {name: 'catalog.title', query: {alId: item.data.al_id}},
          product_published: {name: 'Product', query: {productId: item.data.product_id}},
          // On merchant portal, if an order notification title has format "xxxx: #ABCXYZ from <company name>",
          // it is very likely the noti was for a subaccount order
          order_modified: {
            name: portal === 'merchant' && item.title.includes('from') ? 'SubaccountsOrders' : 'order.title',
            query: {orderId: item.data.order_id}
          },
          payment_ticket_modified: {
            name: 'PaymentTicket',
            query: {paymentTicketId: item.data.payment_ticket_id}
          },
          withdrawal_modified: {name: 'Withdrawal', query: {withdrawalId: item.data.withdrawal_id}}
        }[item.type]
      }

      const onClickNoti = async item => {
        await store.markAsRead(item)
        store.countUnread()
      }

      const closeVerNotify = async (read = false) => {
        if (read) $root.$router.go()
        showVerNotify.value = false
      }

      // Load notifications whenever the popup is shown
      watch(
        () => notificationsShown.value,
        shown => shown && store.getNotifications(false)
      )
      watch(
        () => $root.$route,
        async () => {
          store.countUnread()
          await store.getBackendVersion()
          if (store.backendVer.value && store.backendVer.value != currentVersion.value) {
            showVerNotify.value = true
          }
        }
      )

      return {
        ...store,
        listIcons,
        getRoute,
        notificationsShown,
        convertTime,
        currentVersion,
        onClickNoti,
        showVerNotify,
        closeVerNotify
      }
    }
  })
</script>

<style scoped lang="sass">
  @import "../../../node_modules/vuetify/src/styles/settings/variables"
  @import '@/style/css/variables.scss'
  @include notification_types_color()
  .notifications-hub
    padding: 0
    height: 500px
    max-width: 100vw
    width: 600px
    overflow-y: auto
  .no-notifications
    text-align: center
    padding: 64px 0
    img
      max-width: max-content
      filter: "grayscale(100%)"
      opacity: 0.2
  .v-list
    padding-bottom: 0
    .v-list-item .v-list-item__title,.v-list-item .v-list-item__subtitle
      font-weight: normal
    .v-list-item .v-list-item__title
      font-size: 0.95rem
  .load-more-btn
    background: map-get($color, 'primary')
    height: 40px
    width: 100%
    text-align: center
  .header
    position: sticky
    top: 0
    z-index: 1
  .header h2
    color: rgba(0,0,0,.64)
    font-weight: bold
    font-size: 1.2em
  .v-menu_content
    margin-top: 10px
    @media #{map-get($display-breakpoints, 'sm-and-down')}
      margin-top: 0
  ::v-deep .v-list-item__title, ::v-deep .v-list-item__subtitle
    white-space: initial
  .icon_items_types
    width: 24px !important
    height: 24px !important
  .unseen
    background-color: #edf4e7
    .v-list-item__content
      color: #6ea33f !important
  .v-list .v-list-item--active
    color: transparent
    .v-list-item__content
      color: rgba(0, 0, 0, 0.87)
  .hasUnseen ::v-deep .v-badge__badge
    visibility: hidden
  .notification-mobile
    position: absolute !important
    margin-right: 60px !important

  ::v-deep
    .v-snack__wrapper.theme--dark
      background-color: #01579B !important
      max-width: 760px
    .v-snack--vertical .v-snack__wrapper .v-snack__action
      align-self: flex-center
  @media (max-width: 372px)
    .mobile-btn
      height: 36px !important
</style>

<i18n>
en:
  no_notifications_yet: No notifications yet
  labels:
    notifications: Notifications
    archive: Archive
  buttons:
    load_more: Load More
    mark_as_read: Mark As Read
    mark_all_as_read: Mark All As Read
    archive: Archive
    unarchive: Unarchive
    view-archived-notifications: View Archived Notifications
    view-unarchived-notifications: View Notifications
  notification_types:
    al_published: A.L Notification
    product_published: Product Notification
    order_modified: Order Notification
    payment_ticket_modified: Payment Ticket Notification
    withdrawal_modified: Withdrawal Notification
</i18n>
