export const assignment = {
  state: {
    showColumn: {cut: [], pot: []}
  },
  mutations: {
    setShowColumn(state, value) {
      state.showColumn = value
    }
  },
  getters: {
    getShowColumn(state) {
      return state.showColumn
    }
  }
}
