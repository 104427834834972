import axios from 'axios'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {TOKEN_KEY} from '@/utils/constants'
import {getInstance} from '../services/auth0plugin'
import {auth} from '@/services/auth'

const api = axios.create({
  baseURL: process.env.VUE_APP_WEB_ROOT_URL
})
api.interceptors.request.use(
  async config => {
    const authService = getInstance()
    const token = await authService.getAccessTokenSilently()
    if (token) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      config.headers.Authorization = `Bearer ${token}`
      localStorage.setItem(TOKEN_KEY, token)
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  response => {
    // If the response is successful, just return the response
    return response
  },
  async error => {
    // Check if the error response status is 401
    if (error.response && error.response.status === 401) {
      auth.logout()
    }

    // If the error is not a 401, reject it
    return Promise.reject(error)
  }
)

export default api
