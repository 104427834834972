import {ref} from 'vue'
import _ from 'lodash'
import {api} from '@/plugins'
import axios from 'axios'
import version from '@/version.json'

export function useStore() {
  const loading = ref(false)
  const haveMore = ref(false)
  const isArchivedList = ref(false)
  let page = 1
  const totalUnseen = ref(0)
  const perPage = 10
  const listNotifications = ref([])
  const backendVer = ref()
  const countUnread = () => {
    // Todo
    // return api.get(`notifications/_unread_count`).then((res) => {
    //   totalUnseen.value = res.data.value
    // })
  }
  const archiveNotification = (notification, archived) => {
    // Check this notification is seen yet
    if (archived) {
      if (!notification.read) {
        // If not unseen, total will be minus 1
        totalUnseen.value -= 1
      }
      // Mark archived = true
      notification.archived = true
      // Add to archived list
    } else {
      if (!notification.read) {
        // If not unseen, total will be minus 1
        totalUnseen.value += 1
      }
      // Mark archived = false
      notification.archived = false
      // Add to archived list
    }
    listNotifications.value = listNotifications.value.filter(item => item !== notification)
    // Call API
    const params = {archived: archived}
    return api.patch(`/notifications/${notification.id}`, params).catch(() => {
      // If API failed revert all value
      if (archived) {
        notification.archived = false
        if (notification.read) {
          // Revert total unseen notification
          totalUnseen.value += 1
        }
      } else {
        notification.archived = true
        if (!notification.read) {
          // Revert total unseen notification
          totalUnseen.value -= 1
        }
      }
      listNotifications.value.push(notification)
    })
  }
  const markAsRead = notification => {
    // Early return
    if (notification.read) return
    // Re calculate total unseen notifications when click this notification
    if (!notification.archived) {
      totalUnseen.value -= 1
    }
    // Mark this notification as seen
    notification.read = true
    // Call API
    const params = {read: true}
    return api.patch(`/notifications/${notification.id}`, params).catch(() => {
      // If API failed revert all value
      totalUnseen.value += 1
      notification.read = false
    })
  }
  const markAllAsRead = () => {
    const cloneNotifications = _.cloneDeep(listNotifications.value)
    const cloneTotalUnseen = totalUnseen.value
    // Mark all unseen notifications as seen
    for (const notification of listNotifications.value) {
      if (!notification.read) {
        notification.read = true
      }
    }
    // Reset total unseen notifications
    totalUnseen.value = 0
    // Call API
    const params = {value: true}
    return api.put(`/notifications/_read`, params).catch(() => {
      // If API failed revert all value
      totalUnseen.value = cloneTotalUnseen
      listNotifications.value = cloneNotifications
    })
  }
  const _getNotifications = () => {
    loading.value = true
    return api
      .get(`/notifications`, {
        params: {
          match_archived: `${isArchivedList.value}`,
          per_page: `${perPage}`,
          page: `${page}`
        }
      })
      .then(res => {
        // Consider that we might have more data if the number of fetched
        // items equal to perPage
        haveMore.value = res.data.length === perPage
        // get notifications list
        listNotifications.value = [...listNotifications.value, ...res.data]
        return res
      })
      .finally(() => (loading.value = false))
  }
  const getNotifications = function (archived) {
    isArchivedList.value = archived
    // Reset state then get notis
    haveMore.value = false
    page = 1
    listNotifications.value = []
    countUnread()
    return _getNotifications()
  }
  const loadMore = function () {
    // Move to next page then get notis
    page += 1
    return _getNotifications()
  }

  const getBackendVersion = function () {
    if (process.env.NODE_ENV === 'development') {
      backendVer.value = version
    } else {
      axios
        .get('/version.json?v=' + Date.now())
        .then(res => {
          if (res.data) backendVer.value = res.data
        })
        .catch(() => {
          backendVer.value = null
        })
    }
  }

  return {
    loadMore,
    haveMore,
    loading,
    getNotifications,
    markAsRead,
    markAllAsRead,
    countUnread,
    totalUnseen,
    archiveNotification,
    isArchivedList,
    listNotifications,
    getBackendVersion,
    backendVer
  }
}
