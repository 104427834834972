<template lang="pug">
v-text-field(:type='type', :value='value' autocomplete='off' hide-details @input='onInput' @keydown.enter='onEnter')
</template>

<script type="js">
  import {defineComponent, onMounted, ref, watch} from 'vue'
  import { pickDate } from '@/utils'
  const JCommonPickDateInput = defineComponent({
    props: {
      value: {
        types: String || null,
        required: true
      },
      type: {
        type: String,
        required: true
      }
    },
    setup(props, {emit}) {
      const memoryInputDate = ref(null)
      const onInput = (e) => {
        const d = pickDate(e)
        if (d !== null){
          memoryInputDate.value = pickDate(e)
        } else {
          memoryInputDate.value = null
        }
      }
      const onEnter = () => {
        if (memoryInputDate.value !== null) {
          emit('on-enter', memoryInputDate.value)
        }
      }
      onMounted(() => {
        memoryInputDate.value = props.value
      })
      return {
        pickDate,
        onInput,
        onEnter
      }
    }
  })

  export default JCommonPickDateInput
</script>
