import type {Ref} from 'vue'
import {ref} from 'vue'
import {parameterize} from '@/utils/string'
import {api} from '@/plugins'
import {Amplify, Auth} from 'aws-amplify'
import router from '@/router'

import type {IAuth, AuthType, ContextUserType, AuthResponseType, LoginType} from './types'
import {initAuth, buildAuth} from './types'

Amplify.configure({
  Auth: {
    region: 'ap-northeast-1',
    userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_APP_CLIENT_ID
    /** we don't use Cognito UI to login */
    // oauth: {
    //   domain: "hik-dev.auth.ap-northeast-1.amazoncognito.com",
    //   scope: ["openid"],
    //   redirectSignIn: "http://localhost:3000/login",
    //   redirectSignOut: "http://localhost:3000/logout",
    //   responseType: "code",
    // },
  }
})

class CognitoAuth implements IAuth {
  me: Ref<AuthType>

  constructor() {
    this.me = ref<AuthType>(initAuth())
  }

  async reload(force = false): Promise<boolean> {
    console.log('[Auth]', 'reload')
    const isAuthenticated = await this.isAuthenticated()
    if (!isAuthenticated) {
      return false
    }

    // // 自己情報がロード済みで、強制リフレッシュでなければ処理なし。
    if (!force && this.me.value.isLoaded) {
      return true
    }

    try {
      const {data: self} = await api.get('/self')
      this.me.value = buildAuth(self)
      return true
    } catch (err: unknown) {
      console.log('[Auth]', 'Failed to resolve self. Logout automatically.')
      return false
    }
  }

  async login(value?: number | ContextUserType | LoginType): Promise<AuthResponseType> {
    console.log('[Auth]', 'Login')
    if (typeof value === 'undefined') {
      throw new Error('Invalid parameter')
    }
    if (typeof value === 'number') {
      throw new Error('Invalid parameter')
    }
    const _value = value as LoginType
    if (_value.pass === undefined) {
      throw new Error('Invalid parameter')
    }
    try {
      await Auth.signIn(_value.code as string, _value.pass as string)
      return {status: true, messages: []}
    } catch (e: unknown) {
      console.log('[Auth]', 'Failed to login.')
      const error = e as Error
      return {
        status: false,
        messages: [`auth_cognito_${parameterize(error.name)}`, `auth_cognito_${parameterize(error.message)}`]
      }
    }
  }
  async getTokenSilently(): Promise<string> {
    const isAuth = await this.isAuthenticated()
    if (!isAuth) {
      return ''
    }
    console.log('[Auth]', 'getTokenSilently')
    const ses = await Auth.currentSession()
    return ses.getAccessToken().getJwtToken()
  }
  async isAuthenticated(): Promise<boolean> {
    try {
      await Auth.currentAuthenticatedUser()
      console.log('[Auth]', `isAuthenticated [Yes]`)
      return true
    } catch {
      console.log('[Auth]', `isAuthenticated [No]`)
      return false
    }
  }

  async logout(): Promise<void> {
    console.log('[Auth]', 'Logout')
    await Auth.signOut()
    this.me.value = initAuth()
    router.push('/login')
  }
}

export const auth = new CognitoAuth()
