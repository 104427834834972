import type {Ref} from 'vue'
import {ref} from 'vue'
import {timerStorage} from '@/services/storage'
import type {IAuth, SelfType, AuthType, ContextUserType, AuthResponseType, LoginType} from './types'
import {initAuth, buildAuth} from './types'
import {api} from '@/plugins'
import router from '@/router'

const UID_SESSION_KEY = 'uid'
const UID_EXPIRE_SECONDS = 3600 * 24 * 6

class Auth implements IAuth {
  me: Ref<AuthType>

  constructor() {
    this.me = ref<AuthType>(initAuth())
  }

  async reload(force = false): Promise<boolean> {
    // console.log("[Auth]", "reload");
    const isAuthenticated = await this.isAuthenticated()
    if (!isAuthenticated) {
      return false
    }

    // 自己情報がロード済みで、強制リフレッシュでなければ処理なし。
    if (!force && this.me.value.isLoaded) {
      return true
    }

    try {
      const {data: self} = await api.get('/self')
      this.me.value = buildAuth(self)
      return true
    } catch (err: unknown) {
      console.log('[Auth]', 'Failed to resolve self. Logout automatically.')
      return false
    }
  }

  async login(value?: number | ContextUserType | LoginType): Promise<AuthResponseType> {
    if (typeof value !== 'number') {
      throw new Error('Invalid parameter')
    }
    const uid = value
    // console.log("[Auth]", "Login");
    timerStorage.setItem(UID_SESSION_KEY, uid, UID_EXPIRE_SECONDS)
    return {status: await this.isAuthenticated(), messages: []}
  }
  async getTokenSilently(): Promise<string> {
    // console.log("[Auth]", "getUidSilently");
    return (async () => {
      return timerStorage.getItem(UID_SESSION_KEY) || ''
    })()
  }
  async isAuthenticated(): Promise<boolean> {
    const authenticated = !!timerStorage.getItem(UID_SESSION_KEY)
    // console.log("[Auth]", `isAuthenticated [${authenticated ? "Yes" : "No"}]`);
    return authenticated
  }

  async logout(): Promise<void> {
    console.log('[Auth]', 'Logout')
    ;(async () => {
      timerStorage.removeItem(UID_SESSION_KEY)
      this.me.value = initAuth()
      router.push('/login')
    })()
  }
}

export const auth = new Auth()
