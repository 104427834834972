// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {i18n} from '@/plugins'
// import moment from 'moment'

// const date = moment(new Date()).format('YYYY-MM-DD')

export const MENU = [
  {
    title: i18n.t('sale.title') as string,
    icon: 'mdi-credit-card-outline',
    path: '/sales-amount-input'
  },
  {
    title: i18n.t('summary.title') as string,
    icon: 'mdi-credit-card-outline',
    path: '/summary'
  },
  {
    title: i18n.t('menu.master.title') as string,
    icon: 'mdi-cogs',
    children: [
      {
        title: i18n.t('processed_product.title') as string,
        path: '/master/products'
      }
    ]
  }
]
