
  import {defineComponent} from 'vue'

  import {getTable} from '@/components/FEditableTable/types'

  import Cell from './Cell.vue'

  export default defineComponent({
    components: {Cell},
    props: {
      columns: {
        type: Array,
        required: true
      },
      row: {
        type: Object,
        required: true
      },
      rowIndex: {
        type: Number,
        required: true
      },
      showIndex: {
        type: Boolean,
        default: false
      },
      possibleStoreArrivalDate: {
        type: Object,
        required: false
      }
    },
    setup(props) {
      const on = setupEvents(props)

      return {
        on
      }
    }
  })

  function setupEvents(props) {
    const $table = getTable()

    function onContextMenu($event: MouseEvent) {
      $event.preventDefault()
      $table.$emit('table.contextmenu', {row: props.row, $event})
    }

    return {
      contextmenu: onContextMenu
    }
  }
