import type {RouteConfig} from 'vue-router'

export const routes: RouteConfig[] = [
  // {
  //   path: "/index",
  //   component: () => import("@/pages/Auth/Pseudo/Index.vue"),
  //   meta: {
  //     requiresAuth: false,
  //     title: "Entry",
  //   }
  // }
  {
    path: '/login',
    component: () => import('@/pages/Auth0/Login.vue'),
    meta: {
      requiresAuth: false,
      title: 'Login'
    }
  },
  {
    path: '/logout',
    component: () => import('@/pages/Auth0/Login.vue'),
    meta: {
      requiresAuth: false,
      title: 'Logout'
    }
  }
]
