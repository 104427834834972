import Vue from 'vue'
import Vuex from 'vuex'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {history} from './history'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {assignment} from './assignment'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {payment} from './payment'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {common} from './common'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loadingIndicator: false
  },
  getters: {},
  mutations: {
    getLoadingIndicator(state) {
      return state.loadingIndicator
    },
    setLoadingIndicator(state, bool) {
      state.loadingIndicator = bool
    }
  },
  actions: {},
  modules: {
    history,
    assignment,
    payment,
    common
  }
})
