import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import {i18n, vuetify, nextFocus} from './plugins'
import Toast from 'vue-toastification'
import PortalVue from 'portal-vue'
import 'vue-toastification/dist/index.css'
import {Auth0Plugin} from './services/auth0plugin'
import {RawLocation} from 'vue-router'

Vue.config.productionTip = false
Vue.use(PortalVue)
Vue.use(Toast, {hideProgressBar: true})
Vue.directive('next-focus', {inserted: nextFocus})
Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  redirectUri: `${process.env.VUE_APP_WEB_ROOT_URL}/account/callback`,
  audience: process.env.VUE_APP_AUTH0_API_AUDIENCE,
  onRedirectCallback: (appState: {targetPath: RawLocation}) => {
    router.push(appState && appState.targetPath ? appState.targetPath : window.location.pathname)
  }
})

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
