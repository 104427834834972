import {DirectiveFunction} from 'vue'
import {DirectiveBinding} from 'vue/types/options'
import {VNode} from 'vue/types/umd'
const isTextAreaComposing = (el: HTMLInputElement) => {
  const v = el.value
  return el.selectionStart !== v.length || v.substr(v.length - 2, 1) !== '\n'
}
const isTextArea = (el: HTMLInputElement) => {
  return el.tagName.toUpperCase() === 'TEXTAREA'
}
const nextFocus: DirectiveFunction = (el: HTMLElement, binding: DirectiveBinding, vnode: VNode) => {
  // Handle stop(stop to next to other input) option
  const option = binding?.arg
  const value = binding?.value

  let group: any
  let isStop = false
  let isDisabled = false
  switch (option) {
    case 'stop':
      group = 'default'
      isStop = true
      break
    default:
      group = option || 'default'
      break
  }
  if (value && value === 'disabled') isDisabled = true
  if (isDisabled) return
  bindInputGroup(el, group)
  el.addEventListener('keyup', (ev: KeyboardEvent) => {
    if (ev.key === 'Enter') {
      // Find inputs only within the currently open dialog
      const dialogs = document.querySelectorAll('.v-dialog.v-dialog--active')
      // Get last dialog
      const dialog = dialogs.length > 0 ? dialogs[dialogs.length - 1] : undefined
      const doc = dialog ? dialog : document
      const inputs = Array.from(
        doc.querySelectorAll(
          `input:not([type="hidden"], [readonly], [disabled], [type="checkbox"])[group="${group}"], textarea:not([type="hidden"], [readonly], [disabled], [type="checkbox"])`
        )
      ) as HTMLElement[]
      const targetElement = ev.target as HTMLElement
      const currentIndex = inputs.findIndex(input => input.id === targetElement.id)
      if (currentIndex < 0) return
      // Check if it's text area
      if (
        isTextArea(inputs[currentIndex] as HTMLInputElement) &&
        isTextAreaComposing(inputs[currentIndex] as HTMLInputElement)
      )
        return
      if (isStop) return
      const nextIndex = currentIndex + 1
      inputs[currentIndex].blur()
      if (nextIndex < inputs.length) {
        inputs[nextIndex].click()
      } else {
        // If it's the last input
        inputs[0].click()
      }
    }
  })
}
function bindInputGroup(el: HTMLElement, group: string) {
  const inputs = Array.from(
    el.querySelectorAll(`input:not([type="hidden"], [readonly], [disabled], [type="checkbox"])`)
  ) as HTMLElement[]
  inputs.forEach(i => i.setAttribute('group', group))
}
export default nextFocus
