import {getData} from '@/utils'
import {cloneDeep} from 'lodash'

const initColumns = (disable = []) => {
  const columns = [
    {
      name: 'cutpot',
      items: [
        {
          name: 'Cut',
          value: 'cut'
        },
        {
          name: 'Pot',
          value: 'pot'
        },
        {
          name: 'Other',
          value: 'other'
        }
      ],
      dropdown: true,
      isActive: true,
      rules: 'required|oneOf:cut,pot,other'
    },
    {
      name: 'icon',
      items: [
        {
          name: 'New',
          value: 'new'
        },
        {
          name: 'Special offer',
          value: 'special_offer'
        },
        {
          name: 'Recommended',
          value: 'recommended'
        },
        {
          name: 'Rare',
          value: 'rare'
        }
      ],
      dropdown: true,
      isActive: true,
      rules: 'oneOf:new,special_offer,recommended,rare'
    },
    {
      name: 'origin',
      isActive: true
    },
    {
      name: 'family',
      header: true,
      items: [
        {
          code: '001',
          name: 'product 1',
          value: 'product 1'
        },
        {
          code: '002',
          name: 'product 2',
          value: 'product 2'
        },
        {
          code: '003',
          name: 'product 3',
          value: 'product 3'
        },
        {
          code: '004',
          name: 'product 4',
          value: 'product 4'
        }
      ],
      dropdown: true,
      isActive: true,
      required: true,
      rules: 'required'
    },
    {
      name: 'variety',
      isActive: true,
      required: true,
      rules: 'required'
    },
    {
      name: 'color',
      isActive: true
    },
    {
      name: 'pot_size',
      isActive: true
    },
    {
      name: 'fee',
      isActive: true
    },
    {
      name: 'grade',
      isActive: true,
      help: 'The flower quality (Selected, Standard, etc...), or consistent length of stem, size of bloom.'
    },
    {
      name: 'quantity',
      isActive: true,
      required: true,
      rules: 'required',
      type: 'number',
      help: 'Number of units in each box'
    },
    {
      name: 'unit',
      isActive: true,
      help: 'E.g: stem, branch, etc.',
      rules: 'required',
      required: true
    },
    {
      name: 'pieces_per_unit',
      isActive: true,
      help: 'Number of pieces per each unit. E.g. stems per bunch',
      type: 'number',
      rules: 'min_value:1'
    },
    {
      name: 'box_weight',
      isActive: true,
      type: 'number'
    },
    {
      name: 'price',
      isActive: true,
      required: true,
      rules: 'required|greater_than_zero',
      help: 'Price per unit'
    },
    {
      name: 'note',
      isActive: true,
      help: 'Additional information for an item'
    },
    {
      name: 'private_note',
      isActive: true,
      help: 'Note for internal use, not visible to merchants. The note will also show up in orders.'
    },
    {
      name: 'sold_out',
      isActive: true,
      rules: 'isBool'
    },
    {
      name: 'images',
      isActive: true
    }
  ]

  columns.forEach(col => {
    col.isActive = disable.indexOf(col.name) === -1
  })

  return columns
}

const initRow = (columns, data = null, page = undefined) => {
  // columns: string[]
  let row = {}
  columns.forEach(name => {
    row[name] = null
  })

  if (data) {
    Object.keys(data).forEach(key => {
      row[key] = data[key]
    })
  }
  if (page === 'arrival') row = {...row, redBucket: []}
  return [row]
}

const checkLastRow = (rows, indexCurrentRowInput) => {
  return rows.length - 1 === indexCurrentRowInput
}

const autoAddEmptyRow = (rows, indexCurrentRowInput) => {
  if (checkLastRow(rows, indexCurrentRowInput)) return addOneRow(rows)
}

const addOneRow = (rows, index = null, data = null) => {
  /*
    {
        row: $table.rows[cursor.rowIndex],
        rowIndex: cursor.rowIndex,
        column: $table.columns[cursor.columnIndex],
        columnIndex: cursor.columnIndex,
        rows,
        rowIndexes,
        columns,
        columnIndexes,
    }
    */
  // exp: { color: 'red', box_type: 'L' }
  const row = rows[0]
  const column = Object.keys(row)
  let newRow = initRow(column, cloneDeep(data))[0]

  const indexInsert = index ? index : rows.length
  if (index === 0) {
    rows.splice(index, 0, newRow)
  } else {
    rows.splice(indexInsert, 0, newRow)
  }
  return rows
}

const addRows = (rows, numberRowAdd = 1, index = null, isAbove = false, data = null) => {
  const indexInsert = index !== null ? (isAbove ? index : index + 1) : rows.length - 1
  for (let i = 0; i < numberRowAdd; i++) {
    rows = addOneRow(rows, indexInsert, data)
  }
  return rows
}

const getSelectItems = async (columns, dataInput, dataMaster) => {
  const curColumn = dataInput.column.name
  if (curColumn === 'material_variety_name') {
    const curItemRow = dataMaster.material_family_name.find(item => item.name === dataInput.row.material_family_name)
    if (dataInput.row.material_family_name !== null && curItemRow) {
      const data = await getData(['material_varieties'], {
        material_variety: {material_family_id: curItemRow.id}
      })
      addDataDropDown(columns, {
        material_variety_name: data.material_varieties
      })
    } else if (!curItemRow) {
      addDataDropDown(columns, {material_variety_name: []})
    }
  }
  return true
}

const deleteRows = (rows, numberRowDelete, index) => {
  if (rows.length === numberRowDelete + index) {
    rows = addOneRow(rows, numberRowDelete + index + 1)
  }
  const deletedRows = rows.slice(index, numberRowDelete + index)
  rows.splice(index, numberRowDelete)
  return [rows, deletedRows]
}

const copyRows = (rows, newRows, index, columns = [], notCopyProps = [], includeDisabled = false) => {
  let indexInsert = index + 1
  let skipCopyProps = columns.filter(col => col.skipCopy).map(col => col.name)
  skipCopyProps = [...notCopyProps, ...skipCopyProps, 'history']

  const disabledCols = columns.filter(col => col.disabled).map(col => col.name)

  // not copy these props and disabled columns
  if (!includeDisabled) {
    skipCopyProps = [...skipCopyProps, ...disabledCols]
  }
  newRows.forEach(newRow => {
    newRow = JSON.parse(JSON.stringify(newRow))
    // let skipCopyProps = [
    //   'history',
    //   'reg_no',
    //   'mixinfo',
    //   'mix_details',
    //   'sales',
    //   'purchase_amount',
    //   'purchase_unit_price',
    //   'sold_box_num',
    //   'readonly',
    //   'disabledRow',
    //   'desired_unit_price_from',
    //   'desired_unit_price_to',
    //   'is_web_request',
    //   'classes'
    // ]

    skipCopyProps.forEach(prop => {
      if (prop in newRow) {
        newRow[prop] = null
      }
    })
    if (newRow.id) newRow.id = null
    newRow.is_tracking_no_check = false
    rows.splice(indexInsert, 0, newRow)
    indexInsert++
  })
  return rows
}

const getDropDownItems = async (dataInput, dataMaster, masterField, params = {}) => {
  const searchInput = dataInput.searchInput
  const curColumn = dataInput.column.name
  let dropdownItems = []
  if (curColumn === 'variety') {
    const currentItem = dataMaster.items.find(
      item =>
        item.code ===
        (dataInput.row.item && dataInput.row.item.includes(':') ? dataInput.row.item.split(':')[0] : dataInput.row.item)
    )
    if (dataInput.row.item !== null && currentItem) {
      const data = await getData([masterField], {
        variety: {
          item_id: currentItem?.id,
          searchstr: searchInput ? searchInput : '',
          active: true,
          ...params
        }
      })
      dropdownItems = data.varieties
    }
  }
  if (curColumn === 'shipper') {
    const data = await getData(['shipper'], {
      shipper: {
        searchstr: searchInput ? searchInput : '',
        active: true,
        page: 1,
        per_page: 100
      }
    })
    dropdownItems = data.shippers
  }
  return dropdownItems
}

const getDropdownData = (columns, dropDownData) => {
  return columns.map(column => {
    if (column.dropdown && dropDownData[column.name]) {
      column.items = dropDownData[column.name]
    }
    return {...column}
  })
}

const isNotEmptyRow = row => {
  const cloneRow = JSON.parse(JSON.stringify(row))
  delete cloneRow.id
  delete cloneRow.is_direct
  delete cloneRow.is_delete
  delete cloneRow.is_communication_fees
  delete cloneRow.redBucket
  delete cloneRow.readonly
  delete cloneRow.disabledBucket
  return Object.keys(cloneRow).some(key => {
    if (key === '$isValidate') return false
    return cloneRow[key] !== null && cloneRow[key] !== ''
  })
}

const validateRow = (row, column, value, allowValueZero = false) => {
  row = JSON.parse(JSON.stringify(row))
  // $isValidate is used to show a validation error when $isValidate['field'] = true.
  if (!row.$isValidate) row.$isValidate = {}
  if (column.required) {
    if (column.type === 'number') {
      // Convert empty string to null
      value = typeof value === 'string' ? (value ? parseFloat(value.normalize('NFKD')) : null) : value
      if (value === null) {
        row.$isValidate[column.name] = true
        return [false, row]
      }
      // If is not allow zero, value must be !== 0
      if (!allowValueZero && !value) {
        row.$isValidate[column.name] = true
        return [false, row]
      }
    }
    if (allowValueZero && (value === '' || value === null)) {
      if (!row.$isValidate) row.$isValidate = {}
      row.$isValidate[column.name] = true
      return [false, row]
    }
    if (!allowValueZero && !value) {
      if (!row.$isValidate) row.$isValidate = {}
      row.$isValidate[column.name] = true
      return [false, row]
    }
  }
  // Edit FTable
  if (row.$isValidate[column.name] === true) return [false, row]
  return [true, row]
}

const validateRows = (rows, columns, allowValueZero = false) => {
  let success = true
  rows = rows.map(row => {
    if (!isNotEmptyRow(row)) return row
    columns.forEach(column => {
      const [successRow, rowResult] = validateRow(
        row,
        column,
        row[column.name],
        allowValueZero || column?.allowValueZero
      )
      success = success && successRow
      row = rowResult
    })
    return row
  })
  return [success, JSON.parse(JSON.stringify(rows))]
}

const insertIntoRedBucket = (rows, rowIdx, colName) => {
  if (!rows[rowIdx].redBucket) rows[rowIdx].redBucket = []
  const idx = rows[rowIdx].redBucket.indexOf(colName)
  if (idx === -1) rows[rowIdx].redBucket.push(colName)
}

const removeFromRedBucket = (rows, rowIdx, colName) => {
  if (!rows[rowIdx].redBucket) rows[rowIdx].redBucket = []
  const idx = rows[rowIdx].redBucket.indexOf(colName)
  if (idx !== -1) rows[rowIdx].redBucket.splice(idx, 1)
}

const addDataDropDown = (columns, dataDetail) => {
  columns.map(column => {
    if (column.dropdown && dataDetail[column.name]) {
      column.items = dataDetail[column.name]
    }
    return column
  })
}

export {
  initRow,
  addOneRow,
  addRows,
  deleteRows,
  copyRows,
  autoAddEmptyRow,
  initColumns,
  getDropdownData,
  getDropDownItems,
  isNotEmptyRow,
  validateRow,
  validateRows,
  insertIntoRedBucket,
  removeFromRedBucket,
  getSelectItems
}
