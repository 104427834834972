import {i18n} from '@/plugins'
import {auth} from '@/services/auth'
import {basicStorage} from '@/services/storage'
import router from '@/router'

import {Route} from 'vue-router'

import {getInstance} from '../../services/auth0plugin'

export const authGuard = (to: Route, from: Route, next: any) => {
  document.title = to.name ? `Bouquet Management System ｜ ${i18n.t(to.name)}` : 'Bouquet Management System'
  const authService = getInstance()

  const guardAction = () => {
    if (to.meta?.requiresAuth === false) {
      return next()
    }

    if (authService.isAuthenticated) {
      return next()
    }
    return next({
      path: '/login',
      query: {referer: to.path}
    })
  }

  // If the Auth0Plugin has loaded already, check the authentication state
  if (!authService.isLoading) {
    return guardAction()
  }

  authService.$watch('isLoading', (isLoading: boolean) => {
    if (isLoading === false) {
      return guardAction()
    }
  })
}
