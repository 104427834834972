import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {navGuard, authGuard} from '@/utils/Gaurd'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {urlPath} from '@/utils'
import store from '../store'

// auth
import {routes as authRoutes} from '@/router/auth'

// master
import {routes as masterRoutes} from './master'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  ...authRoutes,
  {
    ...urlPath.HOME,
    component: () => import(/* webpackChunkName: "home" */ '../pages/Home/index.vue')
  },
  ...masterRoutes,
  {
    ...urlPath.LOGIN_CALLBACK,
    component: () => import('../pages/Auth0/Callback.vue')
  },
  {
    ...urlPath.CALCULATE_SUMMARY,
    component: () => import(/* webpackChunkName: "home" */ '../pages/CalculateSummary/index.vue')
  },
  {
    ...urlPath.SALE,
    component: () => import(/* webpackChunkName: "home" */ '@/pages/SalesAmountInput/index.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.afterEach((to, from) => {
  store.commit('setLoadingIndicator', false)
})
router.beforeEach(navGuard)
router.beforeEach(authGuard)

export default router
