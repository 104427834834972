<template lang="pug">
common-table.mb-10(
  :headers='headers',
  :item-key='itemKey',
  :items='items',
  :loading='loading',
  :max-width='maxWidth'
  @on-click='onClickNextIcon')
  template(v-slot:name='{item}')
    div(v-if='isShowView')
      v-icon.mr-2(medium) mdi-folder-open-outline
      span.link-text {{item.name}}
    div(v-else) {{item.name}}
  template(v-slot:name_display='{item}')
    div(v-if='isShowView')
      v-icon.mr-2(medium) mdi-folder-open-outline
      span.link-text {{item.name_display}}
    div(v-else) {{item.name_display}}
  template(v-slot:item.product_name='{item}')
    span.link-product(@click='$emit("editProduct", item)') {{item.product_name}}
  template(v-slot:action='{item}')
    v-menu(v-if='isActive && !loading' left min-width='9vw' offset-y)
      template(v-slot:activator='{on}')
        v-btn(v-on='on' icon)
          v-icon(medium) mdi-dots-vertical
      v-list
        v-list-item(v-for='(action, i) in actions', :key='i' link @click='emitAction(action.action, item)')
          v-icon.pr-3(:color='action.color') {{action.icon}}
          v-list-item-title {{$t(action.text)}}
    v-btn.mr-2.hidden-xs-only.button(v-else-if='!loading' color='black' dark outlined @click.stop='onRevertItem(item)')
      span {{$t('common.btn_revert')}}

  template(v-for='(_, name) in $scopedSlots' #[name]='slotProps')
    slot(v-bind='slotProps', :name='name')
</template>
<script>
  import {defineComponent} from 'vue'
  import CommonTable from '../CommonTable/index'

  const JMasterItemList = defineComponent({
    props: {
      maxWidth: {
        type: Number,
        default: 1200,
        required: false
      },
      isShowDestination: {
        type: Boolean,
        required: false,
        default: false
      },
      items: {
        type: Array,
        required: true
      },
      headers: {
        type: Array,
        required: true
      },
      isShowView: {
        type: Boolean,
        required: false,
        default: false
      },
      actions: {
        type: Array,
        required: false,
        default: () => []
      },
      loading: {
        type: Boolean,
        default: false,
        required: false
      },
      isActive: {
        type: Boolean,
        default: true,
        required: false
      },
      itemKey: {
        type: String,
        required: false
      }
    },
    components: {
      CommonTable
    },
    setup(props, {emit}) {
      // Click to open unit of each group
      const onClickNextIcon = item => {
        emit('go-to-master-detail', item)
      }

      const onRevertItem = item => {
        emit('handle-revert-item', item)
      }

      // emit all action (on-update, on-delete)
      const emitAction = (action, item) => {
        emit(action, item)
      }

      return {
        onClickNextIcon,
        emitAction,
        onRevertItem
      }
    }
  })

  export default JMasterItemList
</script>

<style scoped lang="sass">
  @import '@/style/css/common.sass'
  .home
    width: 100%

  .btn
    border: 0px

  .link-text
    color: blue
    text-decoration: underline
  .link-product
    text-decoration: underline
    cursor: pointer

  ::v-deep table > thead > tr > th > span
    color: white !important
    font-size: 16px

  ::v-deep table > tbody > tr > td
    font-size: 16px !important
</style>
