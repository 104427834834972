<template lang="pug">
div
  button.nav-hide(v-if='!value && !isMobile', :value='!value' @click='$emit("toggle")')
    v-btn.btn-icon(icon)
      v-icon(color='white' x-large) mdi-pan-right
  v-navigation-drawer.test(:value='value' app fixed left temporary @input='drawer => $emit("input", drawer)')
    profile-menu(v-if='isMobile', :is-mobile='isMobile')
    v-list-item.pa-0(v-if='!isMobile')
      v-spacer
      v-icon.primary--text(@click='$emit("toggle")') mdi-backburger
    v-list(v-for='menu in menus', :key='menu.title' expand)
      v-list-item(v-if='!menu.children', :to='{path: menu.path}')
        v-icon.primary--text {{menu.icon}}
        v-list-item-title.pl-8 {{menu.title}}
      v-list-group(v-else, :prepend-icon='menu.icon' value='group')
        template(v-slot:activator)
          v-list-item-content
            v-list-item-title {{menu.title}}
        .menu(v-for='child in menu.children')
          v-list-item.item(
            v-if='!child.children2',
            :class='{"v-list-item--active": checkExact(child.path, child?.query), disableClick: checkActive(child.path, child?.query)}',
            :exact='child?.query ? true : false',
            :key='child.title',
            :to='{path: child.path, query: child?.query}'
            link)
            v-list-item-content
              v-list-item-title {{child.title}}
          v-list-group(
            v-else,
            :key='child.title',
            :prepend-icon='child.children2 ? "mdi-menu-down" : ""',
            :value='true'
            no-action
            sub-group)
            template(v-slot:activator)
              v-list-item-content
                v-list-item-title(:class='child.children2 ? "" : "no-icon"') {{child.title}}
            v-list-item(
              v-for='child2 in child.children2',
              :class='{"v-list-item--active": checkExact(child2.path, child2?.query), disableClick: checkActive(child2.path, child2?.query)}',
              :exact='child2?.query ? true : false',
              :key='child2.title',
              :to='{path: child2.path, query: child2?.query}'
              link)
              v-list-item-title {{child2.title}}
</template>

<script>
  import {defineComponent, ref} from 'vue'
  import {MENU} from './menu'
  import router from '@/router'
  import ProfileMenu from '@/components/JHeaderMenu/ProfileMenu.vue'

  const JNavigationMenu = defineComponent({
    props: {
      value: {
        type: Boolean,
        required: true
      },
      isMobile: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    components: {ProfileMenu},
    setup() {
      const group = ref(null)
      const menus = MENU

      const onClickMenu = menu => {
        if (menu.name) {
          router.push({name: menu.name})
        }
      }

      const checkExact = (path, query) => {
        const location = window.location
        if (query && path == location.pathname) {
          const params = new URLSearchParams(location.search)
          for (const [key, value] of params) {
            if (key in query && query[key].toString() == value.toString()) return true
          }
        }
        return false
      }

      const checkActive = (path, query) => {
        if (path == window.location.pathname) {
          if (query) {
            const params = new URLSearchParams(window.location.search)
            for (const [key, value] of params) {
              if (key in query && query[key].toString() == value.toString()) return true
            }
          } else return true
        }
        return false
      }

      return {
        group,
        menus,
        onClickMenu,
        checkExact,
        checkActive
      }
    }
  })
  export default JNavigationMenu
</script>

<style scoped lang="sass">
  @import '@/style/css/common.sass'
  .nav-hide
    z-index: 1000
    position: fixed
    text-align: left
    width: 18px
    background-color: rgba(111, 152, 111, 0.2) !important

    .v-icon
      font-size: x-large
      padding-right: 10px

    .btn-icon
      opacity: 0.5 !important
      background-color: rgba(111, 152, 111, 0.7)
      position: absolute
      left: 2px
      top: 15px
      box-shadow: 0 0 2px 2px rgba(218, 216, 216, 0.49)

  .nav-show
    position: absolute
    text-align: left
    height: 100vh
    width: 250px
    background-color: white

  .title
    font-weight: bold
    color: var(--v-primary-base)
    font-size: 18px

  .child
    .link
      color: $rough-black
      text-underline-position: under

    .text
      font-size: 18px

  ::v-deep .v-list
    .item
      padding-left: 48px
    .v-list-group__header
      .v-list-item__icon:first-child
        margin-right: 8px
        .v-list-group__header__append-icon
          min-width: 24px
    .v-list-group--sub-group > .v-list-group__items > .v-list-item
       padding-left: 72px

  ::v-deep .v-list .v-list-item--active
    color: var(--v-primary-base)

  ::v-deep .v-list-item .v-list-item__title
    color: $rough-black

  .no-icon
    padding-left: 40px
  .test
    background-color: white !important
    width: 300px !important
  @media (max-width: 820px)
    .nav-hide
      width: 0

  .disableClick
    pointer-events: none
</style>
