type WrapType = {
  expires: number
  value: any
}

class TimerStorage {
  setItem<T = any>(key: string, obj: T, ttl = 7200) {
    try {
      const now = new Date().getTime()
      const expires = now + ttl * 1000
      const wrap: WrapType = {
        expires: expires,
        value: obj
      }
      localStorage.setItem(key, JSON.stringify(wrap))
    } catch (e) {
      this.removeItem(key)
    }
  }
  getItem<T = any>(key: string): T | null {
    try {
      const value = localStorage.getItem(key)
      if (value === null) {
        return null
      }
      const wrap: WrapType = JSON.parse(value)
      const now = new Date().getTime()
      if (wrap.expires < now) {
        this.removeItem(key)
        return null
      }
      return wrap.value
    } catch (e) {
      this.removeItem(key)
      return null
    }
  }
  removeItem(key: string) {
    try {
      localStorage.removeItem(key)
    } catch (e) {
      //
    }
  }
}
export const timerStorage = new TimerStorage()
