export * from './ProductType'
export * from './Invoice'
export * from './Screen'
export * from './Column'
export * from './Common'
export * from './constant'
export * from './Job'

export const TOKEN_KEY = 'token_key'

export const TaxType = Object.freeze({
  NO_TAX: 'no_tax',
  INCLUDED: 'included',
  EXCLUDED: 'excluded'
})

export const TaxTypeId = Object.freeze({
  TAX_EXCLUDED: '1',
  TAX_INCLUDED: '2',
  TAX_FREE: '3'
})

export const TaxFractionId = Object.freeze({
  ROUND_CEIL: '2',
  ROUND_FLOOR: '3'
})
