
  import {
    defineComponent,
    getCurrentInstance,
    onBeforeUnmount,
    onMounted,
    PropType,
    provide,
    reactive,
    Ref,
    ref,
    toRef,
    toRefs,
    watch,
    nextTick
  } from 'vue'
  import {throttle} from 'lodash'
  import escape from 'lodash/escape'
  import CellCursor from './CellCursor.vue'
  import CellInput from './CellInput.vue'
  import CellSelectionRegion from './CellSelectionRegion.vue'
  import ColumnResizer from './ColumnResizer.vue'
  import ContextMenu from './ContextMenu.vue'
  import Row from './Row.vue'
  import RowSelectionRegion from './RowSelectionRegion.vue'
  import {
    Column,
    Columns,
    ColumnSizes,
    CursorSymbol,
    SelectedCellRegion,
    SelectedIndexes,
    TableInstance,
    TableSymbol,
    CellMouseEvent
  } from './types'

  export default defineComponent({
    components: {
      ContextMenu,
      Row,
      CellInput,
      CellCursor,
      RowSelectionRegion,
      CellSelectionRegion,
      ColumnResizer
    },
    provide() {
      return {
        $table: this
      }
    },
    props: {
      columns: {
        type: Array as PropType<Columns>,
        required: true
      },
      // For multi-level headers, subColumns: above headers
      subColumns: {
        type: Array as PropType<Columns>,
        required: true
      },
      rows: {
        type: Array,
        required: true
      },
      possibleStoreArrivalDate: {
        type: Object,
        required: false
      },
      contextActions: {
        type: Array,
        required: false,
        default: /* istanbul ignore next */ () => []
      },
      selectedIndexes: {
        type: Array as PropType<SelectedIndexes>,
        required: false,
        default: () => []
      },
      selectedCellRegion: {
        type: Object as PropType<SelectedCellRegion>,
        required: false,
        default: () => null
      },
      columnSizes: {
        type: Object as PropType<ColumnSizes>,
        default: null
      },
      domId: {
        type: String,
        default: null
      },
      showFooter: {
        type: Boolean,
        required: false,
        default: false
      },
      showIndex: {
        type: Boolean,
        required: false,
        default: false
      },
      isShowContext: {
        type: Boolean,
        required: false,
        default: true
      },
      isAllowSorting: {
        type: Boolean,
        default: true
      },
      showSummary: {
        type: Boolean,
        default: false
      },
      isFixedHeader: {
        type: Boolean,
        default: false
      },
      isMoveRightOnEnter: {
        type: Boolean,
        default: true
      },
      isFixedHeaderSaleScreen: {
        type: Boolean,
        default: false
      },
      isCustomCursor: {
        type: Boolean,
        default: true
      },
      disabled: {
        type: Boolean,
        default: false
      },
      autoFocusFirstCell: {
        type: Boolean,
        default: false
      },
      countEntireTotal: {
        type: Function,
        required: false
      }
    },
    setup(props, {emit}) {
      const currentVm: any = getCurrentInstance()?.proxy
      const {columns: refColumns} = toRefs(props)
      const {subColumns: subRefColumns} = toRefs(props)
      provide(TableSymbol, currentVm)
      const defaultCur = {
        rowIndex: 1,
        columnIndex: 0,
        editing: false,
        top: 0,
        left: 0,
        width: 0,
        height: 0
      }

      const currentRegions = ref([])
      const cursor = reactive({...defaultCur})
      const handleInput = e => {
        emit('input', e)
      }
      cursor.rowIndex = -1
      /*Set init cursor position*/
      // setTimeout(() => {
      //   cursor.rowIndex = props.rows.length - 1
      //   cursor.columnIndex = refColumns.value.findIndex((column: any) => column.isActive)
      // }, 100)

      provide(CursorSymbol, cursor)

      const getDisplayField = (index: number) => {
        return refColumns.value[index]?.displayField
      }
      const localSelectedIndexes = setupSelectedIndexes(props)
      const localSelectedCellRegion = setupSelectedCellRegion(props)
      const onPaste = setupReceivingDataOnPaste(currentVm as any as TableInstance)
      const onCopy = setupBuildCsvOnCopy(currentVm as any as TableInstance)
      const bodyEvents = {
        click(event: any) {
          currentVm.$emit('bodyclick', cursor)
        },
        mousedown(event: any) {
          currentVm.$emit('bodymousedown', event)
        }
      }
      const onOutsideClick = function (event: any) {
        // Fix for vuetify 2.3.13+
        // Selecting an item from v-select inside the table triggers
        // v-outside-click, so we need to ignore them.
        // Otherwise, the input will revert to non-editing mode after pressing enter
        // to select an item from v-select
        if (event.target.matches('.v-list-item--active')) {
          return
        }
        currentVm.$emit('outsideclick', event)
      }

      const {
        activateResizer,
        columnSizes: localColumnSizes,
        resizerHeight
      } = setupColumnResizer(currentVm as any as TableInstance, toRef(props, 'columnSizes'))

      watch(
        () => refColumns.value,
        () => {
          const memoryIndex = cursor.columnIndex
          let newIndex = -1
          if (refColumns.value[memoryIndex]?.isActive === true) {
            newIndex = memoryIndex
          } else if (refColumns.value[memoryIndex]?.isActive === false) {
            for (let i = memoryIndex + 1; i <= refColumns.value.length; i += 1) {
              if (refColumns.value[i]?.isActive === true) {
                newIndex = i
                break
              }
            }
          }
          cursor.columnIndex = newIndex
        },
        {
          deep: true
        }
      )
      watch(cursor, () => {
        currentVm.$emit('cursor', cursor)
      })
      const focusOnFirstCell = async () => {
        await nextTick()
        let firstCell = document
          .getElementById(props.domId)
          ?.querySelector('td[data-column-index="0"][data-row-index="0"]') as HTMLTableElement

        if (!firstCell) return
        // Focus to the first enabled cell
        if (firstCell.className.includes('cell--disabled')) {
          firstCell = document
            .getElementById(props.domId)
            ?.querySelector('td[data-row-index="0"]:not(.cell--disabled)') as HTMLTableElement
        }
        // Force click on first cell element
        cursor.rowIndex = 0
        cursor.columnIndex = 0
        firstCell?.click()
        const clickEvent = new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        })
        const colPosition = Number(firstCell.getAttribute('data-column-index'))
        currentVm.$emit('cellclick', {
          rowIndex: 0,
          columnIndex: colPosition,
          $event: clickEvent
        } as CellMouseEvent)
      }
      watch(
        () => props.disabled,
        async () => {
          if (!props.disabled && props.autoFocusFirstCell) {
            focusOnFirstCell()
          }
        }
      )
      return {
        cursor,
        localSelectedIndexes,
        localSelectedCellRegion,
        onPaste,
        onCopy,
        bodyEvents,
        onOutsideClick,
        getDisplayField,
        activateResizer,
        localColumnSizes,
        resizerHeight,
        refColumns,
        currentRegions,
        handleInput,
        subRefColumns
      }
    }
  })

  function setupSelectedIndexes(props: {selectedIndexes: SelectedIndexes}) {
    const localSelectedIndexesRef: Ref<SelectedIndexes> = ref([])
    const vm: any = getCurrentInstance().proxy

    watch(
      () => props.selectedIndexes,
      value => {
        localSelectedIndexesRef.value = value
      }
    )

    watch(localSelectedIndexesRef, value => {
      if (props.selectedIndexes !== value) {
        vm.$emit('update:selected-indexes', value)
      }
    })

    return localSelectedIndexesRef
  }

  function setupSelectedCellRegion(props: {selectedCellRegion: SelectedCellRegion}) {
    const selectedCellRegionRef: Ref<SelectedCellRegion> = ref(null)
    const vm: any = getCurrentInstance()?.proxy

    watch(
      () => props.selectedCellRegion,
      value => {
        selectedCellRegionRef.value = value
      }
    )

    watch(selectedCellRegionRef, value => {
      if (props.selectedCellRegion !== value) {
        vm.$emit('update:selected-cell-region', value)
      }
    })

    return selectedCellRegionRef
  }

  function setupReceivingDataOnPaste($table: TableInstance) {
    // const $table = getTable()

    async function onPaste(event: ClipboardEvent, cursor) {
      event.preventDefault()

      const pastedItems = getCsvFromClipboardData(
        event.clipboardData || (window as unknown as {clipboardData: DataTransfer}).clipboardData
      )
      if (!pastedItems.length) return

      // Get the four corners of selected cell region
      const cellRegion = $table.localSelectedCellRegion
      let topIndex = Math.min(cellRegion.start.rowIndex, cellRegion.end.rowIndex)
      const bottomIndex = Math.max(cellRegion.start.rowIndex, cellRegion.end.rowIndex)
      const leftIndex = Math.min(cellRegion.start.columnIndex, cellRegion.end.columnIndex)
      // Only in case we also want to repeat columns
      // const rightIndex = max([cellRegion.start.columnIndex, cellRegion.end.columnIndex])

      // Start firing input events
      let rowsRepeated = false
      for (let i = 0; i < pastedItems.length; i++) {
        for (let j = 0; j < pastedItems[i].length; j++) {
          // Fix bug ignore readonly/disabled/disabledRow/isSpan/isButton
          const pasteRow = $table.rows[topIndex + i]
          const pasteCol = $table.columns[leftIndex + j]
          let pasteContent = pastedItems[i][j].toString()
          if (pasteCol?.type === 'number') {
            pasteContent = pasteContent.toString().trim().normalize('NFKC')
          }
          const readOnly = (pasteRow.readonly || []).includes(pasteCol.name)
          const ignorePaste =
            (pasteCol.isSpan && pasteCol.type !== 'checkbox') ||
            pasteCol.isButton ||
            pasteCol.disabled ||
            pasteRow.disabledRow ||
            readOnly

          if (!ignorePaste)
            $table.$emit('input', {
              row: $table.rows[topIndex + i],
              column: $table.columns[leftIndex + j],
              value: pasteContent === 'false' ? false : pasteContent,
              onPaste: true
            })
          // If there should be a new row, we wait one tick for FEditableTable to
          // receive new rows prop
          if (topIndex + i >= $table.rows.length) {
            await new Promise<void>(resolve => $table.$nextTick(resolve))
          }
        }

        // Repeat rows if we haven't reach the bottomIndex yet
        if (i + 1 === pastedItems.length && topIndex + i < bottomIndex) {
          i = -1
          topIndex = topIndex + pastedItems.length
          rowsRepeated = true
        }

        // Stop repeating rows if we have reached the bottomIndex
        if (rowsRepeated && topIndex + i >= bottomIndex) {
          break
        }
      }
    }

    return onPaste
  }

  function setupBuildCsvOnCopy($table: TableInstance) {
    // const $table = getTable()

    return function onCopy(event: any) {
      // Fix bug: Ctrl C on header, red console
      if ($table.cursor.rowIndex === -1) return
      event.preventDefault()

      // Get the four corners of selected cell region
      const cellRegion = $table.localSelectedCellRegion
      const topIndex = Math.min(cellRegion.start.rowIndex, cellRegion.end.rowIndex)
      const bottomIndex = Math.max(cellRegion.start.rowIndex, cellRegion.end.rowIndex)
      const leftIndex = Math.min(cellRegion.start.columnIndex, cellRegion.end.columnIndex)
      const rightIndex = Math.max(cellRegion.start.columnIndex, cellRegion.end.columnIndex)

      // Get the data to copy into clipboard
      const rows = []
      for (let i = topIndex; i <= bottomIndex; i++) {
        const row = []
        for (let j = leftIndex; j <= rightIndex; j++) {
          const field = $table.getDisplayField(j)
          const value = field ? $table.rows[i][$table.columns[j].name][field] : $table.rows[i][$table.columns[j].name]

          // Cannot copy object/array value
          const finalValue = typeof value === 'object' ? '' : value
          row.push(finalValue ?? '')
        }
        rows.push(row)
      }

      // Build HTML version
      let html = '<meta http-equiv="content-type" content="text/html; charset=utf-8">'
      html += '<table><tbody>'
      rows.forEach(row => {
        html += '<tr>'
        row.forEach(cell => {
          html += '<td>'
          html += escape(`${cell}`)
          html += '</td>'
        })
        html += '</tr>'
      })
      html += '</tbody></table>'
      event.clipboardData.setData('text/html', html)

      // Build text version
      const text = rows.map(row => row.join('\t')).join('\n')
      event.clipboardData.setData('text/plain', text)
    }
  }

  function setupColumnResizer($table: TableInstance, columnSizesProp: Ref<ColumnSizes>) {
    // const $table = getTable()

    // const rightColumnRef = ref<Column>(null)
    const leftColumnRef = ref<Column>(null)
    const mouseDownPageX = ref(0)
    const columnSizes = ref<ColumnSizes>({})
    const backupColumnSizes = ref<ColumnSizes>({})
    const tableWidth = ref(0)
    const MIN_COLUMN_SIZE_PX = 10
    let sizeUpdated = false

    // Set-up two-way binding for column-sizes prop
    // Parent-child here, Child-parent in deactivateResizer()
    watch(
      () => columnSizesProp.value,
      newValue => (columnSizes.value = newValue || {})
    )

    const resizeColumn = throttle((event: MouseEvent) => {
      // Get the distance between when user pressed on the resizer, and when user drag the resizer around,
      // in relative with the table width
      const diff = mouseDownPageX.value - event.pageX

      // If the distance is positive (which means user drags the resizer left)
      // We need to reduce the size of the column on the left of resizer
      const newLeftColumnSize = parseFloat(backupColumnSizes.value[leftColumnRef.value.name].replace('px', '')) - diff
      // Safe-guard the column to not be smaller than 10px
      if (newLeftColumnSize < MIN_COLUMN_SIZE_PX) return

      // update: only change the width of left column, do not change the width of right column
      // And also increase the size of the column on the right of resizer
      // const newRightColumnSize = parseFloat(backupColumnSizes.value[rightColumnRef.value.name]) + diffPercentage
      // // Safe-guard the column to not be smaller than 10px
      // if ((newRightColumnSize * tableWidth.value) / 100 < MIN_COLUMN_SIZE_PX) return

      // Apply calculated sizes to the column
      columnSizes.value[leftColumnRef.value.name] = `${newLeftColumnSize}px`
      // columnSizes.value[rightColumnRef.value.name] = `${newRightColumnSize}%`

      sizeUpdated = true
    }, 16)

    const activateResizer = (event: MouseEvent, leftColumn: Column) => {
      // Store necessary info for resizeColumn()
      // rightColumnRef.value = rightColumn
      leftColumnRef.value = leftColumn
      mouseDownPageX.value = event.pageX

      // Fix size of columns when start
      calculateColumnSizes()
      // Make the resizer as high as the table when start dragging
      calculateResizerHeight()

      document.addEventListener('mousemove', resizeColumn)
    }

    const calculateColumnSizes = () => {
      const calculatedColumnSizes: any = {}
      tableWidth.value = ($table.$el as HTMLElement).offsetWidth
      for (const column of $table.columns) {
        const columnElement = $table.$el.querySelector(`.f-editable-table__header--${column.name}`) as HTMLElement
        if (!columnElement) continue
        calculatedColumnSizes[column.name] = `${columnElement.offsetWidth}px`
      }
      columnSizes.value = {...calculatedColumnSizes}
      backupColumnSizes.value = {...calculatedColumnSizes}
    }

    const deactivateResizer = () => {
      // No need for the resizer to cover the table when stop resizing
      resizerHeight.value = ''
      // Child-parent sync
      if (sizeUpdated) $table.$emit('update:columnSizes', columnSizes.value)
      sizeUpdated = false

      document.removeEventListener('mousemove', resizeColumn)
    }

    const resizerHeight = ref('')

    const calculateResizerHeight = () => {
      // We want the resizer to be as high as the table
      // So we will get the table height, then subtract it by:
      // 1. The dummy row at the bottom of the table
      // 2. The distance between the top of the table, and the top of its header (since header can be sticky)
      const {top: tableTop, bottom: tableBottom} = $table.$el.getBoundingClientRect()
      const tableHeight = tableBottom - tableTop
      const theadTop = $table.$el.querySelector('thead th').getBoundingClientRect().top
      const dummyRowHeight = 8
      resizerHeight.value = `${tableHeight - dummyRowHeight - (theadTop - tableTop)}px`
    }

    onMounted(() => {
      document.addEventListener('mouseup', deactivateResizer)
    })

    onBeforeUnmount(() => {
      deactivateResizer()
      document.removeEventListener('mouseup', deactivateResizer)
    })

    return {activateResizer, columnSizes, resizerHeight}
  }

  export function getCsvFromClipboardData(clipboardData: DataTransfer) {
    let pastedText: string
    let isHTML = true
    let pastedItems = []

    try {
      pastedText = clipboardData.getData('text/html')
      if (!pastedText) {
        pastedText = clipboardData.getData('text/plain')
        isHTML = false
      }
    } catch (e) {
      pastedText = clipboardData.getData('text')
      isHTML = false
    }
    pastedText = pastedText.trim()

    if (isHTML) {
      const parser = new DOMParser()
      let trList = parser.parseFromString(pastedText, 'text/html').querySelectorAll('tr')
      if (trList.length === 0) {
        trList = parser.parseFromString(pastedText, 'text/html').querySelectorAll('span')
        pastedItems = Array.from(trList).map(function (e) {
          return [e.innerText]
        })
      } else {
        pastedItems = Array.from(trList).map(function (tr) {
          const pat = Array.from(tr.querySelectorAll('td')).map(function (td: HTMLElement) {
            return td.innerText
          })
          return pat
        })
      }
    } else {
      pastedItems = pastedText.split('\n').map(function (row) {
        return row.split('\t')
      })
    }

    return pastedItems
  }
