<template functional lang="pug">
i(v-if='icon', :class='`shipping-type shipping-type--${icon}`')
</template>

<style lang="sass">
  i.shipping-type
    width: 24px
    min-width: 24px
    height: 24px
    display: inline-block
    vertical-align: middle

    &--cut
      background-image: url('@/assets/shipping-type-cut.svg')

    &--pot
      background-image: url('@/assets/shipping-type-pot.svg')

    &--other
      background-image: url('@/assets/shipping-type-other.svg')
</style>

<script>
  export default {
    props: {
      icon: {
        type: String || null,
        required: true
      }
    }
  }
</script>
