
  import _ from 'lodash'
  import {computed, ComputedRef, defineComponent, getCurrentInstance, Ref, ref, watch, toRefs} from 'vue'
  import debounce from 'lodash/debounce'
  import {convertToWidthCharacter, convertMinusJpToLatin, convert} from '@/utils'
  import {Column, ContextActionPayload, getCursor, getTable, Row} from './types'
  import JCommonPickDateInput from '../JCommonPickDateInput/index'
  import JCombobox from '@/components/JCombobox/index.vue'
  import {getSelectedColumnIndexes, getSelectedRowIndexes, toColumns, toRows} from './utils'
  import {filterOnComboBox} from '@/utils'
  import {WEEKS} from '@/utils/constants'

  export default defineComponent({
    props: {
      columns: {
        type: Array,
        required: true
      },
      isMoveRightOnEnter: {
        type: Boolean,
        required: true
      },
      isCustomCursor: {
        type: Boolean,
        default: true
      }
    },
    components: {
      JCommonPickDateInput,
      JCombobox
    },
    setup(props, {emit}) {
      const {columns: refColumns} = toRefs(props)
      const {style, cellClass, cursor} = setupCellStyle()
      const {row, column, cell, editing, inputType, readOnly} = setupTemplateModels()

      const {shouldNotSelectAllOnFocus} = makeSureInputAlwaysHaveFocus(column)
      const {enableEditMode, disableEditMode} = setupTogglingEditMode(shouldNotSelectAllOnFocus, column, row, readOnly)

      const {onInput} = setupInputEvent(row, column, disableEditMode)
      const {onKeyDown, moveCursorRightOnEnter, moveCursorOnEnter, onSpaceDown, handleCompositionEnd} = setupNavigation(
        {enableEditMode, disableEditMode, onInput}
      )

      const countItem = ref(0)
      const searchString = ref('')
      const itemsList = ref([])

      const autoSelectFirst = computed(() => {
        return countItem.value > 0 && searchString.value !== ''
      })

      const returnValue = computed(() => {
        if (column.value.returnCode) return 'code'
        else if (column.value.returnName) return 'name'
        else return null
      })
      const emitSearch = debounce((row, column, searchString) => {
        emit('on-searching-select-box', {
          row: row,
          column: column,
          searchInput: searchString
        })
      })
      const onUpdateSearchInput = (searchInput: any) => {
        // Fix input "ao" => only show "o" in japanese
        // Do not need this anymore, remove to fix the bug input item-variety code 1-1, then variety can not search
        // if (searchString.value === searchInput) return
        if (!searchInput) {
          // onInput(null)
          searchString.value = ''
        } else {
          searchString.value = searchInput.trim()
        }
        updateItemsList(searchString.value)
        emitSearch(row.value, column.value, searchString.value)
      }

      const updateItemsList = (queryText: string) => {
        if (!queryText) {
          itemsList.value = column.value.items ?? []
          countItem.value = itemsList.value ? itemsList.value.length : 0
          return
        }
        const columnName = column.value.name
        itemsList.value = column.value.items ? filterOnComboBox(column.value.items, queryText) : []
        countItem.value = itemsList.value.length
      }

      const onFilter = (item: any, queryText: string) => {
        // disabled filter, filter by updateItemsList
        return true
      }

      watch(
        () => column.value,
        () => {
          if (!column.value) return
          updateItemsList(searchString.value)
        },
        {
          deep: true,
          immediate: true
        }
      )

      return {
        style,
        cellClass,
        onKeyDown,
        onSpaceDown,
        handleCompositionEnd,
        row,
        column,
        cell,
        readOnly,
        editing,
        inputType,
        onInput,
        onUpdateSearchInput,
        onFilter,
        refColumns,
        autoSelectFirst,
        moveCursorRightOnEnter,
        itemsList,
        moveCursorOnEnter,
        cursor,
        returnValue
      }
    }
  })

  function setupCellStyle() {
    const $table = getTable()
    const cursor = getCursor()

    const style = computed(() => {
      const cursorBorderWidth = 2
      return {
        top: `${cursor.top + cursorBorderWidth}px`,
        left: `${cursor.left + cursorBorderWidth}px`,
        width: `${cursor.width - cursorBorderWidth * 2}px`
      }
    })

    const cellClass = computed(() => {
      return {
        editing: cursor.editing,
        [`cell-input--${$table.columns[cursor.columnIndex]?.name}`]: true,
        [`cell-input--row-${cursor.rowIndex + 1}`]: true
      }
    })

    return {
      style,
      cellClass,
      cursor
    }
  }

  function setupTemplateModels() {
    const cursor = getCursor()
    const $table = getTable()

    const row = computed(() => $table.rows[cursor.rowIndex])
    const column = computed(() => $table.columns[cursor.columnIndex])
    const cell = computed(() => {
      return row.value[column.value.name]
    })
    const readOnly = computed(() => {
      return (
        (row.value.readonly || []).includes(column.value.name) ||
        (row.value.disabledRow && !column.value.alwaysEnable) ||
        (row.value.disabledBucket || []).includes(column.value.name)
      )
    })
    return {
      row,
      column,
      cell,
      editing: computed(() => cursor.editing),
      inputType: computed(() => {
        if (column.value.type) {
          // type = number won't accept full width (zenkaku) number
          // to support inputting with full width number
          // we have change type number to type text and implement validate later
          if (column.value.type === 'number') {
            return 'text'
          }
          if (column.value.type === 'date') {
            return 'text'
          }
          return column.value.type
        }
        return 'text'
      }),
      readOnly
    }
  }

  function setupInputEvent(row: Ref<Row>, column: Ref<Column>, disableEditMode: () => void) {
    const $table = getTable()
    const cursor = getCursor()
    return {
      onInput($event: any, isClearRow = false) {
        if (
          row.value?.notEditable ||
          column.value?.disabled ||
          (column.value.onEnableEditMode &&
            column.value.onEnableEditMode({
              row: row.value,
              column: column.value
            }) === false)
        ) {
          return
        }
        if ($event && typeof $event === 'string' && column.value.normalizedContent !== false) {
          // convert all alphanumeric characters to half width
          // $event = convert($event.trim(), 'as')
          // Update: no convert the characters, keep the original type is user typed in
          $event = $event.trim()
        }
        if (!cursor.editing) {
          // Edit FTable to set null to number/integer field when delete the cell
          const emptyValue =
            column.value.type && ['number', 'integer'].includes(column.value.type) && !$event ? null : ''
          $table.$emit('input', {
            row: row.value,
            column: column.value,
            value: emptyValue,
            cursor: cursor,
            key: event.key,
            isClearRow: isClearRow
          })
          return
        }

        // validate for input type =  number
        if (column.value.type === 'number') {
          // Edit FTable
          // convert all alphanumeric characters to half width
          if (typeof $event === 'string') $event = Number($event?.normalize('NFKC'))
          // check value is numeric or not, if not do not allow input
          if ($event && typeof $event === 'string' && !/^\d+$/.test($event?.normalize('NFKD'))) return
          // convert to null when not a number
          if (!_.isNumber($event)) $event = null
        }

        // validate for input type = integer
        if (column.value.type === 'integer') {
          $event = convertMinusJpToLatin($event)
          // check value is numeric or not, if not do not allow input
          if ($event && typeof $event === 'string' && !/^-?[0-9]\d*(\.\d+)?$/.test($event?.normalize('NFKD'))) return
          else if (typeof $event === 'string') {
            $event = parseInt($event?.normalize('NFKD'))
            if (isNaN($event)) $event = ''
          }
          // convert to null when not a number
          if (!_.isNumber($event)) $event = null
        }
        $table.$emit('input', {
          row: row.value,
          column: column.value,
          value: $event,
          cursor: cursor
        })
        // Disable edit mode after user selected a dropdown entry
        if (column.value.dropdown && $event) {
          disableEditMode()
        }
      }
    }
  }

  function makeSureInputAlwaysHaveFocus(column: Ref<Column>) {
    const $table = getTable()
    const cursor = getCursor()
    const vm = getCurrentInstance().proxy
    const shouldNotSelectAllOnFocus = ref(false)

    /**
     * Focus on the input upon called
     */
    const focus = debounce(function () {
      // Find the input
      const input = getInput()
      if (!input) return

      // Focus if not already focus
      if (document.activeElement !== input) input.focus()

      // Select all texts inside
      if (shouldNotSelectAllOnFocus.value) {
        // eslint-disable-next-line no-param-reassign
        shouldNotSelectAllOnFocus.value = false
        vm.$nextTick(() => {
          try {
            input.selectionStart = input.selectionEnd
          } catch (e) {
            // Try setting selectionStart on a number input will cause exception
            // This is safe to ignore
          }
        })
      } else {
        // Fig bug: Comment out below code because selected hidden input in IME mode,
        // then F9 will make this input turn into focusing typing text.
        // Comment out this code will not cause effect because we use onInput('') on onKeyDown event
        // to handle the case when typing text in hidden input
        // vm.$nextTick(() => input.select())
      }

      // Click on v-autocomplete component to open options menu
      if (column.value.dropdown) {
        vm.$nextTick(() => input.click())
      }
      // Wait until the input completes moving to new coordinate, otherwise the page
      // will be janked back to input's old position
    }, 16)

    // Refocus whenever the table's body was clicked
    $table.$on('bodyclick', focus)

    // Refocus when edit mode changes
    watch(() => cursor.editing, focus)

    function getInput(): HTMLInputElement {
      return vm.$el?.querySelector('input:not([type=hidden])')
    }

    return {
      shouldNotSelectAllOnFocus
    }
  }

  function setupTogglingEditMode(
    shouldNotSelectAllOnFocus: Ref<boolean>,
    column: ComputedRef<Column>,
    row: ComputedRef<Row>,
    readOnly: Ref<boolean>
  ) {
    const $table = getTable()
    const cursor = getCursor()

    function enableEditMode(shouldNotSelectAll = false) {
      if (row.value.parentId && row.value.parentId !== row.value.id && WEEKS.includes(column.value.name)) return
      if (column.value.name === 'total_bouquet' && row.value.disableTotalBouquet) return
      if (
        column.value.disabled ||
        readOnly.value ||
        column.value.isSpan ||
        column.value.isButton ||
        (column.value.onEnableEditMode &&
          column.value.onEnableEditMode({
            row: row.value,
            column: column.value
          }) === false)
      ) {
        return
      }

      // eslint-disable-next-line no-param-reassign
      shouldNotSelectAllOnFocus.value = shouldNotSelectAll
      cursor.editing = true
    }

    function disableEditMode() {
      cursor.editing = false
    }

    // Disable edit mode when row or column changes
    watch([() => cursor.rowIndex, () => cursor.columnIndex], (newValue, oldValue) => {
      $table.$emit('blur', {rowIndex: oldValue[0], columnIndex: oldValue[1]})
      disableEditMode()
    })

    // Click outside
    $table.$on('outsideclick', () => {
      $table.$emit('blur', {
        rowIndex: cursor.rowIndex,
        columnIndex: cursor.columnIndex
      })
      // remove cursor when click outside
      cursor.rowIndex = -1
      disableEditMode()
    })

    // Switch to edit mode when user double clicks on a cell
    $table.$on('celldblclick', () => {
      // When this cell is in edit mode, and user double click to a new cell
      if (cursor.editing) {
        // Then we must disable edit mode first to destroy current component
        disableEditMode()
        // Then re-enable to show the input
        setTimeout(() => enableEditMode)
      } else {
        // Otherwise just show the input directly
        enableEditMode()
      }
    })

    return {
      enableEditMode,
      disableEditMode
    }
  }

  type SetupNavigationInput = ReturnType<typeof setupTogglingEditMode> & ReturnType<typeof setupInputEvent>

  function setupNavigation({enableEditMode, disableEditMode, onInput}: SetupNavigationInput) {
    const cursor = getCursor()
    const $table = getTable()
    const vm = getCurrentInstance().proxy
    const {readOnly} = setupTemplateModels()

    function onSpaceDown() {
      const currentColumn = $table.columns[cursor.columnIndex]
      const currentRow = $table.rows[cursor.rowIndex]
      const currentCell = currentRow[currentColumn.name]
      if (readOnly.value) return
      $table.$emit('input', {
        row: currentRow,
        column: currentColumn,
        value: !currentCell
      })
    }

    // Fix bug: Windows IME does not recognize Space on checkbox column
    function handleCompositionEnd(event, column) {
      // We just only handle space event on checkbox column
      if (column.type !== 'checkbox') return
      if (event.data === '　' || event.data === ' ') onSpaceDown()
    }

    function onKeyDown(event, columns = [], column = null, isMoveRightOnEnter) {
      // We do not want to alter behavior of most keys while in edit mode.
      // Only except:
      // + Tab: to move cursor to right/left cell
      // + Enter: to move cursor to below cell
      // + Esc: to exit edit mode
      const currentColumn = $table.columns[cursor.columnIndex]
      const currentRow = $table.rows[cursor.rowIndex]
      const ignoreInput = currentColumn.isSpan || currentColumn.isButton || readOnly.value || currentRow.disabled

      // Fix bug: windows IME JP mode does not recognize keydown event,
      // then we use compositionstart to enable edit mode on CellInput
      if (event.key === 'compositionstart') {
        if (ignoreInput) return
        onInput('')
        // Then enable edit mode
        enableEditMode(true)
        return
      }
      if (ignoreInput) {
        if (currentColumn.type === 'checkbox' && event.code === 'Space') {
          onSpaceDown()
        }
        // Do not intercept any key combination with modifier
        if (event.ctrlKey || event.altKey || event.metaKey) return
        event.preventDefault()
        navigateFromEvent(event, columns, column, isMoveRightOnEnter)
        return
      }

      if (cursor.editing && !(event.key === 'Tab' || event.key === 'Enter' || event.key === 'Escape')) {
        return
      }
      if (event.key === 'Enter') {
        $table.$emit('cell-enter', {
          cursor: cursor,
          column: column
        })
      }

      if (event.key === 'Delete' || event.key === 'Backspace') {
        let notEmptyRow = []
        const activeColumns = $table.columns.filter(obj => obj.isActive === true).map(obj => obj.name)
        const rowIndexes = getSelectedRowIndexes($table)
        const rows = toRows($table, rowIndexes)
        const columnIndexes = getSelectedColumnIndexes($table)
        const columns = toColumns($table, columnIndexes)
        const actionPayload = computed((): ContextActionPayload => {
          return {
            row: $table.rows[cursor.rowIndex],
            rowIndex: cursor.rowIndex,
            column: $table.columns[cursor.columnIndex],
            columnIndex: cursor.columnIndex,
            rows,
            rowIndexes,
            columns,
            columnIndexes
          }
        })

        if (cursor.columnIndex >= 0) {
          // Set cursor to the top left if select whole rows
          if ($table.currentRegions.length) {
            rowIndexes.forEach(rowIndex => {
              cursor.rowIndex = rowIndex
              cursor.columnIndex = 0
              onInput('', true)
            })
          } else {
            rowIndexes.forEach(rowIndex => {
              columnIndexes.forEach(columnIndex => {
                cursor.rowIndex = rowIndex
                cursor.columnIndex = columnIndex
                const columnName = $table.columns[cursor.columnIndex]['name']
                if (activeColumns.includes(columnName) && columnName !== 'images' && columnName !== 'mix_content') {
                  onInput('')
                }
              })
              // check if a row is not empty
              const isNotEmptyRow = activeColumns.some(key => {
                return $table.rows[rowIndex][key] !== null && $table.rows[rowIndex][key] !== ''
              })
              if (isNotEmptyRow) {
                notEmptyRow.push(rowIndex)
              }
            })
          }
        }
        // add empty row into payload
        actionPayload.value.rowIndexes = rowIndexes.filter(value => !notEmptyRow.includes(value))
        actionPayload.value.rows = toRows($table, actionPayload.value.rowIndexes)
        // delete row if it is empty
        // if (actionPayload.value.rowIndexes.length > 0) {
        //   $table.$emit('even-right-click', {
        //     event: 'handle_delete_rows',
        //     actionPayload: actionPayload.value
        //   })
        // }
      }
      //- if (column.enterSkip) {
      //-   if (event.key === 'Tab' || (event.key === 'Enter' && !column.dropdown) || event.key === 'Escape') {
      //-     moveCursorOnEnter({ force: true, nextLineOnEnd: true, cols: columns }, isMoveRightOnEnter, false)
      //-   }
      //- }
      // Do not intercept any key combination with modifier
      if (event.ctrlKey || event.altKey || event.metaKey) return

      // Character key events should not be prevented so user could start typing
      // when cell is not in edit mode
      // When using virtual/mobile keyboards, formally known as IME (Input-Method Editor),
      // the W3C standard states that a KeyboardEvent’s e.keyCode should be 229
      // and e.key should be "Unidentified" (on Ubuntu) or "Process" (on Windows)
      // https://javascript.info/keyboard-events#mobile-keyboards
      // TODO: Need to test on Macbook, remove this later
      if (
        event.key.length === 1 ||
        ((event.key === 'Unidentified' || event.key === 'Process') && event.keyCode === 229)
      ) {
        // If the cell is not in editing mode
        if (!cursor.editing) {
          // If this cell is in readonly mode r disabled, it should not go to edit mode
          const currentCol = $table.columns[cursor.columnIndex]
          if (readOnly.value || currentCol.disabled) return

          // Clear the current value, so that the character will be
          // set as the input's new value

          onInput('')
          // Then enable edit mode
          enableEditMode(true)
          // Make sure that if the input element changed (e.g overridden by external slot),
          // it will still have focused
          vm.$nextTick(() => {
            const input = getInput()
            input?.focus()
          })
        }
        return
      }

      // Prevent default behavior of all keys
      event.preventDefault()

      navigateFromEvent(event, columns, column, isMoveRightOnEnter)

      function getInput(): HTMLInputElement {
        return vm.$el?.querySelector('input:not([type=hidden])')
      }
    }
    function navigateFromEvent(event, columns = [], column = null, isMoveRightOnEnter) {
      switch (event.key) {
        case 'Enter':
          // Integrate with vuetify's menuable component
          // Do not handle Enter if the menuable component is showing while in edit mode
          if (cursor.editing && document.querySelector('.v-menu__content.menuable__content__active')) {
            break
          }

          if (!cursor.editing) {
            // If not in edit mode, enable edit mode
            // We must disable edit mode first to destroy current component
            disableEditMode()
            // Then re-enable to show the input
            setTimeout(() => enableEditMode())
          } else {
            // If in edit mode, move cursor down
            const cursorMoved = moveCursorDown({force: true})
            // If the cursor wasn't moved (i.e., cursor at last line), manually disable edit mode
            if (!cursorMoved) disableEditMode()
          }
          break
        case 'ArrowDown':
          moveCursorDown()
          break
        case 'ArrowUp':
          moveCursorUp()
          break
        case 'ArrowRight':
          moveCursorRight({cols: columns})
          break
        case 'ArrowLeft':
          moveCursorLeft({force: true, prevLineOnStart: true, cols: columns})
          break
        case 'F2':
          enableEditMode(true)
          break
        case 'Escape':
          disableEditMode()
          break
        case 'Tab':
          // Ignore Alt+Tab or Ctrl+Tab
          if (event.ctrlKey || event.altKey) break

          if (!event.shiftKey) {
            moveCursorRight({force: true, nextLineOnEnd: true, cols: columns})
          } else {
            moveCursorLeft({force: true, prevLineOnStart: true, cols: columns})
          }
          break
        default:
          break
      }
    }

    function moveCursorRight({force = false, nextLineOnEnd = false, cols = []} = {}) {
      if (cursor.editing && !force) return
      // const nextIndex = cursor.columnIndex + 1
      let nextIndex = cursor.columnIndex
      //Searching the last active index
      let lastIndexActive = cols.length - 1
      for (let j = cols.length - 1; j >= 0; j -= 1) {
        if (cols[j].isActive === false) {
          continue
        } else if (cols[j].isActive === true) {
          lastIndexActive = j
          break
        }
      }
      //Searching nearest right active index
      if (cursor.columnIndex < lastIndexActive) {
        for (let i = cursor.columnIndex + 1; i < cols.length; i += 1) {
          if (cols[i].isActive === true) {
            nextIndex = i
            break
          }
        }
      } else {
        nextIndex += 1
      }
      //Move cursor down when it moved to the end of row
      if (lastIndexActive >= nextIndex) {
        cursor.columnIndex = nextIndex
      } else if (nextLineOnEnd) {
        if (moveCursorDown({force})) {
          //Searching the first active column
          let firstActiveCol = 0
          for (let i = 0; i <= cols.length; i += 1) {
            if (cols[i].isActive === true) {
              firstActiveCol = i
              break
            }
          }
          cursor.columnIndex = firstActiveCol
        }
      }
    }

    function moveCursorLeft({force = false, prevLineOnStart = false, cols = []} = {}) {
      if (cursor.editing && !force) return
      let prevIndex = cursor.columnIndex
      //Searching nearest left active index
      for (let i = cursor.columnIndex - 1; i >= 0; i -= 1) {
        if (cols[i].isActive === true) {
          prevIndex = i
          break
        }
      }
      if (prevIndex >= 0) {
        cursor.columnIndex = prevIndex
      } else if (prevLineOnStart) {
        if (moveCursorUp({force})) {
          cursor.columnIndex = $table.columns.length - 1
        }
      }
    }

    function moveCursorOnEnter(
      {force = false, nextLineOnEnd = false, cols = []} = {},
      isMoveRightOnEnter = true,
      isClickItemDropDown = false
    ) {
      let result = null
      if (isMoveRightOnEnter) {
        result = moveCursorRightOnEnter({force: true, nextLineOnEnd: true, cols: cols}, isClickItemDropDown)
      } else {
        moveCursorDown({force}, isClickItemDropDown)
      }
      return result
    }

    function moveCursorRightOnEnter(
      {force = false, nextLineOnEnd = false, cols = []} = {},
      isClickItemDropDown = false
    ) {
      if (cursor.editing && !force) return
      // const nextIndex = cursor.columnIndex + 1
      let nextIndex = cursor.columnIndex

      //Searching the last active index
      let lastIndexActive = cols.length - 1
      for (let j = cols.length - 1; j >= 0; j -= 1) {
        if (cols[j].isActive === false) {
          continue
        } else if (cols[j].isActive === true && cols[j].enterSkip === false) {
          lastIndexActive = j
          break
        }
      }
      //Searching nearest right active index
      if (cursor.columnIndex < lastIndexActive) {
        for (let i = cursor.columnIndex + 1; i < cols.length; i += 1) {
          if (cols[i].isActive === true && cols[i].enterSkip === false) {
            nextIndex = i
            // get next cell Element active
            let classNm = `.cell-${nextIndex}-${cursor.rowIndex}`
            const cellElement = document.querySelector(classNm)
            const elementRect = cellElement.getBoundingClientRect()
            // check if the next cell is in viewport ornot
            let tableElement = $table.$el
            let tableContainer = tableElement.parentNode
            let containerRect = tableContainer?.getBoundingClientRect()
            const isElementInContainer = elementRect?.right <= containerRect.right

            if (!isElementInContainer) {
              // Move scroll if not
              const currentTranslateX = getTranslateX(tableElement)
              const newTranslateX = Math.abs(currentTranslateX) + elementRect.right - containerRect.right
              // check in case of Payment screen not custom cursor
              if (!vm.$attrs.isCustomCursor) {
                $table.$el.scrollLeft = -newTranslateX
              } else {
                $table.$el.style.transform = `translateX(${-newTranslateX}px)`
              }
            }
            break
          }
        }
      } else {
        nextIndex += 1
      }
      //Move cursor down when it moved to the end of row
      if (lastIndexActive >= nextIndex) {
        cursor.columnIndex = nextIndex
      } else if (nextLineOnEnd) {
        if (moveCursorDown({force})) {
          //Searching the first active column
          let firstActiveCol = 0
          for (let i = 0; i <= cols.length; i += 1) {
            if (cols[i].isActive === true && cols[i].enterSkip === false) {
              firstActiveCol = i
              break
            }
          }
          cursor.columnIndex = firstActiveCol
          // Move scroll back when enter in the end of row
          $table.$el.style.transform = `translateX(${0}px)`
        }
      }
      // Fix loose focus when click on dropdown items
      if (isClickItemDropDown) {
        const newRowIndex = cursor.rowIndex
        const newColumnIndex = cursor.columnIndex
        setTimeout(() => {
          cursor.rowIndex = newRowIndex
          cursor.columnIndex = newColumnIndex
          disableEditMode()
        })
      }
    }

    // Get position of element
    function getTranslateX(element) {
      const transformValue = window.getComputedStyle(element).getPropertyValue('transform')
      const matrix = new DOMMatrix(transformValue)
      return matrix.m41
    }
    // function moveCursorLeftOnEnter({force = false, prevLineOnStart = false, cols = []} = {}) {
    //   if (cursor.editing && !force) return
    //   let prevIndex = cursor.columnIndex
    //   //Searching the first active index
    //   let firstIndexActive = 0
    //   for (let j = 0; j <= cols.length - 1 ; j += 1) {
    //     if (cols[j].isActive === false) {
    //       continue
    //     } else if (cols[j].isActive === true && cols[j].enterSkip === false) {
    //       firstIndexActive = j
    //       break
    //     }
    //   }
    //   //Searching nearest left active index
    //   for (let i = cursor.columnIndex - 1; i >= 0; i -= 1) {
    //     if (cols[i].isActive === true && cols[i].enterSkip === false) {
    //       prevIndex = i
    //       break
    //     }
    //   }
    //   if (prevIndex >= firstIndexActive) {
    //     cursor.columnIndex = prevIndex
    //   } else if (prevLineOnStart) {
    //     if (moveCursorUp({force})) {
    //       cursor.columnIndex = $table.columns.length - 1
    //     }
    //   }
    // }

    function moveCursorDown({force = false} = {}, isClickItemDropDown = false) {
      let result = false
      if (!cursor.editing || force) {
        const nextIndex = cursor.rowIndex + 1
        if ($table.rows.length > nextIndex) {
          cursor.rowIndex = nextIndex
          result = true
        }
      }
      // Fix loose focus when click on dropdown items
      if (isClickItemDropDown && result) {
        const newRowIndex = cursor.rowIndex
        const newColumnIndex = cursor.columnIndex
        setTimeout(() => {
          cursor.rowIndex = newRowIndex
          cursor.columnIndex = newColumnIndex
          disableEditMode()
        })
      }
      return result
    }

    /**
     * Move the cursor up
     * @returns {boolean}
     */
    function moveCursorUp({force = false} = {}) {
      if (cursor.editing && !force) return false
      const prevIndex = cursor.rowIndex - 1
      if (prevIndex >= 0) {
        cursor.rowIndex = prevIndex
        return true
      }
      return false
    }

    return {
      onKeyDown,
      moveCursorRightOnEnter,
      moveCursorOnEnter,
      onSpaceDown,
      handleCompositionEnd
    }
  }
