<template lang="pug">
div
  j-master-dialog(
    ref='master_dialog',
    :height='"85vh"',
    :is-add='isAdd',
    :is-on-mobile='isOnMobile',
    :label='isAdd ? $t("item.add") : $t("item.edit")',
    :label-name='$t("common.name")',
    :loading='loading',
    :master-data='itemData',
    :show='show'
    type='item'
    @on-close='closeDialog'
    @on-create='createItem'
    @on-update='updateItem')
    template(#middle)
      validation-observer(ref='observer' v-slot='{invalid}')
        //- 略称
        validation-provider(v-slot='{errors}', :name='$t("common.short_name")', :rules={required: true})
          v-text-field#short_name(v-model='itemData.short_name' v-next-focus, :error-messages='errors')
            template(v-slot:label)
              span {{$t('common.short_name')}}
              span.red--text *
        //- WEB略称
        v-text-field#web_name(v-model='itemData.web_name' v-next-focus, :label='$t("common.web_name")')
        //- WEB名称
        v-text-field#web_full_name(v-model='itemData.web_full_name' v-next-focus, :label='$t("common.web_full_name")')
        //- WEBカナ名称
        v-text-field#web_kana_name(v-model='itemData.web_kana_name' v-next-focus, :label='$t("common.web_kana_name")')
        validation-provider(v-slot='{errors}', :name='$t("common.flower_type")' rules='required')
          v-autocomplete#flower_type(
            v-model='itemData.flower_type_id'
            v-next-focus,
            :error-messages='errors',
            :filter='(item, queryText, itemText) => filterBySearchString(item, queryText)',
            :items='flowerTypeList.map(item => ({...item, name_display: `${item.code}：${item.name}`}))',
            :label='$t("common.flower_type")'
            clearable
            item-text='name_display'
            item-value='id')
            template(v-slot:label)
              span {{$t('common.flower_type')}}
              span.red--text *
            template(v-slot:no-data)
              span.pl-5.grey--text {{$t('message.no_data')}}
        //- 開設者品目
        v-autocomplete#establisher_item(
          v-model='itemData.establisher_item_id'
          v-next-focus,
          :filter='(item, queryText, itemText) => filterBySearchString(item, queryText)',
          :items='establisherItemList.map(item => ({...item, name_display: `${item.code}：${item.name}`}))',
          :label='$t("common.establisher_item")'
          clearable
          item-text='name_display'
          item-value='id')
          template(v-slot:no-data)
            span.pl-5.grey--text {{$t('message.no_data')}}
        //- 全農品目
        v-autocomplete#agri_item(
          v-model='itemData.agri_item_id'
          v-next-focus,
          :filter='(item, queryText, itemText) => filterBySearchString(item, queryText)',
          :items='agriItemList.map(item => ({...item, name_display: `${item.code}：${item.name}`}))',
          :label='$t("common.agri_item")'
          clearable
          item-text='name_display'
          item-value='id')
          template(v-slot:no-data)
            span.pl-5.grey--text {{$t('message.no_data')}}

        //- 大分類
        v-autocomplete#broad_category(
          v-model='itemData.broad_category_id'
          v-next-focus,
          :filter='(item, queryText, itemText) => filterBySearchString(item, queryText)',
          :items='broadCategoryList.map(item => ({...item, name_display: `${item.code}：${item.name}`}))',
          :label='$t("common.broad_category")'
          clearable
          item-text='name_display'
          item-value='id')
          template(v-slot:no-data)
            span.pl-5.grey--text {{$t('message.no_data')}}
        //- 切花部門
        v-autocomplete#cut_department(
          v-model='itemData.cut_department_id'
          v-next-focus,
          :filter='(item, queryText, itemText) => filterBySearchString(item, queryText)',
          :items='departmentList.map(item => ({...item, name_display: `${item.code}：${item.name}`}))',
          :label='$t("item.cut_department")'
          clearable
          item-text='name_display'
          item-value='id')
          template(v-slot:no-data)
            span.pl-5.grey--text {{$t('message.no_data')}}
        //- 鉢部門
        v-autocomplete#pot_department(
          v-model='itemData.pot_department_id'
          v-next-focus,
          :filter='(item, queryText, itemText) => filterBySearchString(item, queryText)',
          :items='departmentList.map(item => ({...item, name_display: `${item.code}：${item.name}`}))',
          :label='$t("item.pot_department")'
          clearable
          item-text='name_display'
          item-value='id')
          template(v-slot:no-data)
            span.pl-5.grey--text {{$t('message.no_data')}}
        //- セリ単位
        v-autocomplete#unit(
          v-model='itemData.unit_id'
          v-next-focus,
          :filter='(item, queryText, itemText) => filterBySearchString(item, queryText)',
          :items='unitList.map(item => ({...item, name_display: `${item.code}：${item.name}`}))',
          :label='$t("item.seri_unit")'
          clearable
          item-text='name_display'
          item-value='id')
          template(v-slot:no-data)
            span.pl-5.grey--text {{$t('message.no_data')}}
        //- 販売担当者
        v-autocomplete#member(
          v-model='itemData.member_id'
          v-next-focus,
          :filter='(item, queryText, itemText) => filterBySearchString(item, queryText)',
          :items='memberList.map(item => ({...item, name_display: `${item.code}：${item.name}`}))',
          :label='$t("item.member")'
          clearable
          item-text='name_display'
          item-value='id')
          template(v-slot:no-data)
            span.pl-5.grey--text {{$t('message.no_data')}}
        //- セリ初期レーン
        v-text-field#auction_lane(v-model='itemData.auction_lane' v-next-focus, :label='$t("item.auction_lane")')
  j-confirm-dialog(
    :cancel-text='$t("common.cancel")',
    :messages='[$t("message.confirm_used_in_assignment")]',
    :ok-text='$t("common.save")',
    :showDialog='showConfirmUsedDialog'
    @on-cancel='showConfirmUsedDialog = false'
    @on-close='showConfirmUsedDialog = false'
    @on-ok='updateItem(false), (showConfirmUsedDialog = false)')
</template>
<script>
  import {defineComponent, toRefs, ref, getCurrentInstance, watch, onMounted} from 'vue'
  import {ValidationProvider, ValidationObserver} from 'vee-validate'
  import JMasterDialog from '../JMasterDialog/index'
  import JConfirmDialog from '../JConfirmDialog/index'
  import {api} from '@/plugins'
  import {endpoints, showError, filterBySearchString} from '@/utils'

  const JItemDialog = defineComponent({
    components: {
      JConfirmDialog,
      JMasterDialog,
      ValidationProvider,
      ValidationObserver
    },
    props: {
      show: {type: Boolean, required: true},
      item: {type: Object, required: false, default: null},
      isAdd: {type: Boolean, required: false, default: true},
      prevInput: {type: String, required: false, default: ''},
      flowerTypesProps: {type: Array, required: false, default: null},
      establisherItemsProps: {type: Array, required: false, default: null},
      agriItemProps: {type: Array, required: false, default: null},
      broadCategoriesProps: {type: Array, required: false, default: null},
      departmentProps: {type: Array, required: false, default: null},
      unitProps: {type: Array, required: false, default: null},
      isOnMobile: {type: Boolean, required: false, default: false},
      membersProp: {type: Array, required: false, default: null}
    },
    setup(props, {emit}) {
      const {$toast, $root, $refs} = getCurrentInstance().proxy
      const {item} = toRefs(props)
      const loading = ref(false)

      const CREATE_ENDPOINT = `${endpoints.MASTER_ITEM}`
      const UPDATE_ENDPOINT = `${endpoints.MASTER_ITEM}`

      const itemData = ref({})
      const isSuccessAll = ref(true)
      const showConfirmUsedDialog = ref(false)

      const closeDialog = () => {
        loading.value = false
        emit('on-close')
        resetValidate()
      }

      const validateElement = async () => {
        const validate = await $refs.observer.validate()
        if (!validate) {
          $toast.error($root.$t('message.check_data_type'))
          return false
        }
        return true
      }

      const resetValidate = async () => {
        const validateName = await $refs.observer
        validateName.reset()
      }

      const convertData = () => {
        Object.keys(itemData.value).forEach(key => {
          if (itemData.value[key] === '') itemData.value[key] = null
        })
      }

      const createItem = async () => {
        loading.value = true
        convertData()
        if (!(await validateElement())) {
          loading.value = false
          return
        }
        try {
          const {data} = await api.post(CREATE_ENDPOINT, itemData.value)
          if (isSuccessAll.value) $toast.success($root.$t('message.create_successful'))
          emit('on-reload', data)
          closeDialog()
        } catch (e) {
          // if api return duplicate --> show dialog confirm and allow save in db
          showError(e, $toast, $root.$t('message.update_failed'))
        } finally {
          loading.value = false
          await resetValidate()
        }
      }

      const updateItem = async () => {
        loading.value = true
        try {
          await api.put(UPDATE_ENDPOINT + `/${itemData.value.id}`, itemData.value)
          if (isSuccessAll.value) $toast.success($root.$t('message.create_successful'))
          emit('on-reload')
          closeDialog()
        } catch (e) {
          // if api return duplicate --> show dialog confirm and allow save in db
          showError(e, $toast, $root.$t('message.update_failed'))
        } finally {
          loading.value = false
        }
      }
      const confirmUpdate = async () => {
        try {
          await api.post(`${UPDATE_ENDPOINT}check?id=${itemData.value.id}`)
          await updateItem()
        } catch (e) {
          showConfirmUsedDialog.value = true
        }
      }

      const init = async () => {
        if (props.show) {
          if (props.isAdd) {
            const itemInit = {
              code: null,
              name: props.prevInput,
              full_name: null,
              flower_type: null
            }
            itemData.value = JSON.parse(JSON.stringify(itemInit))
          } else {
            itemData.value = JSON.parse(JSON.stringify(item.value))
          }
        }
      }

      // 切鉢分類
      const flowerTypeList = ref([])
      const getFlowerType = async () => {
        if (props.flowerTypesProps) {
          flowerTypeList.value = props.flowerTypesProps
        } else {
          try {
            const {data} = await api.get(`${endpoints.MASTER_FLOWER_TYPE}?active=true`)
            flowerTypeList.value = data.filter(clr => clr.active === true)
          } catch (e) {
            showError(e, $toast, $root.$t('message.get_data_failed'))
          }
        }
      }

      // 開設者品目
      const establisherItemList = ref([])
      const getEstablisherItem = async () => {
        if (props.establisherItemsProps) {
          establisherItemList.value = props.establisherItemsProps
        } else {
          try {
            const {data} = await api.get(`${endpoints.MASTER_ESTABLISHER_ITEM}?active=true`)
            establisherItemList.value = data.filter(clr => clr.active === true)
          } catch (e) {
            showError(e, $toast, $root.$t('message.get_data_failed'))
          }
        }
      }

      // 全農品目
      const agriItemList = ref([])
      const getAgriItem = async () => {
        if (props.agriItemsProps) {
          agriItemList.value = props.agriItemsProps
        } else {
          try {
            const {data} = await api.get(`${endpoints.MASTER_AGRI_ITEM}?active=true`)
            agriItemList.value = data.filter(clr => clr.active === true)
          } catch (e) {
            showError(e, $toast, $root.$t('message.get_data_failed'))
          }
        }
      }

      // 大分類
      const broadCategoryList = ref([])
      const getBroadCategory = async () => {
        if (props.broadCategorysProps) {
          broadCategoryList.value = props.broadCategorysProps
        } else {
          try {
            const {data} = await api.get(`${endpoints.MASTER_BROAD_CATEGORY}?active=true`)
            broadCategoryList.value = data.filter(clr => clr.active === true)
          } catch (e) {
            showError(e, $toast, $root.$t('message.get_data_failed'))
          }
        }
      }

      // 部門
      const departmentList = ref([])
      const getDepartment = async () => {
        if (props.departmentsProps) {
          departmentList.value = props.departmentsProps
        } else {
          try {
            const {data} = await api.get(`${endpoints.MASTER_DEPARTMENT}?active=true`)
            departmentList.value = data.filter(clr => clr.active === true)
          } catch (e) {
            showError(e, $toast, $root.$t('message.get_data_failed'))
          }
        }
      }

      // セリ単位
      const unitList = ref([])
      const getUnit = async () => {
        if (props.unitProps) {
          unitList.value = props.unitProps
        } else {
          try {
            const {data} = await api.get(`${endpoints.MASTER_UNIT}?active=true`)
            unitList.value = data.filter(clr => clr.active === true)
          } catch (e) {
            showError(e, $toast, $root.$t('message.get_data_failed'))
          }
        }
      }

      const memberList = ref([])
      const getMembers = async () => {
        if (props.membersProp) {
          memberList.value = props.membersProp
        } else {
          try {
            const {data} = await api.get(`${endpoints.MASTER_MEMBERS}?active=true`)
            memberList.value = data.filter(clr => clr.active === true)
          } catch (error) {
            showError(error, $toast, $root.$t('message.get_data_failed'))
          }
        }
      }

      onMounted(() => {
        Promise.all([
          getFlowerType(),
          getEstablisherItem(),
          getAgriItem(),
          getDepartment(),
          getBroadCategory(),
          getUnit(),
          getMembers()
        ])
      })
      watch(
        () => props.show,
        () => {
          init()
        }
      )
      watch(
        () => [
          props.flowerTypesProps,
          props.establisherItemsProps,
          props.agriItemProps,
          props.broadCategoriesProps,
          props.departmentsProps,
          props.unitProps
        ],
        () => {
          flowerTypeList.value = props.flowerTypesProps
          establisherItemList.value = props.establisherItemsProps
          agriItemList.value = props.agriItemProps
          broadCategoryList.value = props.broadCategoriesProps
          departmentList.value = props.departmentsProps
          unitList.value = props.unitProps
        }
      )

      return {
        closeDialog,
        loading,
        itemData,
        updateItem,
        createItem,
        confirmUpdate,
        showConfirmUsedDialog,
        flowerTypeList,
        establisherItemList,
        agriItemList,
        departmentList,
        broadCategoryList,
        unitList,
        filterBySearchString,
        memberList
      }
    }
  })

  export default JItemDialog
</script>

<style lang="sass" scoped>
  .content
    padding: 10px
  .required
    content: '99'
    color: red
  .list-item
    max-height: 550px
    overflow-y: auto
  .v-list-item
    padding: 0 !important
    min-height: 35px !important

  .item
    display: flex
    align-items: center

  .item .code
    display: inline-block
    width: 100px
    text-align: left
    padding-left: 20px
  .item .name
    display: inline-block
    width: calc(100% - 100px)
    text-align: left
    padding-right: 20px
  .icon-list
    font-size: 20px
    display: inline-block
    justify-content: center
    align-items: center


  .color-btn
    background-color: #bdbdbd
</style>
