
  import {defineComponent} from 'vue'

  export default defineComponent({
    props: {
      height: {
        type: String, // css height so type is string
        default: ''
      }
    }
  })
