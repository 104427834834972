import {computed, ComputedRef, reactive} from 'vue'

// import {getXAccountId, setupAccountBridge, setXAccountId} from '@/common/services/account'

import {BridgeMethod, EMBEDDER, IFrameBridge, IS_VALIDLY_EMBEDDED} from './embed'
// import storage from './storage'
import {Auth} from 'aws-amplify'

interface IUserConfiguration {
  // eslint-disable-next-line camelcase
  has_seen_merchant_welcome_page: boolean
}
interface IUserInfo {
  id?: string
  // eslint-disable-next-line camelcase
  account_id?: string
  picture?: string
  name?: string
  email?: string
  perms?: Array<string>
  // eslint-disable-next-line camelcase
  grower_ids?: Array<string>
  // eslint-disable-next-line camelcase
  configuration?: IUserConfiguration
}

type IAuthStore = {
  userInfo: IUserInfo
  isAuthenticated: boolean
  isVerifyingAuthentication: boolean
}
export enum ROLES {
  ADMIN = 'admin',
  MEMBER = 'member',
  BILLING = 'billing'
}
// Storage key for storing user info
const USER_INFO = 'user_info'
export const GetParentToken: BridgeMethod<() => string> = Symbol('get parent token')
export const GetParentUserInfo: BridgeMethod<() => IUserInfo> = Symbol('get parent user info')
export const HandleParentAuthentication: BridgeMethod<() => string> = Symbol('handle parent authentication')

const getTokenSilently = async () => {
  const token = await Auth.currentSession().then((res: any) => {
    const accessToken = res.getAccessToken()
    return accessToken.getJwtToken()
  })
  return token
}

const getUserInfoFromStorage = () => {
  return JSON.parse(
    '{"email":"admin@merchant.ex","tel":"090-0000-0001","nick":"First user","name":"First Merchant User","picture":"","tel_code":81,"new_email":"","configuration":{"has_seen_merchant_welcome_page":true},"roles":["admin"],"id":"BYVGAEL3QACAM","account_id":"BYVF6GOGQACAE","perms":["merchant.read","vendor.read","admin","billing"]}'
  )
}

const handleAuthentication = () => {
  /**/
  return getTokenSilently()
}

export function setupAuthBridge(bridge: IFrameBridge) {
  bridge.addCallHandler(GetParentToken, () => getTokenSilently())
  bridge.addCallHandler(GetParentUserInfo, () => getUserInfoFromStorage())
  bridge.addCallHandler(HandleParentAuthentication, () => handleAuthentication())
  // setupAccountBridge(bridge)
}
