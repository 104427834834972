<template lang="pug" xmlns="">
#j-combobox(@keydown='keydown')
  v-text-field(
    ref='textField'
    v-model='textValue',
    :hide-details='hideDetails'
    autocomplete='off'
    @input='onSearchInput($event)'
    @keydown='e => onKeydownTextInput(e)')
  v-menu(
    ref='menu'
    v-if='isDropdown',
    :close-on-click='false',
    :close-on-content-click='false',
    :max-height='MAX_HEIGHT_DROPDOWN',
    :min-width='width',
    :position-x='position.x',
    :position-y='position.y',
    :transition='false',
    :value='true'
    bottom
    open-on-click
    open-on-focus)
    //-
      By default, j-combobox will return {code}: {name} on select event.
      You can override this behavior by passing return-object option = true to return the whole selected object on select event.
      or return-value option = {prop_name} to return the selected object's {prop_name} value on select event.
    dropdown(
      ref='dropdown',
      :cursor='cursor',
      :item-text='itemText',
      :items='items',
      :return-code-name='returnCodeName',
      :return-object='returnObject',
      :return-value='returnValue'
      @change='$emit("change", $event)'
      @on-select='$emit("on-select", {name: name, value: $event})')
      template(v-for='(_, slot) of $scopedSlots' v-slot:[slot]='scope')
        slot(v-bind='scope', :name='slot')
</template>

<script>
  import {defineComponent, getCurrentInstance, onMounted, ref, watch} from 'vue'
  import Dropdown from '@/components/JCombobox/dropdown.vue'
  import {propsDefault} from './index.js'
  import {attach} from 'framebus/dist/lib/attach'
  import {pickDate} from '@/utils'

  export default defineComponent({
    methods: {attach},
    components: {Dropdown},
    props: propsDefault,
    setup(props, {emit}) {
      const {$refs} = getCurrentInstance().proxy
      const textValue = ref('')
      const position = ref({x: null, y: null})
      const cursor = ref(null)
      const numOfPressEnter = ref(0)
      const OPTION_ELEMENT_WDITH = 40
      const MAX_HEIGHT_DROPDOWN = 250
      const memoryInputDate = ref(null)

      const onSearchInput = query => {
        if (props.isDropdown) {
          numOfPressEnter.value = 1
          emit('search-input', textValue.value)
          props.filter(query)
        } else if (props.type === 'date') {
          const d = pickDate(query)
          if (d !== null) {
            memoryInputDate.value = pickDate(query)
          } else {
            memoryInputDate.value = null
          }
        } else {
          emit('input', query)
        }
      }
      const onKeydownTextInput = ({keyCode}) => {
        if (keyCode === 13) {
          if (props.items.length > 0 && props.isDropdown) $refs.dropdown.onChange(cursor.value)
          cursor.value = null
        }
      }
      const keydown = ({keyCode}) => {
        if (keyCode === 40) {
          if (cursor.value === null) {
            cursor.value = 0
          } else if (cursor.value < props.items.length - 1) {
            cursor.value += 1
          }
        }
        if (keyCode === 38) {
          if (cursor.value === null) {
            cursor.value = 0
          } else if (cursor.value > 0) {
            cursor.value -= 1
          }
        }
        if (keyCode === 13) {
          if (props.type === 'date') {
            emit('input', memoryInputDate.value)
            return
          }
          // Fix error that occurs when input Japanese on the first try
          numOfPressEnter.value += 1
          if (numOfPressEnter.value < 2) return
          if (numOfPressEnter.value === 2) {
            numOfPressEnter.value = 0
            // Emit input value if it is empty
            if (props.items.length === 0) {
              emit('input', textValue.value)
              // Default moving to right when input value
              if (props.isDropdown) {
                emit('move-cursor-right')
                cursor.value = null
              }
            }

            // auto select first
            if (textValue.value && props.items.length > 0 && cursor.value === null) {
              cursor.value = 0
            }
            if (props.items.length > 0 && props.isDropdown) $refs.dropdown.onChange(cursor.value)
            cursor.value = null
          }
          // $refs.dropdown.onChange(cursor.value)
        }
        // move scroll
        const menu_element = document.getElementsByClassName('v-menu__content menuable__content__active')
        if (menu_element.length > 0) {
          if (cursor.value > 5) {
            menu_element[0].scrollTop = (cursor.value - 4) * 40
          } else if (cursor.value < 5) {
            menu_element[0].scrollTop = 0
          }
        }
      }

      const updatePositionDropDown = () => {
        position.value.x = $refs.textField.$el.getBoundingClientRect().x
        const heightDropdown =
          OPTION_ELEMENT_WDITH * props.items.length > MAX_HEIGHT_DROPDOWN
            ? MAX_HEIGHT_DROPDOWN
            : OPTION_ELEMENT_WDITH * props.items.length
        position.value.y =
          $refs.textField.$el.getBoundingClientRect().y + 34 + heightDropdown > window.innerHeight
            ? $refs.textField.$el.getBoundingClientRect().y - heightDropdown - 5
            : $refs.textField.$el.getBoundingClientRect().y + 34
      }

      watch(
        () => textValue.value,
        () => {
          if (textValue.value) {
            cursor.value = 0
          } else {
            cursor.value = null
          }
        }
      )

      const init = () => {
        textValue.value = props.value
        numOfPressEnter.value = 0
        updatePositionDropDown()
        if (props.isDropdown) {
          props.filter()
          emit('search-input', textValue.value)
        }
      }

      watch(
        () => props.name,
        () => init()
      )

      watch(
        () => props.items,
        () => {
          updatePositionDropDown()
        }
      )
      onMounted(() => {
        document.getElementById('j-combobox').addEventListener('updatePositionDropdown', () => {
          updatePositionDropDown()
        })
      })
      return {
        onSearchInput,
        position,
        keydown,
        cursor,
        textValue,
        MAX_HEIGHT_DROPDOWN,
        onKeydownTextInput
      }
    }
  })
</script>
