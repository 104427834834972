<template lang="pug">
router-link.logo(:to='{name: "sale.title"}')
  v-row
    img.py-2.ma-0(alt='bouquet logo' src='@/assets/otani-logo.png')
</template>

<script>
  import {computed, defineComponent, onMounted} from 'vue'

  const Logo = defineComponent({
    props: {
      isMobile: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    setup(props, {emit}) {
      const webAppEnv = process.env.VUE_APP_APP_ENV
      const webDescription = process.env.VUE_APP_WEB_DESCRIPTION
      const colorDescription = process.env.VUE_APP_WEB_DESCRIPTION
        ? process.env.VUE_APP_DESCRIPTION_COLOR
          ? process.env.VUE_APP_DESCRIPTION_COLOR
          : 'green'
        : ''

      // check dev or staging (not production)
      const isDemo = computed(() => {
        return !!(webAppEnv === 'dev' || webAppEnv === 'staging')
      })

      const emitIsDemo = () => {
        emit('is-demo', isDemo.value)
      }

      // check screen is mobile or ipad (not PC)
      const isIPad = computed(() => {
        return screen.width <= 820 && screen.width >= 600
      })

      onMounted(() => {
        emitIsDemo()
      })

      return {
        webDescription,
        isDemo,
        colorDescription,
        isIPad,
        emitIsDemo
      }
    }
  })
  export default Logo
</script>

<style scoped lang="sass">
  .logo
    text-decoration: none
    text-align: center
    position: relative
    img
      width: 100px
      @media (max-width: 820px)
        width: 85px
      @media (max-width: 600px)
        padding-bottom: 0 !important

  .test-div
    margin-top: 10px
    margin-left: 10px
    height: 30px
    @media (max-width: 820px)
      margin-top: 18px
      margin-left: 20px
    @media (max-width: 600px)
      margin-top: 0
      margin-left: 20px
    .test-text
      letter-spacing: 10px
      padding-top: 3px
      padding-left: 7px
      padding-right: 7px
      font-size: 28px
      font-weight: bold
      color: white
      border-radius: 4px
      @media (max-width: 820px)
        font-size: 18px
        letter-spacing: 4px
        padding-top: 3px
      @media (max-width: 600px)
        font-size: 10px
        padding-top: 3px
  .img
    height: 28px
</style>
