<template lang="pug">
j-dialog-container(
  ref='dialog_container'
  v-model='show',
  :height='height ?? "650px"',
  :is-on-mobile='isOnMobile',
  :label='label',
  :loading='loading',
  :mode='isAdd ? "create" : "update"',
  :width='width'
  @on-close='closeDialog'
  @on-create='createGroup'
  @on-update='updateGroup')
  validation-observer(ref='observer' v-slot='{invalid}')
    slot(name='content')
      slot(name='top')
      slot(name='after-top')
      validation-provider(
        v-if='!hideFields.includes("code")'
        v-slot='{errors}',
        :name='$t("common.code")',
        :rules={required: true})
        v-text-field#code(
          v-model='masterData.code'
          v-next-focus,
          :autofocus='isAutofocus',
          :error-messages='errors',
          :label='$t("common.code")'
          hide-spin-buttons)
          template(v-slot:label)
            span {{$t('common.code')}}
            span.red--text *

      validation-provider(
        v-if='!hideFields.includes("name")'
        v-slot='{errors}',
        :name='$t("common.name")'
        rules='required')
        v-text-field#name(v-model='masterData.name' v-next-focus, :error-messages='errors')
          template(v-slot:label)
            span {{labelName}}
            span.red--text *
      div(v-if='isShowField')
        validation-provider(
          v-if='!hideFields.includes("full_name")'
          v-slot='{errors}',
          :name='$t("common.full_name")',
          :rules='{required: true}')
          v-text-field#full_name(v-model='masterData.full_name' v-next-focus, :error-messages='errors')
            template(v-slot:label)
              span {{$t('common.full_name')}}
              span.red--text *

        slot(name='middle')
        v-text-field#keyword(
          ref='keyword'
          v-if='!hideFields.includes("keyword")'
          v-model='masterData.keyword'
          v-next-focus,
          :label='$t("common.keyword")')
      div
        slot(name='bottom')
</template>
<script>
  import {defineComponent, getCurrentInstance, toRefs, ref} from 'vue'
  import JDialogContainer from '../JDialogContainer/index.vue'
  import {ValidationProvider, ValidationObserver} from 'vee-validate'

  const JMasterDialog = defineComponent({
    components: {
      JDialogContainer,
      ValidationProvider,
      ValidationObserver
    },
    props: {
      show: {type: Boolean, required: true},
      masterData: {type: Object, required: true},
      isAdd: {type: Boolean, required: false, default: true},
      loading: {type: Boolean, required: false, default: false},
      label: {type: String, required: false, default: ''},
      labelName: {type: String, required: false, default: ''},
      isShowField: {type: Boolean, required: false, default: true},
      isOnMobile: {type: Boolean, required: false, default: false},
      hideFields: {default: () => []},
      type: {type: String, required: false, default: ''},
      height: {type: String, default: null},
      masterUrl: {type: String, default: ''},
      isAutofocus: {type: Boolean, required: false, default: true},
      width: {type: Number, required: false}
    },
    setup(props, {emit}) {
      const {masterData} = toRefs(props)
      const instance = getCurrentInstance()
      const {$refs, $toast, $root} = instance.proxy
      const test = ref(null)

      const validateElement = async () => {
        const validate = await $refs.observer.validate()
        if (!validate) {
          $toast.error($root.$t('message.check_data_type'))
          return false
        }
        return true
      }

      const resetValidate = async () => {
        const validateName = await $refs.observer
        validateName.reset()
      }

      const closeDialog = () => {
        emit('on-close')
        resetValidate()
      }

      const convertData = () => {
        Object.keys(masterData.value).forEach(key => {
          if (masterData.value[key] === '') masterData.value[key] = null
        })
      }

      const createGroup = async () => {
        convertData()
        if (await validateElement()) {
          emit('on-create')
          await resetValidate()
        }
      }

      const updateGroup = async () => {
        convertData()
        if (await validateElement()) {
          emit('on-update')
          await resetValidate()
        }
      }

      return {
        test,
        closeDialog,
        updateGroup,
        createGroup
      }
    }
  })

  export default JMasterDialog
</script>
