<template lang="pug" xmlns="">
v-list.ma-0
  .item--no-active(v-if='items.length === 0')
    slot(name='no-data')
      span {{$t('message.no_data')}}
  v-list-item-group(:value='cursor' color='primary')
    v-list-item.px-2(
      v-for='(item, index) in displayItems',
      :class='index === cursor ? "item--auto-select" : "item--no-active"',
      :key='index'
      dense
      @click='onChange(index)'
      @keydown='onChange(index)')
      slot(:item='item' name='item')
        span {{item.name}}
    slot(:items='items' name='new_master')
</template>

<script>
  import {defineComponent, ref, onMounted, watch} from 'vue'

  export default defineComponent({
    props: {
      cursor: {default: null},
      items: {default: () => []},
      returnValue: {default: null},
      returnObject: {default: false},
      returnCodeName: {default: false}
    },
    setup(props, {emit}) {
      const getClassItem = index => {
        let classItem = 'item--no-active'
        if (index === props.cursor) {
          classItem = 'item--auto-select'
        }
        return classItem
      }

      const onChange = changedIndex => {
        if (changedIndex === undefined || changedIndex === null) return
        const selectedItem = props.items[changedIndex]
        // By default, it will emit 'change' event with value {selectedItem.name}
        let value = `${selectedItem.name}`

        // In case returnValue = {prop_name}, it will emit 'change' event with value {selectedItem[prop_name]}
        if (props.returnValue !== null)
          value = Object.keys(selectedItem).includes(props.returnValue) ? selectedItem[props.returnValue] : null
        // Or emit 'change' event with whole of selected object
        else if (props.returnObject) value = selectedItem
        // get selected item
        emit('on-select', selectedItem)
        emit('change', value)
      }

      const displayItems = ref([])
      const sliceItems = (start, end) => {
        return props.items.slice(start, end)
      }
      const setDisplayItems = (items = undefined) => {
        if (items) displayItems.value = items
        else return []
      }

      /*START_ZONE-Auto increase items size when end of scrollbar*/
      const INCREASE_VALUE = 5
      const increaseDisplayItems = () => {
        const displayItemsLength = displayItems.value.length
        displayItems.value = displayItems.value.concat(
          sliceItems(displayItemsLength, displayItemsLength + INCREASE_VALUE)
        )
      }
      // Simple end of scroll flag
      const isEndOfScroll = ref(false)
      // Store moving direction of scrollbar
      const onScroll = element => {
        const maxValueScrollTop = element.scrollHeight - element.clientHeight
        let percentageOfScroll = element.scrollTop / maxValueScrollTop
        percentageOfScroll = Math.round(percentageOfScroll * 10) / 10
        // Check if end of scroll
        isEndOfScroll.value = percentageOfScroll === 1
      }
      watch(isEndOfScroll, () => {
        if (isEndOfScroll.value) {
          increaseDisplayItems()
          // Refresh end of scroll flag
          isEndOfScroll.value = false
        }
      })
      const registerScrollListener = () => {
        let menuElement = document.getElementsByClassName('v-menu__content menuable__content__active')
        if (menuElement.length > 0) {
          menuElement = menuElement[0]
          menuElement.addEventListener('scroll', () => {
            onScroll(menuElement)
          })
        }
      }
      /*END_ZONE-Auto increase items size when end of scrollbar*/

      watch(
        () => props.items,
        () => {
          displayItems.value = props.items.slice(0, 20)
        },
        {
          deep: true,
          immediate: true
        }
      )

      onMounted(() => {
        registerScrollListener()
        setDisplayItems(sliceItems(0, 20))
      })

      return {
        getClassItem,
        onChange,
        displayItems
      }
    }
  })
</script>

<style scoped lang="sass">
  .content
    z-index: 10 !important
    position: relative
    border: #1dc125 1px
  ::v-deep .v-list-item.item--no-active
    background-color: white !important
  ::v-deep .v-list-item.item--auto-select
    background-color: #e0efe7
</style>
