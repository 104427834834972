<template lang="pug">
div
  j-master-dialog(
    ref='master_dialog',
    :height='"85vh"',
    :is-add='isAdd',
    :is-on-mobile='isOnMobile',
    :isAutofocus='false',
    :label='isAdd ? $t("variety.add") : $t("variety.edit")',
    :label-name='$t("common.name")',
    :loading='loading',
    :master-data='varietyData',
    :show='show'
    type='variety'
    @on-close='closeDialog'
    @on-create='createItem'
    @on-update='updateItem')
    template(#top)
      //- 品目コード
      validation-provider(v-slot='{errors}', :name='$t("common.item")' rules='required')
        v-autocomplete#item_id(
          v-model='varietyData.item_id'
          v-next-focus,
          :error-messages='errors',
          :filter='(item, queryText, itemText) => filterBySearchString(item, queryText)',
          :items='itemList.map(item => ({...item, name_display: `${item.code}：${item.name}`, disabled: item.id !== curItemId && !item.active}))'
          autofocus
          clearable
          item-text='name_display'
          item-value='id')
          template(v-slot:label)
            span {{$t('common.item')}}
            span.red--text *
          template(v-slot:no-data)
            span.pl-5.grey--text {{$t('message.no_data')}}
    template(#middle)
      validation-observer(ref='observer' v-slot='{invalid}')
        //- 略称
        validation-provider(v-slot='{errors}', :name='$t("common.short_name")', :rules={required: true})
          v-text-field#short_name(v-model='varietyData.short_name' v-next-focus, :error-messages='errors')
            template(v-slot:label)
              span {{$t('common.short_name')}}
              span.red--text *
        //- WEB略称
        v-text-field#web_name(v-model='varietyData.web_name' v-next-focus, :label='$t("common.web_name")')
        //- WEB名称
        v-text-field#web_full_name(
          v-model='varietyData.web_full_name'
          v-next-focus,
          :label='$t("common.web_full_name")')
        //- WEBカナ名称
        v-text-field#web_kana_name(
          v-model='varietyData.web_kana_name'
          v-next-focus,
          :label='$t("common.web_kana_name")')
        //- 種別
        v-autocomplete#category_id(
          v-model='varietyData.category_id'
          v-next-focus,
          :filter='(item, queryText, itemText) => filterBySearchString(item, queryText)',
          :items='categoryList.map(item => ({...item, name_display: `${item.code}：${item.name}`}))',
          :label='$t("common.category")'
          clearable
          item-text='name_display'
          item-value='id')
          template(v-slot:no-data)
            span.pl-5.grey--text {{$t('message.no_data')}}
        //- 品名入力
        .switch-wrapper
          span.prepend-label {{$t('variety.name_input')}}
          v-switch.hidden-xs-only.switch(v-model='varietyData.is_name_input')
        //- 色
        v-autocomplete#color_id(
          v-model='varietyData.color_id'
          v-next-focus,
          :filter='(item, queryText, itemText) => filterBySearchString(item, queryText)',
          :items='colorList.map(item => ({...item, name_display: `${item.code}：${item.name}`}))',
          :label='$t("common.color")'
          clearable
          item-text='name_display'
          item-value='id')
          template(v-slot:no-data)
            span.pl-5.grey--text {{$t('message.no_data')}}
        //- 濃淡
        v-autocomplete#shade_type_id(
          v-model='varietyData.shade_type_id'
          v-next-focus,
          :filter='(item, queryText, itemText) => filterBySearchString(item, queryText)',
          :items='shadeTypeList.map(item => ({...item, name_display: `${item.code}：${item.name}`}))',
          :label='$t("common.shade_type")'
          clearable
          item-text='name_display'
          item-value='id')
          template(v-slot:no-data)
            span.pl-5.grey--text {{$t('message.no_data')}}
        //- 切花部門コード
        v-autocomplete#cut_department_id(
          v-model='varietyData.cut_department_id'
          v-next-focus,
          :filter='(item, queryText, itemText) => filterBySearchString(item, queryText)',
          :items='departmentList.map(item => ({...item, name_display: `${item.code}：${item.name}`}))',
          :label='$t("variety.cut_department_id")'
          clearable
          item-text='name_display'
          item-value='id')
          template(v-slot:no-data)
            span.pl-5.grey--text {{$t('message.no_data')}}
        //- 鉢部門コード
        v-autocomplete#pot_department_id(
          v-model='varietyData.pot_department_id'
          v-next-focus,
          :filter='(item, queryText, itemText) => filterBySearchString(item, queryText)',
          :items='departmentList.map(item => ({...item, name_display: `${item.code}：${item.name}`}))',
          :label='$t("variety.pot_department_id")'
          clearable
          item-text='name_display'
          item-value='id')
          template(v-slot:no-data)
            span.pl-5.grey--text {{$t('message.no_data')}}
        //- 統一品名コード
        v-text-field#jf_code(v-model='varietyData.jf_code' v-next-focus, :label='$t("variety.jf_code")')
        //- 開設者品目コード
        v-autocomplete#establisher_item_id(
          v-model='varietyData.establisher_item_id'
          v-next-focus,
          :filter='(item, queryText, itemText) => filterBySearchString(item, queryText)',
          :items='establisherItemList.map(item => ({...item, name_display: `${item.code}：${item.name}`}))',
          :label='$t("variety.establisher_item_id")'
          clearable
          item-text='name_display'
          item-value='id')
          template(v-slot:no-data)
            span.pl-5.grey--text {{$t('message.no_data')}}
        //- 全農品目コード
        v-autocomplete#agri_item_id(
          v-model='varietyData.agri_item_id'
          v-next-focus,
          :filter='(item, queryText, itemText) => filterBySearchString(item, queryText)',
          :items='agriItemList.map(item => ({...item, name_display: `${item.code}：${item.name}`}))',
          :label='$t("variety.agri_item_id")'
          clearable
          item-text='name_display'
          item-value='id')
          template(v-slot:no-data)
            span.pl-5.grey--text {{$t('message.no_data')}}
        //- 鉢物統計コード
        v-text-field#pot_statistics_code(
          v-model='varietyData.pot_statistics_code'
          v-next-focus,
          :label='$t("variety.pot_statistics_code")')
        //- セット済フラグ
        .switch-wrapper
          span.prepend-label {{$t('variety.is_set')}}
          v-switch.hidden-xs-only.switch(v-model='varietyData.is_set')
        //- 開設者コード
        v-text-field#establisher_code(
          v-model='varietyData.establisher_code'
          v-next-focus,
          :label='$t("variety.establisher_code")')
        //- 開設者枝番
        v-text-field#establisher_branch_code(
          v-model='varietyData.establisher_branch_code'
          v-next-focus,
          :label='$t("variety.establisher_branch_code")')
        //- 開設者除外
        .switch-wrapper
          span.prepend-label {{$t('variety.is_establisher_exclude')}}
          v-switch.hidden-xs-only.switch(v-model='varietyData.is_establisher_exclude')
        //- 市況報告
        .switch-wrapper
          span.prepend-label {{$t('variety.is_market_report')}}
          v-switch.hidden-xs-only.switch(v-model='varietyData.is_market_report')
        //- 販売値段報告
        .switch-wrapper
          span.prepend-label {{$t('variety.is_sales_report')}}
          v-switch.hidden-xs-only.switch(v-model='varietyData.is_sales_report')
        //- 輸入品市況報告
        .switch-wrapper
          span.prepend-label {{$t('variety.is_import_report')}}
          v-switch.hidden-xs-only.switch(v-model='varietyData.is_import_report')
        //- WEB市況明細
        .switch-wrapper
          span.prepend-label {{$t('variety.is_web_report')}}
          v-switch.hidden-xs-only.switch(v-model='varietyData.is_web_report')
  j-confirm-dialog(
    :cancel-text='$t("common.cancel")',
    :messages='[$t("message.confirm_used_in_assignment")]',
    :ok-text='$t("common.save")',
    :showDialog='showConfirmUsedDialog'
    @on-cancel='showConfirmUsedDialog = false'
    @on-close='showConfirmUsedDialog = false'
    @on-ok='updateItem(false), (showConfirmUsedDialog = false)')
</template>
<script>
  import {defineComponent, toRefs, ref, getCurrentInstance, watch, onMounted} from 'vue'
  import {ValidationProvider, ValidationObserver} from 'vee-validate'
  import JMasterDialog from '../JMasterDialog/index'
  import JConfirmDialog from '../JConfirmDialog/index'
  import {api} from '@/plugins'
  import {endpoints, showError, filterBySearchString} from '@/utils'

  const JItemDialog = defineComponent({
    components: {
      JConfirmDialog,
      JMasterDialog,
      ValidationProvider,
      ValidationObserver
    },
    props: {
      show: {type: Boolean, required: true},
      item: {type: Object, required: false, default: null},
      isAdd: {type: Boolean, required: false, default: true},
      curItemId: {required: false},
      prevInput: {type: String, required: false, default: ''},
      itemsProps: {type: Array, required: false, default: null},
      categoriesProps: {type: Array, required: false, default: null},
      colorsProps: {type: Array, required: false, default: null},
      shadeTypesProps: {type: Array, required: false, default: null},
      departmentProps: {type: Array, required: false, default: null},
      establisherItemsProps: {type: Array, required: false, default: null},
      agriItemProps: {type: Array, required: false, default: null},
      isOnMobile: {type: Boolean, required: false, default: false}
    },
    setup(props, {emit}) {
      const {$toast, $root, $refs} = getCurrentInstance().proxy
      const {item} = toRefs(props)
      const loading = ref(false)

      const CREATE_ENDPOINT = `${endpoints.MASTER_VARIETY}`
      const UPDATE_ENDPOINT = `${endpoints.MASTER_VARIETY}`

      const varietyData = ref({})
      const isSuccessAll = ref(true)
      const showConfirmUsedDialog = ref(false)

      const closeDialog = () => {
        loading.value = false
        emit('on-close')
        resetValidate()
      }

      const validateElement = async () => {
        const validate = await $refs.observer.validate()
        if (!validate) {
          $toast.error($root.$t('message.check_data_type'))
          return false
        }
        return true
      }

      const resetValidate = async () => {
        const validateName = await $refs.observer
        validateName.reset()
      }

      const convertData = () => {
        Object.keys(varietyData.value).forEach(key => {
          if (varietyData.value[key] === '') varietyData.value[key] = null
        })
      }

      const createItem = async () => {
        loading.value = true
        convertData()
        if (!(await validateElement())) {
          loading.value = false
          return
        }
        try {
          const {data} = await api.post(CREATE_ENDPOINT, varietyData.value)
          if (isSuccessAll.value) $toast.success($root.$t('message.create_successful'))
          emit('on-reload', data)
          closeDialog()
        } catch (e) {
          // if api return duplicate --> show dialog confirm and allow save in db
          showError(e, $toast, $root.$t('message.update_failed'))
        } finally {
          loading.value = false
          await resetValidate()
        }
      }

      const updateItem = async () => {
        loading.value = true
        try {
          await api.put(UPDATE_ENDPOINT + `/${varietyData.value.id}`, varietyData.value)
          if (isSuccessAll.value) $toast.success($root.$t('message.create_successful'))
          emit('on-reload')
          closeDialog()
        } catch (e) {
          // if api return duplicate --> show dialog confirm and allow save in db
          showError(e, $toast, $root.$t('message.update_failed'))
        } finally {
          loading.value = false
        }
      }
      const confirmUpdate = async () => {
        try {
          await api.post(`${UPDATE_ENDPOINT}check?id=${varietyData.value.id}`)
          await updateItem()
        } catch (e) {
          showConfirmUsedDialog.value = true
        }
      }

      const init = () => {
        if (props.show) {
          if (props.isAdd) {
            const varietyInit = {
              item_id: parseInt(props.curItemId)
            }
            varietyData.value = JSON.parse(JSON.stringify(varietyInit))
          } else {
            varietyData.value = JSON.parse(JSON.stringify(item.value))
          }
        }
      }

      // 品目
      const itemList = ref([])
      const getItem = async () => {
        if (props.itemsProps) {
          itemList.value = props.itemsProps
        } else {
          try {
            const {data} = await api.get(`${endpoints.MASTER_ITEM}`)
            itemList.value = data
          } catch (e) {
            showError(e, $toast, $root.$t('message.get_data_failed'))
          }
        }
      }

      // 種別
      const categoryList = ref([])
      const getCategory = async () => {
        if (props.categoriesProps) {
          categoryList.value = props.categoriesProps
        } else {
          try {
            const {data} = await api.get(`${endpoints.MASTER_CATEGORY}?active=true`)
            categoryList.value = data.filter(clr => clr.active === true)
          } catch (e) {
            showError(e, $toast, $root.$t('message.get_data_failed'))
          }
        }
      }

      // 色
      const colorList = ref([])
      const getColor = async () => {
        if (props.colorsProps) {
          colorList.value = props.colorsProps
        } else {
          try {
            const {data} = await api.get(`${endpoints.MASTER_COLOR}?active=true`)
            colorList.value = data.filter(clr => clr.active === true)
          } catch (e) {
            showError(e, $toast, $root.$t('message.get_data_failed'))
          }
        }
      }

      // 濃淡
      const shadeTypeList = ref([])
      const getShadeType = async () => {
        if (props.shadeTypesProps) {
          shadeTypeList.value = props.shadeTypesProps
        } else {
          try {
            const {data} = await api.get(`${endpoints.MASTER_SHADE_TYPE}?active=true`)
            shadeTypeList.value = data.filter(clr => clr.active === true)
          } catch (e) {
            showError(e, $toast, $root.$t('message.get_data_failed'))
          }
        }
      }

      // 部門
      const departmentList = ref([])
      const getDepartment = async () => {
        if (props.departmentsProps) {
          departmentList.value = props.departmentsProps
        } else {
          try {
            const {data} = await api.get(`${endpoints.MASTER_DEPARTMENT}?active=true`)
            departmentList.value = data.filter(clr => clr.active === true)
          } catch (e) {
            showError(e, $toast, $root.$t('message.get_data_failed'))
          }
        }
      }

      // 開設者品目
      const establisherItemList = ref([])
      const getEstablisherItem = async () => {
        if (props.establisherItemsProps) {
          establisherItemList.value = props.establisherItemsProps
        } else {
          try {
            const {data} = await api.get(`${endpoints.MASTER_ESTABLISHER_ITEM}?active=true`)
            establisherItemList.value = data.filter(clr => clr.active === true)
          } catch (e) {
            showError(e, $toast, $root.$t('message.get_data_failed'))
          }
        }
      }

      // 全農品目
      const agriItemList = ref([])
      const getAgriItem = async () => {
        if (props.agriItemsProps) {
          agriItemList.value = props.agriItemsProps
        } else {
          try {
            const {data} = await api.get(`${endpoints.MASTER_AGRI_ITEM}?active=true`)
            agriItemList.value = data.filter(clr => clr.active === true)
          } catch (e) {
            showError(e, $toast, $root.$t('message.get_data_failed'))
          }
        }
      }

      onMounted(() => {
        Promise.all([
          getItem(),
          getCategory(),
          getColor(),
          getShadeType(),
          getDepartment(),
          getEstablisherItem(),
          getAgriItem()
        ])
      })
      watch(
        () => props.show,
        () => {
          init()
        }
      )
      watch(
        () => [
          props.itemsProps,
          props.categoriesProps,
          props.colorsProps,
          props.shadeTypesProps,
          props.departmentsProps,
          props.establisherItemsProps,
          props.agriItemProps
        ],
        () => {
          itemList.value = props.itemsProps
          categoryList.value = props.categoriesProps
          colorList.value = props.colorsProps
          shadeTypeList.value = props.shadeTypesProps
          departmentList.value = props.departmentsProps
          establisherItemList.value = props.establisherItemsProps
          agriItemList.value = props.agriItemProps
        }
      )

      return {
        closeDialog,
        loading,
        varietyData,
        updateItem,
        createItem,
        confirmUpdate,
        showConfirmUsedDialog,
        itemList,
        categoryList,
        colorList,
        shadeTypeList,
        establisherItemList,
        agriItemList,
        departmentList,
        filterBySearchString
      }
    }
  })

  export default JItemDialog
</script>

<style lang="sass" scoped>
  .content
    padding: 10px
  .auto-button
    padding-bottom: 4px
  .required
    content: '99'
    color: red
  .list-item
    max-height: 550px
    overflow-y: auto
  .v-list-item
    padding: 0 !important
    min-height: 35px !important

  .item
    display: flex
    align-items: center

  .item .code
    display: inline-block
    width: 100px
    text-align: left
    padding-left: 20px
  .item .name
    display: inline-block
    width: calc(100% - 100px)
    text-align: left
    padding-right: 20px
  .icon-list
    font-size: 20px
    display: inline-block
    justify-content: center
    align-items: center


  .color-btn
    background-color: #bdbdbd

  .switch-wrapper
    display: flex
    align-items: center
    margin-top: 8px
</style>
