export const MASTER_ARRIVAL_TYPE = '/arrival_types'
export const MASTER_ITEM = '/items'
export const MASTER_VARIETY = '/varieties'
export const MASTER_GRADE = '/grades'
export const MASTER_RANK = '/ranks'
export const MASTER_BLOOM = '/bloom_nums'
export const MASTER_COUNTRY = '/countries'
export const MASTER_PACKING = '/packings'
export const MASTER_MPS = '/mpss'
export const MASTER_COLOR = '/colors'
export const MASTER_COLOR_TYPE = '/color_types'
export const MASTER_INDUSTRY = 'industries'
export const HOME = '/home/'
export const MASTER_FLOWER_TYPE = '/flower_types'
export const MASTER_ESTABLISHER_ITEM = '/establisher_items'
export const MASTER_AGRI_ITEM = '/agri_items'
export const MASTER_DEPARTMENT = '/departments'
export const MASTER_BROAD_CATEGORY = '/broad_categories'
export const MASTER_SHADE_TYPE = '/shade_types'
export const MASTER_CATEGORY = '/categories'
export const MASTER_UNIT = '/units'
export const MASTER_SHIPPER = '/shippers'
export const MARKET_COMMISSION = '/market_commissions'
export const SHIPPING_INCENTIVE = '/shipping_incentives'
export const CARRIER = '/carriers'
export const ACCOUNT_TYPE = '/account_types'
export const COMMISSION_BURDEN = '/commission_burdens'
export const CLOSING_DATE = '/closing_dates'
export const BROKERAGE_TYPE = '/brokerage_types'
export const TRANSFER_TYPE = '/transfer_types'
export const BANK = '/banks'
export const BANK_BRANCH = '/bank_branches'
export const COMPANY_BANK = '/company_banks'
export const MASTER_PREFECTURE = '/prefectures'
export const MASTER_ORGANIZATION_TYPE = '/organization_types'
export const TAX_FRACTION = '/tax_fractions'
export const MASTER_ARTICLE = 'master/articles'
export const ARRIVALS = '/arrivals'
export const DAILY_UPDATE = '/daily_update'
export const MASTER_CARRIER = '/carriers'
export const MASTER_TAX_TYPE = '/tax_types'
export const DEFAULT_TAX_RATE = '/tax_rates'
export const DEFAULT_FREIGHT_COST = '/default_freight_cost'
export const BUYERS = '/buyers'
export const PAST_ITEM = '/items/past'
export const PAST_VARIETY = '/varieties/past'
export const PAST_GRADE = '/grades/past'
export const PAST_RANK = '/ranks/past'
export const PAST_BLOOM_NUM = '/bloom_nums/past'
export const PAST_ARTICLE = '/master/articles/past'
export const ARRIVAL_DETAILS = '/arrival_details'
export const MASTER_MEMBERS = '/members'
export const MASTER_PRINTERS = '/printers'
export const MASTER_BUYERS = '/buyers'
export const LOGIN_USERS = '/login_users'
export const SLIP_TYPE = '/slip_types'
export const MEMBERS = '/members'
export const MASTER_SLIP_TYPES = '/slip_types'
export const MASTER_SETTING = '/settings'
export const SALES_SLIP_REMARKS = '/sales_slip_remarks'
export const AGRI_TYPES = '/agri_types'
export const JOB_DATE = '/job_date'
export const MASTER_JOBS = '/jobs'
export const MASTER_JOB_CATEGORIES = '/job_categories'
export const VARIETY_SHIPPER_FILES = '/variety_shipper_files'
export const SHIPPER_ITEM_DEFAULT = '/shipper_item_default'
export const MASTER_ROLE = '/roles'
export const MASTER_COMMENT = '/comments'
export const MASTER_AUCTION_PRICE_RATE = '/auction_price_rates'
export const MASTER_PRODUCT_FILES = '/product_file'

export const MASTER_MARKET_CONDITIONS = '/market_conditions'
