export const common = {
  state: {
    settingMaster: [],
    memberInfo: {},
    firstClickDatePickerKey: null
  },
  mutations: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    setSettingMaster(state, value) {
      state.settingMaster = value
    },
    setMemberInfo(state, data) {
      state.memberInfo = data
    },
    setFirstClickDatePickerKey(state, data) {
      state.firstClickDatePickerKey = data
    }
  },
  getters: {
    getMemberInfo: state => state.memberInfo,
    getFirstClickDatePickerKey: state => state.firstClickDatePickerKey
  }
}
