import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#007167',
        secondary: '#343f4b',
        thirty: '#EC008C'
      }
    },
    options: {
      customProperties: true
    }
  }
})
