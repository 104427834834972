<template lang="pug">
div
  v-dialog(v-model='showDialog' max-width='450' no-click-animation persistent @keydown='onTyping')
    v-card
      v-card-title.primary
        v-btn(v-if='isBack' dark icon @click='close($event)')
          v-icon mdi-keyboard-backspace

        span.white--text {{title ? title : $t('common.confirm')}}
        v-spacer
        portal-target(name='header-dialog-action')
        v-btn(dark icon @click='$emit("on-close", $event)')
          v-icon mdi-close

      v-card-text.relative-card.pa-0
        v-row.ma-5.display-msg(justify='center')
          span(v-for='msg in messages' v-if='messages') {{msg}}
          //- span(v-if="isDeleteMaster" ) {{$t('message.never_use_in_future')}}
          //- span(v-if="isDeleteMaster" ) {{$t('message.delete_master_being_used')}}
        v-row.display-msg(justify='center')
          slot(name='body-append')
      v-card-actions(:class='cancelText ? "" : "confirm"')
        v-btn.relative-btn(v-if='cancelText' color='rough_black' width='50%' @click='cancel()')
          span {{cancelText}}
        v-btn.relative-btn(v-if='!delText', :color='colorConfirm' dark width='50%' @click='ok()')
          span.white--text {{okText}}
        v-btn.relative-btn.primary(v-if='!okText' color='red' dark width='50%' @click='ok()')
          span.white--text {{delText}}
</template>

<script>
  import {defineComponent} from 'vue'

  const JConfirmDialog = defineComponent({
    props: {
      showDialog: {
        type: Boolean,
        required: true
      },
      messages: {
        type: Array,
        default: () => []
      },
      okText: {
        type: String,
        required: false
      },
      delText: {
        type: String,
        required: false
      },
      cancelText: {
        type: String,
        required: false
      },
      isBack: {
        type: Boolean,
        required: false,
        default: false
      },
      label: {
        type: String,
        default: '',
        required: false
      },
      colorConfirm: {
        type: String,
        default: 'primary'
      },
      title: {
        type: String,
        default: null,
        required: false
      },
      isDeleteMaster: {
        type: Boolean,
        default: false,
        required: false
      }
    },
    setup(props, {emit}) {
      const cancel = () => {
        emit('on-cancel')
      }

      const ok = () => {
        emit('on-ok')
      }

      const close = event => {
        emit('on-close', event)
      }

      const onTyping = event => {
        if (event.keyCode === 27) {
          // Press ESC
          close(event)
        }
        if (event.keyCode === 13) {
          ok()
        }
      }

      return {
        cancel,
        ok,
        onTyping
      }
    }
  })
  export default JConfirmDialog
</script>
<style lang="sass" scoped>
  @import '@/style/css/common.sass'
  .v-dialog__content
    z-index: 100001 !important
  .v-dialog > *
    width: auto
    @include relative-fontsize-2

  .relative-btn
    @include relative-fontsize-2

  .v-card
    ::v-deep .v-card__title
      position: sticky
      top: 0
      z-index: 999

    ::v-deep .v-card__actions
      position: sticky
      background-color: white
      bottom: 0
      z-index: 999

    span
      @include relative-fontsize-2

  .display-msg
    display: inline-grid

  .message
    font-weight: bold
    font-size: 15px
  .confirm
    justify-content: center
</style>
