const arrayCharacterFullWidth = {
  ｧ: 'ァ',
  ｱ: 'ア',
  ｨ: 'ィ',
  ｲ: 'イ',
  ｩ: 'ゥ',
  ｳ: 'ウ',
  ｪ: 'ェ',
  ｴ: 'エ',
  ｫ: 'ォ',
  ｵ: 'オ',
  ｶ: 'カ',
  ｶﾞ: 'ガ',
  ｷ: 'キ',
  ｷﾞ: 'ギ',
  ｸ: 'ク',
  ｸﾞ: 'グ',
  ｹ: 'ケ',
  ｹﾞ: 'ゲ',
  ｺ: 'コ',
  ｺﾞ: 'ゴ',
  ｻ: 'サ',
  ｻﾞ: 'ザ',
  ｼ: 'シ',
  ｼﾞ: 'ジ',
  ｽ: 'ス',
  ｽﾞ: 'ズ',
  ｾ: 'セ',
  ｾﾞ: 'ゼ',
  ｿ: 'ソ',
  ｿﾞ: 'ゾ',
  ﾀ: 'タ',
  ﾀﾞ: 'ダ',
  ﾁ: 'チ',
  ﾁﾞ: 'ヂ',
  ｯ: 'ッ',
  ﾂ: 'ツ',
  ﾂﾞ: 'ヅ',
  ﾃ: 'テ',
  ﾃﾞ: 'デ',
  ﾄ: 'ト',
  ﾄﾞ: 'ド',
  ﾅ: 'ナ',
  ﾆ: 'ニ',
  ﾇ: 'ヌ',
  ﾈ: 'ネ',
  ﾉ: 'ノ',
  ﾊ: 'ハ',
  ﾊﾞ: 'バ',
  ﾊﾟ: 'パ',
  ﾋ: 'ヒ',
  ﾋﾞ: 'ビ',
  ﾋﾟ: 'ピ',
  ﾌ: 'フ',
  ﾌﾞ: 'ブ',
  ﾌﾟ: 'プ',
  ﾍ: 'ヘ',
  ﾍﾞ: 'ベ',
  ﾍﾟ: 'ペ',
  ﾎ: 'ホ',
  ﾎﾞ: 'ボ',
  ﾎﾟ: 'ポ',
  ﾏ: 'マ',
  ﾐ: 'ミ',
  ﾑ: 'ム',
  ﾒ: 'メ',
  ﾓ: 'モ',
  ｬ: 'ャ',
  ﾔ: 'ヤ',
  ｭ: 'ュ',
  ﾕ: 'ユ',
  ｮ: 'ョ',
  ﾖ: 'ヨ',
  ﾗ: 'ラ',
  ﾘ: 'リ',
  ﾙ: 'ル',
  ﾚ: 'レ',
  ﾛ: 'ロ',
  ﾜ: 'ワ',
  ｦ: 'ヲ',
  ﾝ: 'ン',
  ｳﾞ: 'ヴ',
  '･': '・',
  ｰ: 'ー',
  1: '１',
  2: '２',
  3: '３',
  4: '４',
  5: '５',
  6: '６',
  7: '７',
  8: '８',
  9: '９',
  0: '０'
}

export default arrayCharacterFullWidth
