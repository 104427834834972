import type {RouteConfig} from 'vue-router'
import {routes as cognito} from './cognito'
import {routes as pseudo} from './pseudo'

let use: RouteConfig[] = cognito

if (process.env.VUE_APP_USE_AUTH === 'pseudo') {
  use = pseudo
}

export const routes: RouteConfig[] = use
