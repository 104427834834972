<template lang="pug">
.locale-dropdown.mt-3(v-if='isLocalSite')
  v-select(
    v-model='locale',
    :items='languages'
    dense
    hide-details
    item-text='text'
    item-value='value'
    outlined
    @change='onChangeLanguages')
</template>

<style lang="sass" scoped>
  .locale-dropdown
    margin-right: 6px
    width: 54px
    height: 40px
    ::v-deep
      .v-input__slot, fieldset
        padding: 0 !important
      .v-input__append-inner i
        position:relative
        right: 10px
        color: rgba(0, 0, 0, 0.8)
      .v-select__slot input
        display: none
      .v-select__selections
        color: rgba(0, 0, 0, 0.8)
        text-transform: uppercase
        font-weight: 600
</style>

<script>
  import {computed, defineComponent, getCurrentInstance, onMounted, ref} from 'vue'
  import {useLocalStorage} from '@vueuse/core'

  const LANG_STORAGE = 'lang'
  const ELang = Object.freeze({
    JA: 'ja',
    EN: 'en'
  })

  export default defineComponent({
    setup() {
      const {$root} = getCurrentInstance().proxy
      const lang = useLocalStorage(LANG_STORAGE, ELang.JA)
      const locale = ref()
      const languages = [
        {text: 'JA', value: ELang.JA},
        {text: 'EN', value: ELang.EN}
      ]
      const isLocalSite = computed(() => {
        const environment = process.env.VUE_APP_ENVIRONMENT || ''
        return environment.toLocaleLowerCase() === 'local'
      })

      const onChangeLanguages = () => {
        $root.$i18n.locale = locale.value
        lang.value = locale.value
        window.location.reload()
      }

      const getCurrentLanguage = () => {
        if (isLocalSite.value) return lang.value
        return ELang.JA
      }

      onMounted(() => {
        const currentLang = getCurrentLanguage()
        $root.$i18n.locale = currentLang
        locale.value = currentLang
      })

      return {locale, languages, onChangeLanguages, isLocalSite}
    }
  })
</script>
