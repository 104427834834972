<template lang="pug">
div
  v-dialog(
    :content-class='isOnMobile ? "align-bottom" : ""',
    :max-width='width ? "" : "500"',
    :no-click-animation='true',
    :scrollable='isScrollable',
    :value='value',
    :width='width ? width : ""'
    persistent
    @keydown.esc='close($event)')
    v-card(:height='isOnMobile ? "500px" : height')
      v-card-title.white
        v-btn(v-if='isBack' dark icon @click='close($event)')
          v-icon mdi-keyboard-backspace
        span.primary--text {{label}}
        v-spacer
        j-sort-action-button(
          v-if='showActionButton',
          :show-reset='true'
          @on-reset='$emit("on-reset-action-button")'
          @on-save='$emit("on-save-action-button")')
        v-btn(dark icon @click='close($event)')
          v-icon.rounded-circle mdi-close

      v-card-text.relative-card
        #div_top.invisible
        slot
      slot(name='actions')
        v-card-actions(v-if='showBottom')
          v-row(v-if='isConfirm')
            v-col(cols='6')
              v-btn.relative-btn(
                :large='!$vuetify.breakpoint.xsOnly',
                :loading='loading'
                block
                color='rough_black'
                @click='close($event)')
                | {{$t('common.cancel')}}
            v-col(cols='6')
              v-btn.relative-btn(
                :large='!$vuetify.breakpoint.xsOnly',
                :loading='loading'
                block
                color='red'
                dark
                @click='deleteItem()')
                | {{$t('common.delete')}}
          v-row.pa-0.ma-0.justify-center(v-if='!isConfirm')
            v-col.px-0(v-if='showDeleteBtn && mode === "edit"', :cols='6')
              v-btn.relative-btn(
                ref='delete_btn',
                :large='!$vuetify.breakpoint.xsOnly',
                :loading='loading'
                block
                color='red'
                dark
                @click='showConfirmDelete = true')
                | {{$t('common.delete')}}
            v-col.pa-0.pb-2.text-center
              v-btn.relative-btn.fixed-button(
                ref='save_btn'
                v-if='!isConfirm',
                :large='!$vuetify.breakpoint.xsOnly',
                :loading='loading'
                color='primary'
                dark
                @click='mode === "create" ? create() : update()')
                | {{saveBtnLabel ? saveBtnLabel : $t('common.save')}}

  j-confirm-delete(:itemLabel='itemLabel', :showDialog='showConfirmDelete' @on-close='param => confirmDelete(param)')
</template>

<script>
  import {defineComponent, ref, watch, onMounted, onBeforeUnmount} from 'vue'
  import JSortActionButton from '../JSortActionButton/index.vue'
  import JConfirmDelete from '../JConfirmDelete/index'
  const JDialogContainer = defineComponent({
    props: {
      value: {
        type: Boolean,
        required: true
      },
      mode: {
        type: String,
        default: null
      },
      label: {
        type: String,
        default: ''
      },
      loading: {
        type: Boolean,
        default: false
      },
      isBack: {
        type: Boolean,
        required: false,
        default: false
      },
      showBottom: {
        type: Boolean,
        default: true
      },
      isConfirm: {
        type: Boolean,
        default: false,
        required: false
      },
      height: {
        type: String,
        default: null
      },
      showActionButton: {
        type: Boolean,
        default: false
      },
      showDeleteBtn: {
        type: Boolean,
        default: false
      },
      width: {
        type: Number,
        required: false
      },
      itemLabel: {
        type: String,
        required: false
      },
      saveBtnLabel: {
        type: String,
        required: false
      },
      isOnMobile: {
        type: Boolean,
        required: false,
        default: false
      },
      isScrollable: {
        type: Boolean,
        required: false,
        default: true
      },
      preventClickOutside: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    components: {
      JSortActionButton,
      JConfirmDelete
    },
    setup(props, {emit}) {
      const showConfirmDelete = ref(false)

      const close = event => {
        emit('on-close', event)
      }

      const create = () => {
        emit('on-create')
      }

      const deleteItem = () => {
        showConfirmDelete.value = true
      }

      const confirmDelete = param => {
        if (param === 'delete') {
          emit('on-delete')
        }
        showConfirmDelete.value = false
      }

      const update = () => {
        emit('on-update')
      }

      watch(
        () => props.value,
        () => {
          document.getElementById('div_top')?.scrollIntoView()
        }
      )

      const handleEscKey = event => {
        if (event.key === 'Escape') {
          close(event)
        }
      }

      onMounted(() => {
        document.addEventListener('keydown', handleEscKey)
      })

      onBeforeUnmount(() => {
        document.removeEventListener('keydown', handleEscKey)
      })

      return {
        showConfirmDelete,
        close,
        create,
        deleteItem,
        update,
        confirmDelete
      }
    }
  })

  export default JDialogContainer
</script>

<style lang="sass" scoped>
  @import '@/style/css/common.sass'

  .v-dialog > *
    width: auto
    @include relative-fontsize-2

  .relative-btn
    @include relative-fontsize-2

  .v-card
    ::v-deep .v-card__title
      position: sticky
      top: 0
      z-index: 999

    ::v-deep .v-card__actions
      position: sticky
      background-color: white
      bottom: 0
      z-index: 999

  .v-dialog--scrollable > .v-card > .v-card__text
    padding-bottom: 0

    span
      @include relative-fontsize-2
</style>

<style scoped lang="sass">
  @import '@/style/css/common.sass'
  .relative-card
    input, label, span
      @include relative-fontsize

  ::v-deep .v-dialog.align-bottom
    position: absolute
    bottom: 0
    bottom: calc((100vh - 150px - 500px) / 2) !important
    width: 350px !important
  .invisible
    height: 1px
  .fixed-button
    min-width: 100px !important
  .rounded-circle
    border-radius: 50%
    background-color: #666666
    height: 19px !important
    width: 19px!important
    font-size: 19px !important
</style>
