export const columns = [
  {
    name: 'item',
    align: 'center',
    sortable: false,
    value: 'item',
    disabledRow: true,
    isActive: true,
    onEnableEditMode: () => false
  },
  {
    name: 'variety',
    align: 'center',
    sortable: false,
    value: 'variety',
    isActive: true,
    onEnableEditMode: () => false
  },
  {
    name: 'product_area',
    sortable: false,
    value: 'product_area',
    align: 'start',
    isActive: true,
    onEnableEditMode: () => false
  },
  {
    name: 'grade',
    sortable: false,
    value: 'grade',
    align: 'start',
    isActive: true,
    onEnableEditMode: () => false
  },
  {
    name: 'color',
    sortable: false,
    value: 'color',
    align: 'right',
    isActive: true,
    onEnableEditMode: () => false
  },
  {
    name: 'note',
    sortable: false,
    value: 'note',
    align: 'right',
    isActive: true,
    onEnableEditMode: () => false
  },
  {
    name: 'stem_bouquet',
    sortable: false,
    value: 'stem_bouquet',
    align: 'right',
    isActive: true
  }
]
