<template lang="pug">
j-confirm-dialog(
  :cancel-text='$t("common.cancel")',
  :color-confirm='"red"',
  :is-delete-master='isDeleteMaster',
  :messages='setMessage()',
  :ok-text='okText ? okText : $t("common.delete")',
  :showDialog='showDialog'
  @on-cancel='closeDialog("cancel")'
  @on-close='closeDialog("cancel")'
  @on-ok='closeDialog("delete")')
</template>

<script>
  import {defineComponent, getCurrentInstance, ref} from 'vue'
  import JConfirmDialog from '../JConfirmDialog/index'

  const JConfirmDelete = defineComponent({
    components: {
      JConfirmDialog
    },
    props: {
      showDialog: {
        type: Boolean,
        required: true
      },
      itemLabel: {
        type: String,
        required: false
      },
      messages: {
        type: String,
        required: false
      },
      okText: {
        type: String,
        required: false
      },
      isDeleteMaster: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    setup(props, {emit}) {
      const {$root} = getCurrentInstance().proxy
      const closeDialog = param => {
        emit('on-close', param)
      }

      const setMessage = () => {
        if (props.messages) return [props.messages]
        else
          return [
            props.itemLabel
              ? $root.$t('message.confirm_delete_label', {query: props.itemLabel ?? ''})
              : $root.$t('message.confirm_delete')
          ]
      }

      return {
        closeDialog,
        setMessage
      }
    }
  })
  export default JConfirmDelete
</script>
