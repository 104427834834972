export const payment = {
  state: {
    customer: null
  },
  mutations: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    setCustomer(state, value) {
      state.customer = value
    }
  },
  actions: {}
}
