
  import {computed, defineComponent, PropType} from 'vue'
  import {CommonDatePicker, CommonTimePicker} from '@/components'
  import moment from 'moment'

  interface IDateRange {
    startDate: string | null
    startTime: string | null
    endDate: string | null
    endTime: string | null
  }

  export default defineComponent({
    name: 'DateTimeRange',
    components: {
      CommonDatePicker,
      CommonTimePicker
    },
    props: {
      value: {type: Object as PropType<IDateRange>},
      group: {type: String, default: 'default'},
      required: {type: Boolean, default: false},
      hideLabel: {type: Boolean, default: false},
      disable: {type: Boolean, default: false},
      allowNullValue: {type: Boolean, default: false},
      startTimeLabel: {type: String, default: null},
      endTimeLabel: {type: String, default: null}
    },
    setup(props, {emit}) {
      // create two-way data bindings
      const dateTimeRange = computed<IDateRange>({
        get() {
          return props.value as IDateRange
        },
        set(val: IDateRange) {
          emit('input', val)
        }
      })

      //model start date
      const startDate = computed({
        get() {
          return dateTimeRange.value?.startDate || null
        },
        set(val: string) {
          dateTimeRange.value = Object.assign(dateTimeRange.value, {
            startDate: val
          })
        }
      })
      //model start time
      const startTime = computed({
        get() {
          return dateTimeRange.value?.startTime || null
        },
        set(val: string) {
          dateTimeRange.value = Object.assign(dateTimeRange.value, {
            startTime: val
          })
        }
      })

      //model end date
      const endDate = computed({
        get() {
          return dateTimeRange.value?.endDate || null
        },
        set(val: string) {
          dateTimeRange.value = Object.assign(dateTimeRange.value, {
            endDate: val
          })
        }
      })

      //model end date
      const endTime = computed({
        get() {
          return dateTimeRange.value?.endTime || null
        },
        set(val: string) {
          dateTimeRange.value = Object.assign(dateTimeRange.value, {
            endTime: val
          })
        }
      })

      const isDateTimeRangeValid = () => {
        let isValid = true
        for (const key in dateTimeRange.value) {
          if (!dateTimeRange.value[key]) {
            emit('check-valid-date-range', isValid)
            return isValid
          }
        }
        const startDatetime = moment(`${dateTimeRange.value.startDate}T${dateTimeRange.value.startTime}`)
        const endDatetime = moment(`${dateTimeRange.value.endDate}T${dateTimeRange.value.endTime}`)
        if (!startDatetime.isBefore(endDatetime)) isValid = false

        emit('check-valid-date-range', isValid)
        return isValid
      }

      return {startDate, startTime, endDate, endTime, isDateTimeRangeValid}
    }
  })
