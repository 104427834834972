import {IAuth} from './types'
import {auth as cognito} from './cognito'
import {auth as pseudo} from './pseudo'

let use: IAuth = pseudo
if (process.env.VUE_APP_USE_AUTH === 'cognito') {
  use = cognito
}

export const auth: IAuth = use
