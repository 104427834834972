export const random = (len = 8): string => {
  const src = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  let str = ''
  for (let i = 0; i < len; i++) {
    str += src[Math.floor(Math.random() * src.length)]
  }
  return str
}

// ref: https://github.com/MartinKolarik/dedent-js/blob/master/src/index.ts
export const dedent = (str: string): string => {
  const matches = []
  const strs = [str]
  strs[strs.length - 1] = strs[strs.length - 1].replace(/\r?\n([\t ]*)$/, '')
  for (let i = 0; i < strs.length; i++) {
    let match
    if ((match = strs[i].match(/\n[\t ]+/g))) {
      matches.push(...match)
    }
  }
  if (matches.length) {
    const size = Math.min(...matches.map(value => value.length - 1))
    const pattern = new RegExp(`\n[\t ]{${size}}`, 'g')
    for (let i = 0; i < strs.length; i++) {
      strs[i] = strs[i].replace(pattern, '\n')
    }
  }
  strs[0] = strs[0].replace(/^\r?\n/, '')
  return strs[0]
}

export const hanToZen = (v: string): string => {
  return v.replace(/[!-~]/g, c => String.fromCharCode(c.charCodeAt(0) + 0xfee0))
}

export const parameterize = (value: string, separator = '_'): string => {
  const v = value
  const s = separator
  return v
    .trim()
    .replace(/[^\w\s-]/g, '') // Remove non-word characters except hyphen and space
    .replace(/[\s]+/g, s) // Replace spaces with separator
    .replace(new RegExp(`([a-z\\d])([A-Z])`, 'g'), `$1${s}$2`) // Convert camel case to separator-separated
    .toLowerCase() // Convert to lowercase
}

export const getCodeNameStr = (item: Record<string, any>) => {
  return item ? `${item.code}: ${item.name}` : null
}

export const getBuyerCodeStr = (buyer: Record<string, any>) => {
  return buyer.branch_code ? `${buyer.code}-${buyer.branch_code}` : `${buyer.code}`
}

export const getBuyerCodeName = (buyer: Record<string, any>) => {
  return buyer ? `${getBuyerCodeStr(buyer)}: ${buyer.name}` : null
}
