/**
 * This module provides facility to embed iframe from our own apps
 * e.g embed vendor portal in merchant portal, or vice versa
 */
const queryParams = new URLSearchParams(window.location.search)

/**
 * This variable is used to control the UI to whether it should be displayed
 * in embedded mode (e.g no nav menu, less padding around, etc.)
 *
 * Usage:
 * <iframe src="/page?embedded_ui=true" />
 */
export const IS_EMBEDDED_UI_ENABLED = !!queryParams.get('embedded_ui')

// A fixed list of what origins that we allow to communicate through
// window.postMessage merchanism
// Basically only the domains that we control
const VALID_ORIGINS = [window.location.origin, process.env.VUE_APP_VENDOR_URL] as const

/**
 * This variable is used to allow the embedder to pass its origin to the
 * embedded frame, so the iframe can know where to send message to
 *
 * This is needed when different portals need to embed each other, and due to security,
 * an iframe can not query directly what is the origin/domain of its parent
 *
 * USAGE:
 * On parent:
 *      <iframe src="https://vendor.flowermeister.com/page?embedder=https://merchant.flowermeister.com" />
 * On child/iframe:
 *      import {EMBEDDER, IS_VALIDLY_EMBEDDED} from '@/common/services/embed'
 *
 *      if (IS_VALIDLY_EMBEDDED) {
 *          window.parent.postMessage({hello: 'world'}, EMBEDDER)
 *      }
 */
export const EMBEDDER = queryParams.get('embedder') || window.location.origin
const HAS_VALID_EMBEDDER = VALID_ORIGINS.includes(EMBEDDER)
// This check whether the page is in an iframe, by comparing parent & current window,
// Need additional check whether we're under test, since in a cypress window, the comparison will always return true
const isInIframe = window.parent !== window && !('Cypress' in window)
export const IS_VALIDLY_EMBEDDED = isInIframe && HAS_VALID_EMBEDDER

// eslint-disable-next-line @typescript-eslint/no-empty-interface,@typescript-eslint/no-unused-vars
export interface BridgeMethod<T> extends Symbol {}

let _messageId = 1

interface MethodCallMessage {
  type: 'iframe_bridge_call'
  method: string
  messageId: number
  args: unknown[]
}

interface MethodCallResponse {
  type: 'iframe_bridge_response'
  messageId: number
  response: unknown
  rejected: boolean
}

// can uncomment to debug messages
// window.addEventListener('message', event => {
//     console.debug(isInIframe ? 'iframe' : 'top', window.location.origin, 'received', event.data)
// })

export class IFrameBridge {
  iframe: HTMLIFrameElement | WindowProxy
  origin: string
  private _messageResolves: {[key: number]: ((value: unknown | PromiseLike<unknown>) => void)[]}
  private _methodHandlers: {[key: string]: (...args: unknown[]) => unknown | PromiseLike<unknown>}

  constructor(iframe: HTMLIFrameElement | WindowProxy, origin: string) {
    if (!VALID_ORIGINS.includes(origin))
      throw Error(`Invalid origin ${origin}. Allowed origins: ${VALID_ORIGINS.join(', ')}`)
    this.iframe = iframe
    this.origin = origin
    this._onMessage = this._onMessage.bind(this)
    this._messageResolves = {}
    this._methodHandlers = {}
  }

  /**
   * Start listening to messages from child iframe, or parent window
   *
   * IMPORTANT NOTE: If this is called from a main window to connect to a child iframe,
   * always remember to call #destroy() when the iframe is no longer used, otherwise
   * memory leak would occur
   */
  connect() {
    window.addEventListener('message', this._onMessage)
  }

  /**
   * Stop listening to messages, as well as release reference to the iframe
   */
  destroy() {
    window.removeEventListener('message', this._onMessage)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.iframe = null
    this._messageResolves = {}
    this._methodHandlers = {}
  }

  /**
   * Call a method and get its response from connected iframe or parent window
   *
   * The method must be registered in the iframe/parent window with #addCallHandler()
   */
  call<T extends (...args: unknown[]) => unknown>(
    method: BridgeMethod<T>,
    ...args: Parameters<T>
  ): Promise<ReturnType<T>> {
    return new Promise((resolve, reject) => {
      const messageId = _messageId++
      this._sendMessage({type: 'iframe_bridge_call', method: method.toString(), messageId, args})
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this._messageResolves[messageId] = [resolve, reject]
    })
  }

  /**
   * Register a handler to produce response for a call from connected iframe or parent window
   */
  addCallHandler<T extends (...args: unknown[]) => unknown>(
    method: BridgeMethod<T>,
    handler: (...args: Parameters<T>) => ReturnType<T> | PromiseLike<ReturnType<T>>
  ) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this._methodHandlers[method.toString()] = handler
  }

  get _window() {
    if (this.iframe instanceof HTMLIFrameElement) return this.iframe.contentWindow
    return this.iframe
  }

  _sendMessage(message: MethodCallMessage | MethodCallResponse) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this._window.postMessage(message, this.origin)
  }

  async _onMessage(event: MessageEvent<MethodCallMessage | MethodCallResponse>) {
    // Only allow message from our domains
    if (!VALID_ORIGINS.includes(event.origin)) return
    // Only process message from iframe that this bridge specifically
    // connects to
    if (event.source !== this._window) return

    // Handle a call from connected iframe
    if (event.data.type === 'iframe_bridge_call') {
      // Check for registered handler
      // debugger
      const handler = this._methodHandlers[event.data.method]
      if (!handler) {
        console.warn(`No handler registered for method ${event.data.method}`)
        return
      }
      // Execute the handler to get response, or catch its error
      // then send a MethodCallResponse back to the other side
      await Promise.resolve(handler(...event.data.args))
        .then(response => {
          this._sendMessage({
            type: 'iframe_bridge_response',
            messageId: event.data.messageId,
            response,
            rejected: false
          })
        })
        .catch(response => {
          this._sendMessage({
            type: 'iframe_bridge_response',
            messageId: event.data.messageId,
            response,
            rejected: true
          })
        })
    }

    // Handle response received after making a call
    if (event.data.type === 'iframe_bridge_response' && this._messageResolves[event.data.messageId]) {
      const [resolve, reject] = this._messageResolves[event.data.messageId]
      if (event.data.rejected) {
        reject(event.data.response)
      } else {
        resolve(event.data.response)
      }
      delete this._messageResolves[event.data.messageId]
    }
  }
}
